import React, { useContext } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery, useTheme } from "@mui/material";
import CompetenciaForm from "../../Modal/CompetenciaForm";
import { usePutCompetenciaQuery } from "Hooks/Competencia/UsePutCompetenciaQuery";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import Loader from "components/Loader/Loader";
import { CompetenciaContext } from "../CompetenciaProvider";

export default function EditarCompetencia({ data }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setMessageAlert, setOpenSnackbar } = useContext(CompetenciaContext);
  const putCompetencia = usePutCompetenciaQuery();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (competencia) => {
    putCompetencia.mutate(competencia, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setMessageAlert(
          SnackBarMessageResponse({
            message: [
              {
                status: 200,
                description: "La competencia se ha modificado correctamente",
              },
            ],
          })
        );
        setOpen(false);
      },
      onError: (error) => {
        setOpenSnackbar(true);
        setMessageAlert(
          SnackBarMessageResponse({
            message: [
              {
                status: error.response.status,
                description:
                  "Se ha producido un error al modificar la competencia",
              },
            ],
          })
        );
      },
    });
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      {putCompetencia.isLoading && <Loader />}
      <Dialog
        fullWidth={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: fullScreen ? "100%" : "50%", // Cambia el ancho dependiendo de la resolución
            maxWidth: fullScreen ? "100%" : "none", // Permite que el modal se extienda más allá de los valores predeterminados
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Editar Competencia</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CompetenciaForm
            data={data}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
