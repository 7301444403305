import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ErrorMessage, Formik } from "formik";

import { getPeriodosxVariableParam } from 'store/variablesParam/variablesParam.slice';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { vVariablesParamxPer } from 'concursos/utils/validaciones/validaciones';


export default function VariablesParamxPerForm({ variableParam, variablesParamxPer, onSubmit }) {

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();

  const initialValue = {
    parametro1: 0,
    parametro2: null,
    fecha1: "",
    valorVarParam: 0,
    ultFechaInicio: variablesParamxPer.data[0].fecha1 ?? "",
  };

  const handleSubmit = (value) => {

    const idVariable = variableParam.idVariableParam ?? 0
    const dato = [
      {
        parametro1: parseFloat(value.parametro1) ?? 0,
        parametro2: parseFloat(value.parametro2) ?? null,
        fecha1: value.fecha1 ?? "",
        valor: parseFloat(value.valorVarParam) ?? 0,
      }
    ]
    onSubmit({ idVariable, dato });

    setTimeout(() => {
      dispatch(getPeriodosxVariableParam(variableParam.idVariableParam));          // Refresco tabla inmediatamente.
    }, 400);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vVariablesParamxPer}
      >
        {(formikProps) => (
          <Box style={{ borderRadius: "5px" }}>
            <Grid container spacing={2}>

              {/* <Grid item xs={10} md={10}>
                <div style={{ marginLeft: "12px", fontSize: "17px" }}> Nuevos Valores: </div>
              </Grid> */}

              <Grid item xs={10} md={5} style={{ marginTop: "8px" }}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    type='number'
                    id="parametro1"
                    label="Valor Parámetro 1"
                    name="parametro1"
                    autoFocus
                    /* value={varParamxPer?.parametro1 || 0}
                    onChange={handleChange} */
                    value={formikProps.values.parametro1}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="parametro1">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={5} style={{ marginTop: "8px" }}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type='number'
                    id="parametro2"
                    label="Valor Parámetro 2 (Opcional)"
                    name="parametro2"
                    autoFocus
                    /* value={varParamxPer?.parametro2 || 0}
                    onChange={handleChange} */
                    value={formikProps.values.parametro2}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="parametro2">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    type='number'
                    id="valorVarParam"
                    label="Variable Parametrizada"
                    name="valorVarParam"
                    autoFocus
                    /* value={varParamxPer?.valorVarParam || 0}
                    onChange={handleChange} */
                    value={formikProps.values.valorVarParam}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="valorVarParam">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <div style={{ marginLeft: "12px", fontSize: "17px" }}>
                  Fecha de Inicio:
                </div>
              </Grid>

              <Grid item xs={10} md={10} style={{ paddingTop: "7px", display: 'flex', justifyContent: 'center' }}>
                <DemoContainer components={['DateField']}>
                  <DatePicker
                    onChange={(date) => formikProps.setFieldValue("fecha1", date)}
                  />
                </DemoContainer>
                <ErrorMessage name="fecha1">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

            </Grid>
            <p style={{ marginTop: "30px", marginLeft: "10px" }}>
              El Período que pierde vigencia, se cerrará con fecha de <b> un día antes </b> al Período nuevo iniciado.
            </p>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
              <Button
                onClick={() => formikProps.submitForm()}
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </LocalizationProvider >
  );
}
