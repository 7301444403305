import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginApi } from "./auth.api";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  token: "",
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await loginApi(username, password);
      localStorage.setItem("token", data.token); 
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logout = () => ({
  type: "auth/logout"
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.isAuthenticated = false;
      state.user = null;
      state.token = "";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user; 
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.error
          : action.error.message;
      });
  },
});

export const { logout: logoutAction } = authSlice.actions;

export const selectUser = (state) => state.auth.isAuthenticated;
export const Username = (state) => state.auth.user;
export const getUsername = (state) => state.auth.user;
export const isLoading = (state) => state.auth.loading;

export default authSlice.reducer;
