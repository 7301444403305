import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';


export default function TitulosxPersonaSubtitulo({ persona }) {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '15px',
  }));

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ margin: "0px 25px" }}>
          <FormControl fullWidth >
            <Item>
              <b style={{ color: "rgb(25,118,210)" }}>APELLIDO Y NOMBRE: </b>
              <span style={{ color: "rgb(25,118,210)" }}>
                {persona ? `${persona?.apellidos}, ${persona?.nombres}` : "No asignado."}
              </span>
            </Item>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
