import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import MateriaForm from "../MateriaForm/MateriaForm";
import {
  fetchMateriasEditadas,
  newMateria,
  selectResponseMaterias,
} from "store/materias/materias.slice";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import CustomModal from "components/customModal/CustomModal";

export default function MateriaNuevo() {
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");

  const dispatch = useDispatch();

  dispatch(fetchMateriasEditadas(0));

  const MateriasResponse = useSelector(selectResponseMaterias);

  //Se visualiza las respuestas del backend y se aplica logica.
  useEffect(() => {
    if (MateriasResponse !== null) {
      let response = MateriasResponse;
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [MateriasResponse]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(newMateria(data));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nueva
      </Button>
      <CustomModal
        title={"Agregar Materia"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <MateriaForm onSubmit={handleSubmit} onClose={handleClose} />
        </DialogContent>
      </CustomModal>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(flag) => (flag === false) & setOpenSnackbar(flag)}
      />
    </React.Fragment>
  );
}
