import * as Yup from "yup";

export const vsCompetencia = (isEdit) =>
  Yup.object({
    codigoCompetencia: Yup.string().required("Este campo es obligatorio"),
    tipo_titulo: Yup.string().required("Este campo es obligatorio"),
    titulo: Yup.string().required("Este campo es obligatorio"),
    materia: isEdit
      ? Yup.string().required("Este campo es obligatorio") // Si es edición, validamos como string
      : Yup.array()
          .min(1, "Debe seleccionar al menos una materia")
          .required("Este campo es obligatorio"), // Si no es edición, validamos como array
  });

export const vsFiltroCompetencia = Yup.object({
  idArea: Yup.string().required("Este campo es obligatorio"),
  idSubArea: Yup.string().required("Este campo es obligatorio"),
});
