import { useQuery } from "react-query";
import { fetchAllTitulos } from "store/titulos/titulos.api";

export const useTituloQuery = () => {
  const titulosQuery = useQuery(
    {
      queryKey: ["tituloQuery"],
      queryFn: async () => {
        const { data } = await fetchAllTitulos();
        return data;
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return titulosQuery;
};
