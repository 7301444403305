import { request } from "utils/helpers/request.helpers";

const zonasUrl = `${process.env.REACT_APP_API_URL}/api/Zona`;

//const user = localStorage.getItem("token");

export function fetchZonaPorDepartamento(pIdDepartamento) {
  const url = `${zonasUrl}/GETZona_ByIdDepartamento?pIdDepartamento=${pIdDepartamento}`;     
  return request(url, { method: "GET" });
}

export function fetchAllZonas() {
  const url = `${zonasUrl}/ListaCompleta`;     
  return request(url, { method: "GET" });
}
