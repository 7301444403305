import React from 'react';
import { Modal, Typography, Button, Box } from '@mui/material';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemId }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          boxShadow: 8,
          borderRadius: 2,
          p: 4,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Confirmar eliminación
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          ¿Estás seguro de que deseas eliminar este item de puntuación?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
        <Button color="error" variant="contained" onClick={() => onConfirm(itemId)}>Eliminar</Button>
        <Button variant="contained" onClick={onClose}>Cancelar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;