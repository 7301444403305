import React from "react";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import TodayIcon from '@mui/icons-material/Today';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import VariablesParamSubtitulo from "../../VariablesParam/VariablesParamSubtitulo";
import VariablesParamxPerTable from "../VariablesParamxPerTable/VariablesParamxPerTable";
import VariablesParamxPerNueva from "../VariablesParamxPerNueva/VariablesParamxPerNueva";
/* import Loader from 'components/Loader/Loader'; */
import {
  getPeriodosxVariableParam,
  selectVariablesParamxPeriodo,
} from "store/variablesParam/variablesParam.slice";

export default function VariablesParamxPerPage({ variableParam }) {

  //console.log('variableParam en VariablesParamxPer', variableParam)

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getPeriodosxVariableParam(variableParam.idVariableParam));
  };

  const variablesParamxPer = useSelector(selectVariablesParamxPeriodo);

  //console.log('variablesParamxPer en VarParamxPerPage', variablesParamxPer)

  const handleClose = () => {
    setOpen(false);
  };

  const muestroTabla = () => {
    if (variablesParamxPer?.data?.length !== 0) {
      return <VariablesParamxPerTable variablesParamxPer={variablesParamxPer} />;
    } else {
      return (
        <p
          style={{
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {" "} Sin Períodos asignados para esta variable. {" "}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={handleClickOpen}
        style={{ marginLeft: "10px" }}
      >
        <TodayIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Períodos</DialogTitle>
        <DialogContent>
          <VariablesParamSubtitulo variableParam={variableParam} />
        </DialogContent>
        <DialogContent>
          <VariablesParamxPerNueva variableParam={variableParam} variablesParamxPer={variablesParamxPer} />
        </DialogContent>
        <DialogContent>
          {muestroTabla()}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
