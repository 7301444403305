import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";


const VariablesxPerTable = ({ variable, variablesxPer }) => {

  //console.log('variablesxPer en table de varxPer', variablesxPer)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell style={{ textAlign: "center" }}>Fecha Inicio</TableCell>
            <TableCell style={{ textAlign: "center" }}>Fecha Fin</TableCell>
            <TableCell>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variablesxPer?.data?.map(
            (
              variablexPer
            ) => (
              <TableRow
                key={variablexPer.idVarxPer}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{variablexPer.idVarxPer}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{formatDDMMYY(variablexPer.fecha1)}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {variablexPer.fecha2 ? formatDDMMYY(variablexPer.fecha2) : "NULL"}</TableCell>
                <TableCell>{variablexPer.valor ?? 0}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VariablesxPerTable;
