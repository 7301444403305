import { request } from "utils/helpers/request.helpers";

const periodoJuntasUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/Periodo`;

const user = localStorage.getItem("token");

export function fetchTiposDePeriodo() {
  return request(`${periodoJuntasUrl}/ListarTiposPeriodo`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function fetchTiposPerxNivel() {
  return request(`${periodoJuntasUrl}/ListarTiposPeriodo_x_NivelUsuario?usuario=${user}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}