import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  fetchTitulosxPersona,
  createTituloxPersona,
  updateTituloxPersona,
  deleteTituloxPersona,
  /* searchTitle, */
} from "./titulosxPersona.api";

const initialState = {
  entities: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getTitulosxPersona = createAsyncThunk(
  "titulosxPersona/getTitulosxPersona",
  async (pIdPersona) => {
    const r = await fetchTitulosxPersona(pIdPersona);
    return r.data.records;
  }
);

export const newTituloxPersona = createAsyncThunk(
  "titulos/newTituloxPersona",
  async (data) => {
    const r = await createTituloxPersona(data);
    return r;
  }
);

export const editTituloxPersona = createAsyncThunk(
  "titulosxPersona/editTituloxPersona",
  async ({ data }) => {
    const r = await updateTituloxPersona({ data });
    return r;
  }
);

export const removeTituloxPersona = createAsyncThunk(
  "titulosxPersona/removeTitulos",
  async (pIdTitulosPersona) => {
    const r = await deleteTituloxPersona(pIdTitulosPersona);
    return r;
  }
);

export const clearEntitiesData = createAction("entities/clearEntitiesData");

export const TitulosSlice = createSlice({
  name: "titulosxPersona",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getTitulosxPersona.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTitulosxPersona.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getTitulosxPersona.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(newTituloxPersona.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newTituloxPersona.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newTituloxPersona.rejected, (state, action) => {
        state.creating = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(editTituloxPersona.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editTituloxPersona.fulfilled, (state, action) => {
        state.editing = false;
        state.response = action.payload;
      })
      .addCase(editTituloxPersona.rejected, (state, action) => {
        state.editing = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(removeTituloxPersona.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeTituloxPersona.fulfilled, (state, action) => {
        state.deleting = false;
        state.response = action.payload;
      })
      .addCase(removeTituloxPersona.rejected, (state, action) => {
        state.deleting = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
        //#region clearEntitiesData
      .addCase(clearEntitiesData, (state) => {
        state.entities = []; // Vacía el array entities
      });
      //#endregion
  },
});

export const selectTitulosxPersona = (state) => state.titulosxPersona.entities;
export const selectResponseTitxPer = (state) => state.titulosxPersona.response;
export const selectIsLoadingTitxPer = (state) => state.titulosxPersona.loading;
export const selectIsCreatingTitxPer = (state) => state.titulosxPersona.creating;
export const selectIsEditingTitxPer = (state) => state.titulosxPersona.editing;
export const selectIsDeletingTitxPer = (state) => state.titulosxPersona.deleting;
export const selectErrorTitxPer = (state) => state.titulosxPersona.error;

export default TitulosSlice.reducer;
