export const formatDDMMYY = (date) => {
  if (date !== undefined) {
    date = new Date(date.toString())
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
};