import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  searchInscripcionGeneral,
  createInscripcionGeneral,
  updateInscripcionGeneral,
  deleteInscripcionGeneral,
  searchInscGeneralesxDepto,
  searchEstadosInscGeneral,
  /* searchTitle, */
} from "./inscripcionGeneral.api";

const initialState = {
  entities: [],
  entitiesxDepto: [],
  entitiesEstados: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const filtrarInscripcionGeneral = createAsyncThunk(
  "inscripcionGeneral/filtrarInscripcionGeneral",
  async ({ page = 1, data = "" }) => {
    const r = await searchInscripcionGeneral(page, data);
    return r;
  }
);

export const getInscGeneralesxDepto = createAsyncThunk(
  "inscripcionGeneral/searchInscGeneralesxDepto",
  async (pIdDepartamento) => {
    const r = await searchInscGeneralesxDepto(pIdDepartamento);
    return r;
  }
);

export const getEstadosInscGeneral = createAsyncThunk(
  "inscripcionGeneral/searchEstadosInscGeneral",
  async () => {
    const r = await searchEstadosInscGeneral();
    console.log('r estados Insc General', r)
    return r;
  }
);

export const newInscripcionGeneral = createAsyncThunk(
  "inscripcionGeneral/newInscripcionGeneral",
  async (data) => {
    const r = await createInscripcionGeneral(data);
    return r;
  }
);

export const editInscripcionGeneral = createAsyncThunk(
  "inscripcionGeneral/editInscripcionGeneral",
  async ({ data }) => {
    const r = await updateInscripcionGeneral({ data });
    return r;
  }
);

export const removeInscripcionGeneral = createAsyncThunk(
  "inscripcionGeneral/removeInscripcionGeneral",
  async (pIdInscGeneral) => {
    const r = await deleteInscripcionGeneral(pIdInscGeneral);
    return r;
  }
);

export const InscripcionGeneralSlice = createSlice({
  name: "inscripcionGeneral",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(filtrarInscripcionGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(filtrarInscripcionGeneral.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(filtrarInscripcionGeneral.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(getInscGeneralesxDepto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInscGeneralesxDepto.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxDepto = action.payload;
      })
      .addCase(getInscGeneralesxDepto.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(getEstadosInscGeneral.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEstadosInscGeneral.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesEstados = action.payload;
      })
      .addCase(getEstadosInscGeneral.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(newInscripcionGeneral.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newInscripcionGeneral.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newInscripcionGeneral.rejected, (state, action) => {
        state.creating = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(editInscripcionGeneral.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editInscripcionGeneral.fulfilled, (state, action) => {
        state.editing = false;
        state.response = action.payload;
      })
      .addCase(editInscripcionGeneral.rejected, (state, action) => {
        state.editing = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(removeInscripcionGeneral.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeInscripcionGeneral.fulfilled, (state, action) => {
        state.deleting = false;
        state.response = action.payload;
      })
      .addCase(removeInscripcionGeneral.rejected, (state, action) => {
        state.deleting = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
  },
});

export const selectInscripcionGeneral = (state) => state.inscripcionGeneral.entities;
export const selectInscGeneralesxDepto = (state) => state.inscripcionGeneral.entitiesxDepto;
export const selectInscGeneralEstados = (state) => state.inscripcionGeneral.entitiesEstados;
export const selectResponseInscGeneral = (state) => state.inscripcionGeneral.response;
export const selectIsLoadingInscGeneral = (state) => state.inscripcionGeneral.loading;
export const selectIsCreatingInscGeneral = (state) => state.inscripcionGeneral.creating;
export const selectIsEditingInscGeneral = (state) => state.inscripcionGeneral.editing;
export const selectIsDeletingInscGeneral = (state) => state.inscripcionGeneral.deleting;
export const selectErrorInscGeneral = (state) => state.inscripcionGeneral.error;

export default InscripcionGeneralSlice.reducer;
