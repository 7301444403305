import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActividadEditar from '../ActividadEditar/ActividadEditar';
import ActividadEliminar from '../ActividadEliminar/ActividadEliminar';

const createRow = (actividad) => ({
  id: actividad.idActividad,
  proyecto: actividad.proyecto,
  inicio: actividad.fechaInicio,
  fin: actividad.fechaFin,
  cupo: actividad.cupo,
  capacitador: actividad.capacitador,
});

export default function ActividadesTable() {
  const actividades = [];

  const rows = actividades.map((actividad) => createRow(actividad));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Proyecto</TableCell>
            <TableCell>Fecha Inicio</TableCell>
            <TableCell>Fecha Fin</TableCell>
            <TableCell>Cupo</TableCell>
            <TableCell>Capacitador</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((actividad) => (
            <TableRow
              key={actividad.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {actividad.id}
              </TableCell>
              <TableCell>{actividad.proyecto}</TableCell>
              <TableCell>{actividad.inicio}</TableCell>
              <TableCell>{actividad.fin}</TableCell>
              <TableCell>{actividad.cupo}</TableCell>
              <TableCell>{actividad.capacitador}</TableCell>
              <TableCell>
                <ActividadEditar actividad={actividad} />
                <ActividadEliminar actividad={actividad} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
