import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetLocalidades, GetLocalidad, GetDepartamento, fetchDeptosxTipoPeriodo } from "./Localidad.api";

const initialState = {
  localidades: null,
  departamentos: null,
  entitiesxTipoPer: [],
  loading: false,
  errorLocalidades: null,
  errorDepartamentos: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const GetLocalidadesEndpoint = createAsyncThunk(
  "localidad/GetLocalidades",
  async (id) => {
    const r = await GetLocalidades();
    return r;
  }
);

export const getDeptosxTipoPeriodo = createAsyncThunk(
  "localidad/getDeptosxTipoPeriodo",
  async (pIdTipoPeriodo) => {
    const r = await fetchDeptosxTipoPeriodo(pIdTipoPeriodo);
    return r;
  }
);

export const GetLocalidadesByDepartamento = createAsyncThunk(
  "localidad/GetLocalidadesByDepartamento",
  async ({ idDepartamento, localidad = "" }) => {
    const r = await GetLocalidad(idDepartamento, localidad);
    return r;
  }
);

export const GetDepartamentos = createAsyncThunk(
  "localidad/GetDepartamentos",
  async ({ idProvincia, departamento = "" }) => {
    const r = await GetDepartamento(idProvincia, departamento);
    return r;
  }
);

export const localidadSlice = createSlice({
  name: "localidad",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region GetLocalidadesEndpoint
      .addCase(GetLocalidadesEndpoint.pending, (state) => {
        state.loading = true;
        state.errorLocalidades = null;
      })
      .addCase(GetLocalidadesEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.localidades = action.payload;
      })
      .addCase(GetLocalidadesEndpoint.rejected, (state, action) => {
        state.loading = false;
        state.localidades = null;
        state.errorLocalidades = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region getDeptosxTipoPeriodo
      .addCase(getDeptosxTipoPeriodo.pending, (state) => {
        state.loading = true;
        state.errorLocalidades = null;
      })
      .addCase(getDeptosxTipoPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxTipoPer = action.payload;
      })
      .addCase(getDeptosxTipoPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.entitiesxTipoPer = null;
        state.errorLocalidades = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetLocalidadesByDepartamento
      .addCase(GetLocalidadesByDepartamento.pending, (state) => {
        state.loading = true;
        state.errorLocalidades = null;
      })
      .addCase(GetLocalidadesByDepartamento.fulfilled, (state, action) => {
        state.loading = false;
        state.errorLocalidades = null;
        state.localidades = action.payload.data;
      })
      .addCase(GetLocalidadesByDepartamento.rejected, (state, action) => {
        state.loading = false;
        state.localidades = null;
        state.errorLocalidades = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetDepartamentos
      .addCase(GetDepartamentos.pending, (state) => {
        state.loading = true;
        state.errorDepartamentos = null;
        state.errorLocalidades = null;
      })
      .addCase(GetDepartamentos.fulfilled, (state, action) => {
        state.loading = false;
        state.departamentos = action.payload.data;
      })
      .addCase(GetDepartamentos.rejected, (state, action) => {
        state.loading = false;
        state.departamentos = null;
        state.errorDepartamentos = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
    //#endregion
  },
});

export const selectLocalidad = (state) => state.localidad.localidades;
export const selectDeptosxTipoPer = (state) => state.localidad.entitiesxTipoPer;
export const selectDepartamentos = (state) => state.localidad.departamentos;
export const selectIsLoading = (state) => state.localidad.loading;
export const selectErrorLocalidades = (state) =>
  state.localidad.errorLocalidades;
export const selectErrorDepartamentos = (state) =>
  state.localidad.errorDepartamentos;

export default localidadSlice.reducer;
