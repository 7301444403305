import React, { useContext } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogActions, Tooltip } from "@mui/material";
import { useDeleteCompetenciaQuery } from "Hooks/Competencia/UseDeleteCompetenciaQuery";
import { CompetenciaContext } from "../CompetenciaProvider";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import Loader from "components/Loader/Loader";

export default function EliminarCompetencia({ competencia }) {
  const [open, setOpen] = React.useState(false);
  const deleteCompetencia = useDeleteCompetenciaQuery();
  const { setMessageAlert, setOpenSnackbar } = useContext(CompetenciaContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    deleteCompetencia.mutate(competencia.idCompetencias, {
      onSuccess: () => {
        handleClose();
        setMessage({
          message: [
            {
              status: 200,
              description: "La competencia se ha eliminado correctamente",
            },
          ],
        });
      },
      onError: (error) => {
        setMessage([
          {
            status: error.response.status,
            description: "Se ha producido un error al eliminar la competencia",
          },
        ]);
      },
    });
  };

  const setMessage = (message) => {
    setOpenSnackbar(true);
    setMessageAlert(SnackBarMessageResponse(message));
  };

  return (
    <React.Fragment>
      {deleteCompetencia.isLoading && <Loader />}
      <Tooltip title="Eliminar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Eliminar Competencia </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar la competencia{" "}
            <b>"{competencia?.materiaobjeto?.descripcion}"</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
