import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const proyectos = [];

const capacitadores = [];

export default function ActividadForm({ data, onSubmit }) {
  const [actividad, setActividad] = useState(data || {});

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(actividad);
  };

  const handleChange = (e) => {
    setActividad({ ...actividad, [e.target.name]: e.target.value });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="proyecto-select-label">Proyecto</InputLabel>
            <Select
              labelId="proyecto-select-label"
              id="proyecto"
              label="Proyecto"
              name="proyecto"
              value={actividad?.proyecto || ''}
              onChange={handleChange}
              required
            >
              {proyectos.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              id="inicio"
              label="Fecha de Inicio"
              name="inicio"
              autoFocus
              value={actividad?.inicio || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              id="fin"
              label="Fecha de Fin"
              name="fin"
              autoFocus
              value={actividad?.fin || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              id="cupo"
              label="Cupo"
              name="cupo"
              autoFocus
              value={actividad?.cupo || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="capacitador-select-label">Capacitador</InputLabel>
            <Select
              labelId="capacitador-select-label"
              id="capacitador"
              label="Capacitador"
              name="capacitador"
              value={actividad?.capacitador || ''}
              onChange={handleChange}
              required
            >
              {capacitadores.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
        <Button type="submit" variant="contained">
          Guardar
        </Button>
      </Box>
    </Box>
  );
}
