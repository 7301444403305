import { request } from "utils/helpers/request.helpers";

const variablesListUrl = `${process.env.REACT_APP_API_URL}/api/Variables`;

export function fetchVariables(page) {
  //const user = localStorage.getItem("token");
  const url = `${variablesListUrl}/ListarVariable?page=${page}`;
  return request(url, { method: "GET" });
}

export function fetchPeriodosxVariable(idVariable) {                 // Períodos para una determinada Variable.
  //const user = localStorage.getItem("token");                      
  const url = `${variablesListUrl}/listarPeriodosxVariable/${idVariable}`;
  return request(url, { method: "GET" });
}

export function createVariable(data) {
  //const user = localStorage.getItem("token");                      
  return request(`${variablesListUrl}/insertarVariable`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function createVariablexPer({ idVariable, fechaInicio, valor }) {
  //const user = localStorage.getItem("token");                      
  return request(`${variablesListUrl}/actualizarPeriodoVariable?idVariable=${idVariable}&fechaInicio=${fechaInicio}&valor=${valor}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    //body: JSON.stringify({ idVariable, fechaInicio, valor }),
  });
}

