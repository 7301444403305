import { useQuery } from "react-query";
import { fetchCompetenciasBySubArea } from "store/competencias/competencia.api";

export const useCompetenciasQuery = ({ idSubArea, page, titulo, materia }) => {
  const competenciaQuery = useQuery(
    {
      queryKey: ["CompetenciasQuery", { idSubArea, page, titulo, materia }],
      queryFn: async () => {
        if (!!idSubArea) {
          const { data } = await fetchCompetenciasBySubArea(
            idSubArea,
            page,
            titulo,
            materia
          );
          return data;
        }
        return { data: { records: [] } };
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return competenciaQuery;
};
