import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SnackBarUtils from 'utils/MUI/SnackbarUtils';
import { SnackBarMessageResponse } from 'utils/Response/SnackBarMessageResponse';
import TitulosxPersonaSubtitulo from '../TitulosxPersonaCommon/TitulosxPersonaSubtitulo';
import TitulosxPersonaForm from '../TitulosxPersonaCommon/TitulosxPersonaForm';
import { newTituloxPersona } from 'store/titulosxPersona/titulosxPersona.slice';

export default function TitulosxPersonaNuevo({persona}) {

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {    
    dispatch(newTituloxPersona(data));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{margin: "0px 0px 15px 20px", maxWidth: "80px"}}>
        Nuevo
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Asignar nuevo Título</DialogTitle>
        <TitulosxPersonaSubtitulo persona={persona} />
        <DialogContent>
          <TitulosxPersonaForm persona={persona} onSubmitNewEdit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
