import React from "react";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import TodayIcon from '@mui/icons-material/Today';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import VariablesSubtitulo from "../../Variables/VariablesSubtitulo";
import VariablesxPerTable from "../VariablesxPerTable/VariablesxPerTable";
import VariablesxPerNueva from "../VariablesxPerNueva/VariablesxPerNueva";
/* import Loader from 'components/Loader/Loader'; */
import {
  getPeriodosxVariable,
  selectVariablesxPeriodo,
} from "store/variables/variables.slice";

export default function VariablesxPerPage({ variable }) {

  //console.log('variable en Variablesx Per', variable)

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getPeriodosxVariable(variable.idVariable));
  };

  const variablesxPer = useSelector(selectVariablesxPeriodo);

  const handleClose = () => {
    setOpen(false);
  };

  const muestroTabla = () => {
    if (variablesxPer?.data?.length !== 0) {
      return <VariablesxPerTable variable={variable} variablesxPer={variablesxPer} />;
    } else {
      return (
        <p
          style={{
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {" "} Sin Períodos asignados para esta variable. {" "}
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={handleClickOpen}
        style={{ marginLeft: "10px" }}
      >
        <TodayIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Períodos</DialogTitle>
        <DialogContent>
          <VariablesSubtitulo variable={variable} />
        </DialogContent>
        <DialogContent>
          <VariablesxPerNueva variable={variable} variablesxPer={variablesxPer} />
        </DialogContent>
        <DialogContent>
          {muestroTabla()} {/* Si hay subáreas, muestro la tabla. */}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}