import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { vsFiltroPersona } from "./YupPersona";
import { Formik } from "formik";
import { ClearIcon } from "@mui/x-date-pickers";

const PersonaFiltro = ({ onAdd, onSearch, filter }) => {
  const theme = useTheme();

  const handleAdd = () => {
    onAdd(true, true);
  };

  return (
    <>
      <Formik
        initialValues={{
          documento: filter?.documento ?? "",
          apellidos: filter?.apellidos ?? "",
          nombres: filter?.nombres ?? "",
        }}
        validationSchema={vsFiltroPersona}
        onSubmit={(e) => {
          onSearch(e);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Box
            component="form"
            sx={{
              mt: 1,
              [theme.breakpoints.up("lg")]: {
                mt: 1,
                pr: 35,
                pt: 3,
                pl: 2,
              },
              [theme.breakpoints.up("sm")]: {
                mt: 1,
              },
            }}
          >
            <Grid container spacing={2} sx={{ borderRadius: 0 }}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombres"
                    type="text"
                    name="nombres"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.nombres}
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.nombres && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("nombres", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellidos"
                    type="text"
                    name="apellidos"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    value={values.apellidos}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.apellidos && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("apellidos", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Nº de Documento"
                    type="text"
                    name="documento"
                    variant="outlined"
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("documento", onlyNumbers);
                    }}
                    autoComplete="off"
                    value={values.documento}
                    error={Boolean(errors.documento && touched.documento)}
                    helperText={
                      Boolean(errors.documento) &&
                      touched.documento &&
                      errors.documento
                    }
                    inputProps={{ maxLength: 8 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.documento && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("documento", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => submitForm()}
                  >
                    Buscar
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleAdd()}
                    spacing={2}
                  >
                    Nuevo
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};
export default PersonaFiltro;
