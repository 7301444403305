import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { getTitulosxPersona, removeTituloxPersona } from 'store/titulosxPersona/titulosxPersona.slice';
//import SnackBarUtils from 'utils/MUI/SnackbarUtils';
//import { SnackBarMessageResponse } from 'utils/Response/SnackBarMessageResponse';


export default function TitulosxPersonaEliminar({ datosTitulo }) {

  //console.log('datosTitulo en titxper eliminar', datosTitulo)
  
  const [open, setOpen] = React.useState(false);
/*   const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState(""); */
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(removeTituloxPersona(datosTitulo.idTituloPersona));
    setTimeout(() => { 
      dispatch(getTitulosxPersona(datosTitulo.idPersona));
    }, 400);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar asignación </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro/a que deseas eliminar la asignación del Título <b> "{datosTitulo.des_Titulo}" </b> a {datosTitulo.apellidoyNombre}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}