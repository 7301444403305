import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Toolbar from "@mui/material/Toolbar";
import Loader from "components/Loader/Loader";
import AreasTable from "../AreasTable/AreasTable";
import AreasListNuevo from "../AreasListNuevo/AreasListNuevo";
import {
  getAreas,
  selectAreas,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
  selectResponseNewAreas,
} from "store/areasList/areasList.slice";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";

export const myPageAreas = React.createContext(1);

export default function AreasListPage() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const dispatch = useDispatch();
  const areasResponse = useSelector(selectResponseNewAreas);

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  let [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getAreas(page ? page : 1));
  }, [page, dispatch]);

  const areas = useSelector(selectAreas);

  const handleChange = (event, values) => {
    setPage(values);
  };

  useEffect(() => {
    let response = areasResponse;
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [areasResponse]);

  /* const handleSearch = (e) => {
    if (e.code !== "Enter") return;
  }; */

  return (
    <myPageAreas.Provider value={page}>
      <React.Fragment>
        {showLoader && <Loader />}
        <h1>Áreas</h1>
        <Toolbar disableGutters>
          <AreasListNuevo />
        </Toolbar>

        {/* <GenericSearch handleSearch={handleSearch}/> */}

        <AreasTable areas={areas} />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={areas?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </myPageAreas.Provider>
  );
}
