import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InscGeneralForm from "../Common/InscripcionGeneralForm";
import { InscGeneralContext } from "../../Page/InscripcionGeneralPage";
import { editInscripcionGeneral, filtrarInscripcionGeneral } from "store/inscripcionGeneral/inscripcionGeneral.slice";

export default function InscripcionGeneralEditar({ inscGeneral }) {
  const [open, setOpen] = React.useState(false);
  const pageAndSearch = React.useContext(InscGeneralContext);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(editInscripcionGeneral({data}));
    setOpen(false);
    setTimeout(() => {
      dispatch(filtrarInscripcionGeneral(pageAndSearch? pageAndSearch : { page: 1, data: null })); 
    }, 400);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Editar Inscripción</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InscGeneralForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            data={inscGeneral}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
