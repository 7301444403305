import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InscripcionesxPersonaForm from "../InscripcionesxPersonaCommon/InscripcionesxPersonaForm";
import { editInscripcionxPersona } from "store/inscripcionesxPersona/inscripcionesxPersona.slice";

export default function InscripcionesxPersonaEditar({ datosInscPersona, persona }) {

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(editInscripcionxPersona({data}));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Editar Inscripción asignada</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InscripcionesxPersonaForm
            datosInscPersona={datosInscPersona}
            persona={persona}
            onSubmitNewEdit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
