import { request } from "utils/helpers/request.helpers";

const variablesListUrl = `${process.env.REACT_APP_API_URL}/api/Variables`;

export function fetchVariablesParam(page) {
  //const user = localStorage.getItem("token");                      // va esta línea ????
  const url = `${variablesListUrl}/ListarVariableParam?page=${page}`;
  return request(url, { method: "GET" });
}

export function fetchPeriodosxVariableParam(idVariableParam) {       // Períodos para una determinada Variable.
  //const user = localStorage.getItem("token");                      // va esta línea ????
  const url = `${variablesListUrl}/listarPeriodosxVariableParam/${idVariableParam}`;
  return request(url, { method: "GET" });
}

export function createVariableParam(dato) {
  //const user = localStorage.getItem("token");                      // va esta línea ????
  return request(`${variablesListUrl}/insertarVariableParam`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(dato),
  });
}

export function createVariableParamxPer({ idVariable, dato }) {
  //const user = localStorage.getItem("token");                      // va esta línea ????
  return request(`${variablesListUrl}/actualizarPeriodoVariableParam?idVariable=${idVariable}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(dato),
  });
}

