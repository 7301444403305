import { request } from "utils/helpers/request.helpers";

const subareasUrl = `${process.env.REACT_APP_API_URL}/api/Areas`;

const user = localStorage.getItem("token");

export function fetchSubareas() {
  const url = `${subareasUrl}/ListarAreaSubarea?usuario=${user}`; // Listado de Subáreas según el usuario elegido.
  return request(url, { method: "GET" });
}

export function fetchSubareasById(id) {
  // Listado de Subáreas segun el área elegida, sin subárea Default.
  const url = `${subareasUrl}/BuscarSubareasxArea/${id}?idArea=${id}`;
  return request(url, { method: "GET" });
}

export function fetchSubareasByIdconDefault(idArea) {
  // Listado de Subáreas segun el área elegida, con subárea Default.
  const url = `${subareasUrl}/BuscarSubareasxAreaTodas?idArea=${idArea}&usuario=${user}`;
  return request(url, { method: "GET" });
}

export function fetchSubareasPerComp(pIdPersona) {
  const url = `${subareasUrl}/ListarSubareasBy_IdPersonaCompetencias?pIdPersona=${pIdPersona}&usuario=${user}`;
  return request(url, { method: "GET" });
}

export function createSubarea(data) {
  return request(`${subareasUrl}/insertarSubarea?usuario=${user}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateSubarea({ id, data }) {
  return request(`${subareasUrl}/editarSubArea/${id}`, {
    // Edidicón de Subáreas.
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function deleteSubarea(id) {
  return request(`${subareasUrl}/eliminarSubarea/${id}`, {
    // Eliminación de una subárea (Eliminado físico).
    method: "PUT",
  });
}
