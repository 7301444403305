import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditarPeriodo from "../Modal/Editar/EditarPeriodo";
import EliminarPeriodo from "../Modal/Eliminar/EliminarPeriodo";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const PeriodosTable = ({ periodos }) => {
  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripcion</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Tipo de periodo</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Fecha de inicio</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Fecha de Fin</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periodos?.length > 0 ? (
            periodos?.map((periodo) => (
              <TableRow key={periodo.idPeriodo}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {periodo.idPeriodo}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {periodo.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {periodo.tipoPeriodo.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {periodo.fechaInicio}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {periodo.fechaFin}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  {<EditarPeriodo periodo={periodo} />}
                  {<EliminarPeriodo periodo={periodo} />}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default PeriodosTable;
