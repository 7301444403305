import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchMaterias,
  createMateria,
  updateMateria,
  deleteMateria,
  fetchMateriasEdit,
  filterMateria,
} from './materias.api';

const initialState = {
  entities: [],
  materiasEdit: [],
  materiasFilter: null,
  response: null,             // La entrada original es un objeto {}. 
  loading: false,
  loadingEditt: false,
  loaddingUpdate: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getMaterias = createAsyncThunk(
  'materias/getMaterias',
  async (page) => {
    const r = await fetchMaterias(page);
    try{    
      //console.log('try async get')
      return r;
    }
    catch{
      //console.log('catch async get')
    }
    finally{
      //console.log('finally async get')
    }
  }
);
export const fetchMateriasEditadas = createAsyncThunk(
  'materias/getMateriasEditadas',
  async (page) => {
    const r = await fetchMateriasEdit(page);
    return r; 
  }
);
export const updateMateriaById = createAsyncThunk(
  'materias/updateMaterias',
  async (data) => {
    const r = await updateMateria(data);
    return r; 
  }
);
export const searchMateria = createAsyncThunk(
  'materias/search',
  async (data) => {
    const r = await filterMateria(data);
    return r; 
  }
);

export const newMateria = createAsyncThunk(
  'materias/newMaterias',
  async (data) => {
    //console.log(data)
    const r = await createMateria(data);
    return r; 
  }
);

export const editMateria = createAsyncThunk(
  'materias/editMaterias',
  async ({ id, data }) => {
    const r = await updateMateria({ id, data });
    try{
      //console.log('try async edit')
      return r;
    }
    catch{
      //console.log('catch async edit')
    }
    finally{
      //console.log('finally async edit')
    }
  }
);

export const removeMateria = createAsyncThunk(
  'materias/removeMaterias',
  async (id) => {
    const r = await deleteMateria(id);
    try{
      //console.log('try async remove')
      return r;
    }
    catch{
      //console.log('catch async remove')
    }
    finally{
      //console.log('finally async remove')
    }    
  }
);

export const MateriasSlice = createSlice({
  name: 'materias',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMaterias.pending, (state) => {               // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getMaterias.fulfilled, (state, action) => {     // cumplida.
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getMaterias.rejected, (state, action) => {      // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(newMateria.pending, (state) => {
        state.loaddingUpdate = true;
        state.error = null;
      })
      .addCase(newMateria.fulfilled, (state, action) => {
        state.loaddingUpdate = false;
        state.entities = action.payload;
        state.response = action.payload;
      })
      .addCase(newMateria.rejected, (state, action) => {
        state.loaddingUpdate = false;
        state.error = action.error.message;
      })

      .addCase(editMateria.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editMateria.fulfilled, (state, action) => {
        state.editing = false;
        state.entities = state.entities.records.map((materia) => {        // Se le agregó "records".
          if (materia.id !== action.payload?.id) {
            return materia;
          }
          return {
            ...materia,
            ...action.payload,
          };
        });
      })
      .addCase(editMateria.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      .addCase(removeMateria.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeMateria.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state?.entities?.records?.filter(                   // Se le agregó "records".
          (materia) => materia.id !== action.payload?.id
        );
      })
      .addCase(removeMateria.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      })
      .addCase(fetchMateriasEditadas.pending, (state) => {               // pendiente.
        state.loadingEditt = true;
        state.error = null;
      })
      .addCase(fetchMateriasEditadas.fulfilled, (state, action) => {     // cumplida.
        state.loadingEditt = false;
        state.materiasEdit = action.payload;
      })
      .addCase(fetchMateriasEditadas.rejected, (state, action) => {      // rechazado.
        state.loadingEditt = false;
        state.error = action.error.message;
        state.materiasEdit = null
      })
      .addCase(updateMateriaById.pending, (state) => {               // pendiente.
        state.loaddingUpdate = true;
        state.error = null;
      })
      .addCase(updateMateriaById.fulfilled, (state, action) => {     // cumplida.
        state.loaddingUpdate = false;
        state.materiasEdit = [];
      })
      .addCase(updateMateriaById.rejected, (state, action) => {      // rechazado.
        state.loaddingUpdate = false;
        state.error = action.error.message;
      })
      .addCase(searchMateria.pending, (state) => {               // pendiente.
        state.loaddingUpdate = true;
        state.error = null;
      })
      .addCase(searchMateria.fulfilled, (state, action) => {     // cumplida.
        state.loaddingUpdate = false;
        state.materiasFilter = action.payload;
      })
      .addCase(searchMateria.rejected, (state, action) => {      // rechazado.
        state.loaddingUpdate = false;
        state.error = action.error.message;
      });

  },
});

export const selectMaterias = (state) => state.materias.entities;             // records??.
export const selectMateriasEdit = (state) => state.materias.materiasEdit;             // records??.
export const selectMateriasFiltered = (state) => state.materias.materiasFilter;             // records??.
export const selectResponseMaterias = (state) => state.materias.response;     
export const selectIsLoading = (state) => state.materias.loading;
export const selectIsLoadingEditt = (state) => state.materias.loadingEditt;
export const loaddingUpdate = (state) => state.materias.loaddingUpdate;
export const selectIsCreating = (state) => state.materias.creating;
export const selectIsEditing = (state) => state.materias.editing;
export const selectIsDeleting = (state) => state.materias.deleting;
export const selectError = (state) => state.materias.error;
export const selectPage = (state) => state.materias.page;

export default MateriasSlice.reducer;
