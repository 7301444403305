import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Formik } from "formik";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import customParseFormat from "dayjs/plugin/customParseFormat";

import { getVariablesParam } from 'store/variablesParam/variablesParam.slice';
import { myPageVariablesParam } from '../VariablesParamPage/VariablesParamPage';
import { vVariablesParam } from 'concursos/utils/validaciones/validaciones';


export default function VariablesParamForm({ onSubmit }) {

  dayjs.extend(customParseFormat);  
  const dispatch = useDispatch();
  const page = useContext(myPageVariablesParam);

  const initialValue = {
    descripcion: "",
    descParam1: "",
    descParam2: "",                 // Puede ser un String vacío la respuesta.
    esPorCargo: "",
    escalafon: 2,                     // Escalafón docente, para que no falle el back.
    descValor: "",
    deshabilitado: 0,
    parametro1: 0,
    parametro2: null,
    fecha1: "",                       // Fecha de Inicio del Período.
    valor: 0,
  };

  const handleSubmit = (value) => {
    const dato = {
      variable: {
        descripcion: (value.descripcion).toUpperCase() ?? "",
        descParam1: (value.descParam1).toUpperCase() ?? "",
        descParam2: (value.descParam2).toUpperCase() ?? null,            // Puede ser null.
        esPorCargo: "",
        escalafon: 2,
        descValor: (value.descValor).toUpperCase() ?? "",
        deshabilitado: 0,
      },
      listavariables: [
        {
          parametro1: value.parametro1 ?? 0,
          parametro2: value.parametro2 ?? null,                          // Puede ser null.   
          fecha1: value.fecha1 ?? "",
          valor: value.valor ?? 0,
        }
      ]
    }
    //console.log('dato From de variables', dato)

    onSubmit(dato);

    setTimeout(() => {
      dispatch(getVariablesParam(page ? page : 1));        // Refresco tabla inmediatamente.
    }, 400);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vVariablesParam}
      >
        {(formikProps) => (
          <Box style={{ borderRadius: "5px" }}>
            <Grid container spacing={2}>
              <Grid item xs={10} md={10} style={{ marginTop: "10px" }}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    id="descripcion"
                    label="Descripción de Variable Parametrizada"
                    name="descripcion"
                    autoFocus
                    /* value={variable?.descripcion || ''}
                    onChange={handleChange} */
                    value={formikProps.values.descripcion}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="descripcion">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <div style={{ marginLeft: "12px", fontSize: "17px" }}> Parámetros: </div>
              </Grid>

              <Grid item xs={10} md={5}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    id="descParam1"
                    label="Descripción Parámetro 1"
                    name="descParam1"
                    autoFocus
                    /* value={variable?.descParam1 || ''}
                    onChange={handleChange} */
                    value={formikProps.values.descParam1}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="descParam1">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={5}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    type='number'
                    id="parametro1"
                    label="Valor Parámetro 1"
                    name="parametro1"
                    autoFocus
                    /* value={variable?.parametro1 || 0}
                    onChange={handleChange} */
                    value={formikProps.values.parametro1}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="parametro1">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="descParam2"
                    label="Descripción Parámetro 2 (Opcional)"
                    name="descParam2"
                    autoFocus
                    /* value={variable?.descParam2 || ''}
                    onChange={handleChange} */
                    value={formikProps.values.descParam2}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="descParam2">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={5}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type='number'
                    id="parametro2"
                    label="Valor Parámetro 2 (Opcional)"
                    name="parametro2"
                    autoFocus
                    /* value={variable?.parametro2 || 0}
                    onChange={handleChange} */
                    value={formikProps.values.parametro2}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="parametro2">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <div style={{ marginLeft: "12px", fontSize: "17px" }}> Valor Variable Parametrizada: </div>
              </Grid>

              <Grid item xs={10} md={10}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    id="descValor"
                    label="Descripción de Valor"
                    name="descValor"
                    autoFocus
                    /* value={variable?.descValor || ''}
                    onChange={handleChange} */
                    value={formikProps.values.descValor}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="descValor">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <FormControl fullWidth>
                  <TextField
                    required
                    fullWidth
                    type='number'
                    id="valor"
                    label="Valor Variable Parametrizada"
                    name="valor"
                    autoFocus
                    /* value={variable?.valor || 0}
                    onChange={handleChange} */
                    value={formikProps.values.valor}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="valor">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={10}>
                <div style={{ marginLeft: "12px", fontSize: "17px" }}>
                  Fecha de Inicio:
                </div>
              </Grid>

              <Grid item xs={10} md={10} style={{ paddingTop: "7px", display: 'flex', justifyContent: 'center' }}>
                <DemoContainer components={['DateField']}>
                  <DatePicker
                    onChange={(date) => formikProps.setFieldValue("fecha1", date)}
                  />
                </DemoContainer>
                <ErrorMessage name="fecha1">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
              <Button
                onClick={() => formikProps.submitForm()}
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
