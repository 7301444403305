import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Toolbar from "@mui/material/Toolbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Loader from "components/Loader/Loader";
import MateriaNuevo from "../MateriasNuevo/MateriasNuevo";
import MateriasTable from "../MateriasTable/MateriasTable";
import {
  loaddingUpdate,
  searchMateria,
  selectMaterias,
  selectMateriasFiltered,
} from "store/materias/materias.slice";

import {
  getMaterias,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
} from "store/materias/materias.slice";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import GenericSearch from "concursos/utils/search/genericSearch";
import { Grid } from "@mui/material";
import { getSubareas } from "store/subareas/subareas.slice";

export const myPageMaterias = React.createContext(1);

export default function MateriasPage() {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const loaddingUpdatte = useSelector(loaddingUpdate);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  let [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getMaterias(page ? page : 1));
    dispatch(getNivelesEnsenanza(page ? page : 1));
    dispatch(getSubareas());
  }, [page, dispatch, loaddingUpdatte, isDeleting]);

  const materiasFiltered = useSelector(selectMateriasFiltered);
  const getDataMateria = useSelector(selectMaterias);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    if (e.target.value === "") window.location.reload(true);
    dispatch(searchMateria(e.target.value));
  };

  return (
    <myPageMaterias.Provider value={page}>
      <React.Fragment>
        {showLoader && <Loader />}
        <h1> Materias </h1>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Toolbar disableGutters>
              <MateriaNuevo />
            </Toolbar>
          </Grid>
          <Grid item xs={8}>
            <GenericSearch handleSearch={handleSearch} />
          </Grid>
        </Grid>

        <MateriasTable
          materias={materiasFiltered ?? getDataMateria?.data?.records}
        />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={getDataMateria?.data?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
    </myPageMaterias.Provider>
  );
}
