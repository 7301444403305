import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonasDni, selectCertificados, selectLoading, selectError } from 'store/certificado/certificado.slice';
import { TextField, Button, CircularProgress, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';

export const CertificadosPage = () => {
  const dispatch = useDispatch();
  const certificados = useSelector(selectCertificados);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [personaDni, setPersonaDni] = useState('');

  const handleSearchClick = () => dispatch(fetchPersonasDni(personaDni));

  return (
    <Container sx={{ '@media (min-width: 1200px)': { maxWidth: '1400px' } }}>
      <Typography variant="h4" gutterBottom>Certificados</Typography>
      
      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="DNI de la Persona"
            variant="outlined"
            value={personaDni}
            onChange={(e) => setPersonaDni(e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchClick}
            disabled={loading}
            fullWidth
            sx={{ height: '40px' }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>

      {loading && <CircularProgress sx={{ display: 'block', mx: 'auto', my: 2 }} />}
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      {!loading && certificados.data?.length === 0 && (
        <Typography sx={{ mt: 2 }}>No se encontraron resultados</Typography>
      )}

      {certificados.data?.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                {['ID Certificación', 'Denominación Proyecto', 'Horas Cátedra', 'Con Evaluación', 'Tipo Proyecto', 'Destinado A', 'Domicilio', 'Fecha Inicio Actividad', 'Fecha Fin Actividad', 'Localidad', 'Modalidad Proyecto', 'Tipo Certificado', 'Resolución'].map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {certificados.data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.certificado.idCertificacion}</TableCell>
                  <TableCell>{item.proyecto.denominacion}</TableCell>
                  <TableCell>{item.proyecto.horasCatedra}</TableCell>
                  <TableCell>{item.proyecto.conEvaluacion}</TableCell>
                  <TableCell>{item.proyecto.tipoProyecto.denominacion}</TableCell>
                  <TableCell>{item.proyecto.destinadoA}</TableCell>
                  <TableCell>{item.certificado.actividad.domicilio}</TableCell>
                  <TableCell>{new Date(item.certificado.actividad.fechaInicio).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(item.certificado.actividad.fechaFin).toLocaleDateString()}</TableCell>
                  <TableCell>{item.certificado.actividad.localidadActividad.localidad}</TableCell>
                  <TableCell>{item.proyecto.modalidadProyecto.denominacion}</TableCell>
                  <TableCell>{item.certificado.tipoCertificado.descripcion}</TableCell>
                  <TableCell>{item.resolucion.descripcion}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};
