import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Loader from 'components/Loader/Loader';
import ProyectoNuevo from '../ProyectoNuevo/ProyectoNuevo';
import ProyectosTable from '../ProyectosTable/ProyectosTable';
import {
  getProyectos,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
} from 'store/proyectos/proyectos.slice';

export default function ProyectosPage() {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  useEffect(() => {
    //dispatch(getProyectos());
  }, [dispatch]);

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <h1>Proyectos</h1>
      <Toolbar disableGutters>
        <ProyectoNuevo />
      </Toolbar>
      <ProyectosTable />
    </React.Fragment>
  );
}
