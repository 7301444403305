import { request } from "utils/helpers/request.helpers";

const titulosUrl = `${process.env.REACT_APP_API_URL}/api/Titulos`;

export function fetchTitulos(page) {
  const userGet = localStorage.getItem("token");
  const url = `${titulosUrl}/Listar?page=${page}&usuario=${userGet}`; // ABM Titulos.
  return request(url, { method: "GET" });
}

export function fetchAllTitulos() {
  //const userGet = localStorage.getItem("token");
  const url = `${titulosUrl}/ListarAllTitulos`;
  return request(url, { method: "GET" });
}

export function fetchTipoTitulo(user) {
  const _user = user ?? localStorage.getItem("token");
  const url = `${titulosUrl}/GetTipoTitulo?usuario=${_user}`; // ABM Titulos.
  return request(url, { method: "GET" });
}

export function createTitulo(data) {
  return request(`${titulosUrl}/insertarTitulo`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateTitulo({ data }) {
  return request(`${titulosUrl}/updateTitulo`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}
export function searchTitle(value) {
  return request(`${titulosUrl}/FiltrarTitulo?pBusqueda=${value}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export async function deleteTitulo(id) {
  try {
    return await request(`${titulosUrl}/Deshabilitar?idtitulo=${id}`, {
      method: "PUT",
    });
  } catch (error) {
    console.log("cath titulos.api");
    console.log("error", error.response);
  }
}
