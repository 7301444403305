import { request } from "utils/helpers/request.helpers";

const certificadosUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/Certificacion`;
const PersonaCertificadoUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api`;

export function getCertificados(idpersona) {
    const url = `${certificadosUrl}/ListadoCertificadosxPersona?idpersona=${idpersona}`;
    return request(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  }

export function getBusquedaDniPersona(personaDni) {
  return request(`${PersonaCertificadoUrl}/Persona/FiltrarPersonas?pBusqueda=${personaDni}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}  