import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchAllEntidadesOtorgantes,
} from "./entidadesOtorgantes.api";

const initialState = {
  entities: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getAllEntidadesOtorgantes = createAsyncThunk(
  "entidadesOtorgantes/getAllEntidadesOtorgantes",
  async () => {
    const r = await fetchAllEntidadesOtorgantes();
    //console.log('r get entidadesOtorgantes en slice', r)
    return r;
  }
);


export const EntidadesOtorgantesSlice = createSlice({
  name: "entidadesOtorgantes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllEntidadesOtorgantes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEntidadesOtorgantes.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload?.data;
      })
      .addCase(getAllEntidadesOtorgantes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectEntidadesOtorgantesAll = (state) => state.entidadesOtorgantes.entities;
export const selectIsLoading = (state) => state.entidadesOtorgantes.loading;
export const selectIsCreating = (state) => state.entidadesOtorgantes.creating;
export const selectIsEditing = (state) => state.entidadesOtorgantes.editing;
export const selectIsDeleting = (state) => state.entidadesOtorgantes.deleting;
export const selectError = (state) => state.entidadesOtorgantes.error;

export default EntidadesOtorgantesSlice.reducer;
