import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editarItemDePuntuacion, fetchPuntuacionItems } from 'store/PuntuacionPersona/PuntuacionPersona.slice';
import { Modal, Box, TextField, Button, Grid, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { getNivelesEnsenanza, selectNivelesEnsenanza } from 'store/nivelesEnsenanza/nivelesEnsenanza.slice';
import { ListarTipoItemDePuntuacionApi } from 'store/PuntuacionPersona/PuntuacionPersona.api';

const EditItemModal = ({ isOpen, onClose, item }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    idTipoItemsDePuntuacion: 0,
    codigo: '',
    descripcion: '',
    unidadDeMedida: '',
    idNivelEnsenanza: 0,
  });
  const [nivelesSeleccionados, setNivelesSeleccionados] = useState(null);
  const [tiposItemsDePuntuacion, setTiposItemsDePuntuacion] = useState([]);
  const [selectedTipoItem, setSelectedTipoItem] = useState(null);

  const seletEnsenanza = useSelector(selectNivelesEnsenanza);
  const nivelesEnsenanza = seletEnsenanza.records;

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
    ListarTipoItemDePuntuacionApi().then((response) => {
      setTiposItemsDePuntuacion(response);
    });
  }, [dispatch]);

  useEffect(() => {
    if (item) {
      setFormData({
        idTipoItemsDePuntuacion: item.idTipoItemsDePuntuacion,
        codigo: item.codigo,
        descripcion: item.descripcion,
        unidadDeMedida: item.unidadDeMedida,
        idNivelEnsenanza: item.idNivelEnsenanza,
      });
      const nivel = nivelesEnsenanza.find(nivel => nivel.idNivelEnsenanza === item.idNivelEnsenanza);
      setNivelesSeleccionados(nivel || null);
      const tipoItem = tiposItemsDePuntuacion.find(tipo => tipo.idTipoItemsDePuntuacion === item.idTipoItemsDePuntuacion);
      setSelectedTipoItem(tipoItem || null);
    }
  }, [item, nivelesEnsenanza, tiposItemsDePuntuacion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === 'idTipoItemsDePuntuacion' ? Number(value) : value });
  };

  const handleChangeNivelEnsenanza = (_, value) => {
    setNivelesSeleccionados(value);
    const idNivelEnsenanza = value ? value.idNivelEnsenanza : 0;
    setFormData({ ...formData, idNivelEnsenanza });
  };

  const handleChangeTipoItem = (_, value) => {
    setSelectedTipoItem(value);
    const idTipoItemsDePuntuacion = value ? value.idTipoItemsDePuntuacion : 0;
    setFormData({ ...formData, idTipoItemsDePuntuacion });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedItem = {
      id: item.idItemDePuntuacion, 
      idTipoItemsDePuntuacion: formData.idTipoItemsDePuntuacion,
      codigo: formData.codigo,
      descripcion: formData.descripcion,
      unidadDeMedida: formData.unidadDeMedida,
      idNivelEnsenanza: formData.idNivelEnsenanza,
    };
    dispatch(editarItemDePuntuacion(updatedItem))
      .unwrap()
      .then(() => {
        onClose();
        dispatch(fetchPuntuacionItems());
      })
      .catch(error => {
        console.error("Error editing item:", error);
      });
      onClose();
  };  

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit} sx={{ ...modalStyle }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Código"
              name="codigo"
              value={formData.codigo}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descripción"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Unidad de Medida"
              name="unidadDeMedida"
              value={formData.unidadDeMedida}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={tiposItemsDePuntuacion}
                value={selectedTipoItem}
                onChange={handleChangeTipoItem}
                getOptionLabel={(option) => option.descripcion}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de Ítems de Puntuación"
                    placeholder="Selecciona un tipo"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={nivelesEnsenanza}
                value={nivelesSeleccionados}
                onChange={handleChangeNivelEnsenanza}
                getOptionLabel={(option) => option.nivelEnsenanza}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nivel de Enseñanza"
                    placeholder="Selecciona un nivel"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '15px', width: '50%', margin: '2 auto', marginLeft: '90px' }}>
          Guardar
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default EditItemModal;
