import { request } from "utils/helpers/request.helpers";

const nivelesEnsenanzaUrl = `${process.env.REACT_APP_API_URL}/api/NivelesEnsenanza`;
const titulosUrl = `${process.env.REACT_APP_API_URL}/api/Titulos`;

const user = localStorage.getItem("token");

export function fetchNivelesEnsenanza() {
  const url = `${nivelesEnsenanzaUrl}/ListaCompletaJuntas?usuario=${user}`;  // ABM Niveles de Ensenanza.
  return request(url, { method: "GET" });
}

export function createNivelEnsenanza(data) {
  return request(`${nivelesEnsenanzaUrl}?usuario=${user}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateNivelEnsenanza({ id, data }) {
  return request(`${nivelesEnsenanzaUrl}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deleteNivelEnsenanza(id) {
  try {
    return await request(`${nivelesEnsenanzaUrl}/${id}`, {
      method: "DELETE",
    });
  } catch (error) {
    console.log("cath NivelesEnsenanza.api");
    console.log("error", error.response);
  }
}

export function fetchTitulo_Nivel(id) {
  const url = `${titulosUrl}/ListarNivelesxTitulo/${id}`;
  return request(url, { method: "GET" });
}
