import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchTiposDePeriodo,
  fetchTiposPerxNivel
} from "./tiposDePeriodo.api";

import { any } from "prop-types";

const initialState = {
  entities: [],
  entitiesxNivel: [],
  messageResponse: null,
  editLoading: false,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getTiposDePeriodo = createAsyncThunk(
  "tiposDePeriodo/GetTiposDePeriodo",
  async () => {
    const r = await fetchTiposDePeriodo();
    return r;
  }
);

export const getTiposPerxNivel = createAsyncThunk(
  "tiposDePeriodo/fetchTiposPerxNivel",
  async () => {
    const r = await fetchTiposPerxNivel();
    return r;
  }
);

const TiposDePeriodoSlice = createSlice({
  name: "tiposDePeriodo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region getTiposDePeriodo
      .addCase(getTiposDePeriodo.pending, (state) => {
        // pendiente.
        state.editLoading = true;
        state.error = null;
      })
      .addCase(getTiposDePeriodo.fulfilled, (state, action) => {
        // cumplida.
        state.entities = action.payload;
        state.editLoading = false;
      })
      .addCase(getTiposDePeriodo.rejected, (state, action) => {
        // rechazado.
        state.editLoading = false;
        state.error = action.error.message;
      })
      //#endregion

      //#region getTiposPerxNivel
      .addCase(getTiposPerxNivel.pending, (state) => {
        // pendiente.
        state.editLoading = true;
        state.error = null;
      })
      .addCase(getTiposPerxNivel.fulfilled, (state, action) => {
        // cumplida.
        state.entitiesxNivel = action.payload;
        state.editLoading = false;
      })
      .addCase(getTiposPerxNivel.rejected, (state, action) => {
        // rechazado.
        state.editLoading = false;
        state.error = action.error.message;
      });
    //#endregion
  },
});

export const stpTiposDePeriodo = (state) => state.tiposDePeriodo.entities;
export const stpTiposPerxNivel = (state) => state.tiposDePeriodo.entitiesxNivel;
export const stpIsLoading = (state) => state.tiposDePeriodo.loading;
export const stpIsEditLoading = (state) => state.tiposDePeriodo.editLoading;
export const stpError = (state) => state.tiposDePeriodo.error;

export default TiposDePeriodoSlice.reducer;
