import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from '@mui/material/Toolbar';
import Loader from 'components/Loader/Loader';
import ActividadNueva from '../ActividadNueva/ActividadNueva';
import ActividadesTable from '../ActividadesTable/ActividadesTable';
import {
  getActividades,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
} from 'store/actividades/actividades.slice';

export default function ActividadesPage() {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  useEffect(() => {
    dispatch(getActividades());
  }, [dispatch]);

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <h1>Actividades</h1>
      <Toolbar disableGutters>
        <ActividadNueva />
      </Toolbar>
      <ActividadesTable />
    </React.Fragment>
  );
}
