import { request } from 'utils/helpers/request.helpers';

const actividadesUrl = `${process.env.REACT_APP_API_URL}/api/Actividad`;

export function fetchActividades() {
  return request(`${actividadesUrl}/Listar`, {
    method: 'GET',
  });
}

export function createActividad(data) {
  return request(`${actividadesUrl}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function updateActividad({ id, data }) {
  return request(`${actividadesUrl}/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function deleteActividad(id) {
  return request(`${actividadesUrl}/${id}`, {
    method: 'DELETE',
  });
}
