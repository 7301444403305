import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";

import Loader from "components/Loader/Loader";
import TitulosxPersonaTable from "../TitulosxPersonaTable/TitulosxPersonaTable";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import TitulosxPersonaFormConsulta from "../TitulosxPersonaPage/TitulosxPersonaFormConsulta";
import {
  clearEntitiesData,
  selectIsLoadingTitxPer,
  selectResponseTitxPer,
  selectTitulosxPersona,
} from "store/titulosxPersona/titulosxPersona.slice";
import { getAllTitulos } from "store/titulos/titulos.slice";
import { getAllEntidadesOtorgantes } from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import TitulosxPersonaNuevo from "../Modal/TitulosxPersonaNuevo/TitulosxPersonaNuevo";
import { useQuery } from "react-query";
import { fetchPersonaByCuil } from "store/persona/persona.api";
import { toast } from "react-toastify";

const TitulosxPersonaPage = () => {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [persona, setPersona] = useState(null);
  const GetTitulosPersona = useSelector(selectTitulosxPersona);
  const [cuil, setCuil] = useState("");

  const isLoading = useSelector(selectIsLoadingTitxPer);
  const response = useSelector(selectResponseTitxPer);

  const isEnable = useMemo(() => cuil && /^\d{11}$/.test(cuil), [cuil]);

  const { isLoading: isFetching } = useQuery(["getPersonaByCuil", cuil], () => fetchPersonaByCuil(cuil), {
    enabled: !!isEnable,
    onSuccess: (data) => {
      setPersona(data[0]);
    },
    onError: (error) => {
      toast.error("La Persona no Existe o el cuil ingresado es incorrecto");
      setPersona(null);
    },
  });

  useEffect(() => {
    dispatch(getAllEntidadesOtorgantes());
    dispatch(getAllTitulos());
  }, [dispatch]);

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);


  useEffect(() => {
    dispatch(clearEntitiesData())
  }, [cuil, isFetching]);

  const muestroTabla = () => {
    if (GetTitulosPersona?.length !== undefined) {
      if (GetTitulosPersona?.length !== 0) {
        return (
          <TitulosxPersonaTable
            datosTxPersona={GetTitulosPersona}
            persona={persona}
          />
        );
      } else {
        return GetTitulosPersona ? (
          <>
            <p
              style={{
                textAlign: "center",
                background: "rgb(25,118,210,0.6)",
                borderRadius: "5px",
                padding: "10px",
                fontWeight: "bold",
              }}
            >
              {`INFORMACIÓN DE ${persona?.apellidos}, ${persona?.nombres}`}
            </p>
            <TitulosxPersonaNuevo persona={persona} />
            <p
              style={{
                textAlign: "center",
                marginBottom: "30px",
                color: "red",
                fontSize: "20px",
              }}
            >
              Esta persona no tiene Títulos cargados.
            </p>
          </>
        ) : (
          ""
        );
      }
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <h1 style={{ marginLeft: "12px" }}> Títulos por Persona </h1>
      <TitulosxPersonaFormConsulta
        persona={persona}
        setCuil={setCuil}
        isEnable={isEnable}
        isFetching={isFetching}
      />
      <div style={{ marginBottom: "30px" }} />
      {isEnable && muestroTabla()}
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </React.Fragment>
  );
};

export default TitulosxPersonaPage;
