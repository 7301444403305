import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { getAreas } from "store/areasList/areasList.slice";
import { myPageAreas } from "../AreasListPage/AreasListPage";
import { Formik } from "formik";
import { vsArea } from "./YupArea";
import { Stack } from "@mui/material";

export default function AreasListForm({ data, onSubmit, onClose }) {
  const [area, setArea] = useState(data || {});
  const dispatch = useDispatch();
  const page = useContext(myPageAreas);

  const handleClose = (event, reason) => {
    onClose();
  };
  const handleSubmit = (area) => {
    onSubmit(area);
    setTimeout(() => {
      dispatch(getAreas(page ? page : 1));
    }, 400);
  };

  return (
    <Formik
      initialValues={{
        id: data?.id || null,
        codigo: data?.codigo || "", // Se le saca el año.
        descripcion: data?.descripcion || "",
      }}
      validationSchema={vsArea}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched, submitForm, dirty }) => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="codigo"
                  label="Código"
                  name="codigo"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.codigo}
                  error={Boolean(errors.codigo && touched.codigo)}
                  helperText={
                    Boolean(errors.codigo) && touched.codigo && errors.codigo
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.descripcion}
                  error={Boolean(errors.descripcion && touched.descripcion)}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClose()}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
