import React from "react";

import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AreasListEditar from "../AreasListEditar/AreasListEditar";
import AreasListEliminar from "../AreasListEliminar/AreasListEliminar";
import { useSelector } from "react-redux";
import { selectAreas } from "store/areasList/areasList.slice";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";

/* const areasList = useSelector(selectAreas); */

const AreasTable = ({ areas }) => {
  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Codigo</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripcion</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas?.records?.length > 0 ? (
            areas?.records?.map((areas) => (
              <TableRow key={areas.idArea}>
                <TableCell sx={{ ...tableBodyCell }}>{areas.idArea}</TableCell>
                <TableCell sx={{ ...tableBodyCell }}>{areas.codigo}</TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {areas.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  {<AreasListEditar areasList={areas} />}
                  {<AreasListEliminar areasList={areas} />}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default AreasTable;
