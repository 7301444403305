import * as Yup from "yup";

export const vTitulosxPersona = Yup.object().shape({
    autocompleteTitulo: Yup.object().shape({
        idTitulo: Yup.number()
            .required("Debe agregar el Título."),
    }),
    duracion: Yup.number()
        .required("Debe agregar un duración de carrera.")
        .positive("El Valor debe ser mayor que cero."),
    promedio: Yup.number()
        .required("Debe agregar el promedio.")
        .positive("El Valor debe ser mayor que cero."),
    fechaEgreso: Yup.date()
        .required("Debe agregar la fecha de Egreso."),
    autocompleteEntOtorgante: Yup.object().shape({
        idEntidadOtorganteDeTitulo: Yup.number()
            .required("Debe seleccionar una Entidad Otorgante."),
    }),
    registrado: Yup.string()
        .required("Debe responder lo solicitado."),
    /*     numeroRegistro: Yup.number()                               // Ver!!! A condición de "registrado".
            .required("Debe agregar un Número de Registro."),
        fechaRegistro: Yup.date()
            .required("Debe agregar la fecha de Registro."), */
    asiento: Yup.string()
        .required("Debe responder lo solicitado."),
}); 