import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ErrorMessage, Formik } from "formik";

import { getVariables } from 'store/variables/variables.slice';
import { myPageVariables } from '../VariablesPage/VariablesPage';
import { vVariables } from 'concursos/utils/validaciones/validaciones';


export default function VariablesForm({ onSubmit }) {

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const page = useContext(myPageVariables);

  const initialValue = {
    descripcion: "",
    fechaInicio: "",
    valor: 0,
  };

  const handleSubmit = (value) => {
    const dato = {
      variable: {
        descripcion: (value.descripcion).toUpperCase() ?? "",
        deshabilitado: 0,
      },
      fechaInicio: value.fechaInicio ?? "",
      valor: value.valor ?? 0,
    }
    //console.log('dato From de variables', dato)

    onSubmit(dato);

    setTimeout(() => {
      dispatch(getVariables(page ? page : 1));        // Refresco tabla inmediatamente.
    }, 400);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vVariables}
      >
        {(formikProps) => (
          <Box style={{ borderRadius: "5px" }}>
            <Grid container spacing={2}>

              <Grid item xs={10} style={{ marginTop: "8px" }}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="descripcion"
                    label="Descripción"
                    name="descripcion"
                    autoFocus
                    value={formikProps.values.descripcion}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="descripcion">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={6.35} style={{ marginTop: "8px" }}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type='number'
                    id="valor"
                    label="Valor"
                    name="valor"
                    autoFocus
                    value={formikProps.values.valor}
                    onChange={formikProps.handleChange}
                  />
                </FormControl>
                <ErrorMessage name="valor">
                  {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={10} md={3.66}>
                <FormControl fullWidth>
                  <DemoContainer components={['DateField']} >
                    <DatePicker
                      label="Fecha de Inicio"
                      onChange={(date) => formikProps.setFieldValue("fechaInicio", date)}
                    />
                  </DemoContainer>
                </FormControl>
                <ErrorMessage name="fechaInicio">
                    {(mensaje) => <div style={{ color: "red", marginLeft: "8px", fontSize: "15px" }}>{mensaje}</div>}
                  </ErrorMessage>
              </Grid>

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
              <Button
                onClick={() => formikProps.submitForm()}
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
