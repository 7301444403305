import { createSlice } from '@reduxjs/toolkit';

const estadoVarGlobalesSlice = createSlice({
  name: 'estadoVarGlobales',
  initialState: {
    valor: "",
  },
  reducers: {
    estadoVarGlobales_ultFechaInicio: (state, action) => {
      state.valor = action.payload
    },
  },
});

export const { estadoVarGlobales_ultFechaInicio } = estadoVarGlobalesSlice.actions;

export const selectUltFechaInicioVar = (state) => state.estadoVarGlobales.valor;

export default estadoVarGlobalesSlice.reducer;