import { useMutation, useQueryClient } from "react-query";
import { updateCompetencia } from "store/competencias/competencia.api";

export const usePutCompetenciaQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (competencia) => {
      debugger;
      const { data } = await updateCompetencia(
        competencia.idCompetencias,
        competencia
      );
      return data;
    },
    {
      onSuccess: () => {
        // Invalida y refetch las queries que dependen de esta data
        queryClient.invalidateQueries(["CompetenciasQuery"]);
      },
    }
  );
};
