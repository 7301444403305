import * as Yup from "yup";

export const vsPersona = Yup.object({
  tipoDocumento: Yup.number().required("Este campo es obligatorio"),
  documento: Yup.number()
    .required("Este campo es obligatorio")
    .integer("Este campo debe ser un número entero")
    .positive("Este campo debe ser un número positivo")
    .min(1000000, "Este campo debe tener al menos 7 dígitos")
    .max(99999999, "Este campo debe tener como máximo 8 dígitos"),
  cuit: Yup.number()
    .required("Este campo es obligatorio")
    .integer("Este campo debe ser un número entero")
    .positive("Este campo debe ser un número positivo")
    .min(1000000000, "Este campo debe tener al menos 11 dígitos")
    .max(99999999999, "Este campo debe tener como máximo 11 dígitos"),
  apellidos: Yup.string()
    .required("Este campo es obligatorio")
    .min(2, "Este campo debe tener al menos 2 caracteres")
    .max(50, "Este campo debe tener como máximo 50 caracteres"),
  //.matches(/^[a-zA-Z]+$/, "El apellido solo debe contener letras"),
  nombres: Yup.string()
    .min(2, "Este campodebe tener al menos 2 caracteres")
    .max(50, "Este campo debe tener como máximo 50 caracteres")
    //.matches(/^[a-zA-Z]+$/, "El nombre solo debe contener letras")
    .required("Este campo es obligatorio"),
  sexo: Yup.string().required("Este campo es obligatorio"),
  fechaNacimiento: Yup.date()
    .typeError("Ingrese una fecha valida")
    .max(new Date(), "Este campo no puede ser posterior a la fecha actual")
    .required("Este campo es obligatorio"),
  telefono: Yup.string().required("Este campo es obligatorio"),
  lugarNacimiento: Yup.string().required("Este campo es obligatorio"),
  domicilio: Yup.string().required("Este campo es obligatorio"),
  idDepartamento_Residencia: Yup.string().required("Este campo es obligatorio"),
  idEstadoCivil: Yup.string().required("Este campo es obligatorio"),
  idTipoAntecedente: Yup.string().required("Este campo es obligatorio"),
  idLocalidad_Residencia: Yup.string().required("Este campo es obligatorio"),
  email: Yup.string()
    .test("Validate Email", "El formato de este campo es inválido", (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    })
    .required("Este campo es obligatorio"),
});

export const vsFiltroPersona = Yup.object({
  documento: Yup.number()
    .integer("Este campo debe ser un número entero")
    .positive("Este campo debe ser un número positivo")
    .min(1000000, "Este campo debe tener al menos 7 dígitos")
    .max(99999999, "Este campo debe tener como máximo 8 dígitos"),
});
