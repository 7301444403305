import { request } from "utils/helpers/request.helpers";

const titulosUrl = `${process.env.REACT_APP_API_URL}/api/Titulos`;


export function fetchTitulosxPersona(pIdPersona) {
  //const userGet = localStorage.getItem("token");
  const url = `${titulosUrl}/GetTitulosxPersona?pIdPersona=${pIdPersona}`;        // ABM Titulos.
  return request(url, { method: "GET" });
}

export function createTituloxPersona(data) {
  return request(`${titulosUrl}/InsertarTitulosxPersona`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateTituloxPersona({ data }) {
  return request(`${titulosUrl}/ModificarTitulosxPersona`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deleteTituloxPersona(pIdTitulosPersona) {
  return await request(`${titulosUrl}/DeshabilitarTitulosPersona?pIdTitulosPersona=${pIdTitulosPersona}`, {
    method: "PUT",
  });
}
