import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import Loader from 'components/Loader/Loader';
import AreasSubareasTable from '../SubareasTable/AreasSubareasTable';

import {
  getAreas,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
  selectAreas
} from 'store/areasList/areasList.slice';


export const myPageAreasSub = React.createContext(1);

export default function AreasSubareasPage() {      // Presentamos las áreas, para elegir la subárea.
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  let [page, setPage] = React.useState(1);

  const areas = useSelector(selectAreas);    // Debo mostrar las áreas, para la busqueda de la subarea.

  useEffect(() => {
    dispatch(getAreas(page ? page : 1));
  }, [page, dispatch])

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <myPageAreasSub.Provider value={page}>
      <React.Fragment>
        {showLoader && <Loader />}
        <h1 style={{ marginBottom: '15px' }}> Subáreas </h1>
        <p style={{ marginBottom: '40px', marginLeft: "6px" }}> Seleccione el Área sobre la cual modificará las Subáreas </p>
        <AreasSubareasTable areas={areas} />
        <Stack spacing={2} style={{ margin: '20px 0px 30px 0px', }}>
          <Pagination count={areas?.pages} page={page} onChange={handleChange} color="primary" />
        </Stack>
      </React.Fragment>
    </myPageAreasSub.Provider>
  );
}
