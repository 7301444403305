import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery, useTheme } from "@mui/material";
import InscripcionesxPersonaForm from '../InscripcionesxPersonaCommon/InscripcionesxPersonaForm';
import { newInscripcionxPersona } from "store/inscripcionesxPersona/inscripcionesxPersona.slice";


export default function InscripcionesxPersonaNuevo({ persona }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown")
      return;
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(newInscripcionxPersona(data));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} style={{margin: "0px 0px 15px 20px", maxWidth: "80px"}}>
        Nuevo
      </Button>

      <Dialog
        fullWidth={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Asignar nueva Inscripción</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InscripcionesxPersonaForm
            persona={persona}
            onSubmitNewEdit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
