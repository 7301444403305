import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";
import TitulosxPersonaEditar from "../Modal/TitulosxPersonaEditar/TitulosxPersonaEditar";
import TitulosxPersonaEliminar from "../Modal/TitulosxPersonaEliminar/TitulosxPersonaEliminar";
import TitulosxPersonaNuevo from "../Modal/TitulosxPersonaNuevo/TitulosxPersonaNuevo";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";


export default function TitulosxPersonaTable({ datosTxPersona, persona }) {

  return (
    <>
      <p style={{ textAlign: "center", background: "rgb(25,118,210,0.6)", borderRadius: "5px", padding: "10px", fontWeight: "bold" }}>
        {datosTxPersona[0]?.apellidoyNombre ?
          `INFORMACIÓN DE ${datosTxPersona[0]?.apellidoyNombre?.toUpperCase()}`
          :
          "Sin Carga para esta persona."}
      </p>
      <TitulosxPersonaNuevo persona={persona} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} style={{ marginBottom: "40px" }} >
            <Table style={{ fontWeight: "bold", textAlign: "center", background: "rgb(25,118,210,0.6)" }}>
              TÍTULOS CARGADOS
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Título</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Carga Horaria</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Duración (Años)</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Promedio</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Egreso</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Entidad Otorgante</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Registrado</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Nº Registro </TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Registro</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Asiento</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Carga</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Acciones</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {datosTxPersona?.map(datosTitulo => (
                  <TableRow
                    key={datosTitulo.idTituloPersona}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell >{datosTitulo.des_Titulo}</TableCell>
                    <TableCell style={{ textAlign: "center" }} >{datosTitulo.cargaHoraria}</TableCell>
                    <TableCell style={{ textAlign: "center" }} >{datosTitulo.duracionCarrera}</TableCell>
                    <TableCell style={{ textAlign: "center" }} >{datosTitulo.promedio}</TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: "100px" }} >{formatDDMMYY(datosTitulo.fechaEgreso)}</TableCell>
                    <TableCell >{datosTitulo.des_EntidadOtorganteDeTitulo}</TableCell>
                    <TableCell style={{ textAlign: "center" }} >
                      {datosTitulo.registrado == "S" ? "Sí" : datosTitulo.registrado == "N" ? "No" : "Valor Inválido"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }} >{datosTitulo.numeroRegistro ?? ""}</TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: "100px" }} >
                      {datosTitulo.fechaRegistro ? formatDDMMYY(datosTitulo.fechaRegistro) : ""}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }} >
                      {datosTitulo.asiento == "S" ? "Sí" : datosTitulo.asiento == "N" ? "No" : "Valor Inválido"}
                    </TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: "100px" }} >{formatDDMMYY(datosTitulo.fechaCarga)}</TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: "120px" }} >
                      <TitulosxPersonaEditar datosTitulo={datosTitulo} persona={persona} />
                      <TitulosxPersonaEliminar datosTitulo={datosTitulo} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}