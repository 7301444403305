import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  fetchZonaPorDepartamento,
  fetchAllZonas,
} from "./zona.api";

const initialState = {
  entitiesAll: [],
  entitiesxDepto: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getAllZonas = createAsyncThunk(
  "zona/fetchAllZonas",
  async () => {
    const r = await fetchAllZonas();
    return r;
  }
);

export const getZonaPorDepartamento = createAsyncThunk(
  "zona/fetchZonaPorDepartamento",
  async (pIdDepartamento) => {
    const r = await fetchZonaPorDepartamento(pIdDepartamento);
    return r;
  }
);

export const clearZonaxDepto = createAction("entitiesxDepto/clearZonaxDepto");

export const ZonaSlice = createSlice({
  name: "zona",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllZonas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllZonas.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesAll = action.payload;
      })
      .addCase(getAllZonas.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(getZonaPorDepartamento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getZonaPorDepartamento.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxDepto = action.payload;
      })
      .addCase(getZonaPorDepartamento.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#region clearZonaxDepto
      .addCase(clearZonaxDepto, (state) => {
        state.entitiesxDepto = []; // Vacía el array entitiesxDepto
      });
    //#endregion
  },
});



export const selectAllZonas = (state) => state.zona.entitiesAll;
export const selectZonaxDepartamento = (state) => state.zona.entitiesxDepto;
export const selectResponseZonas = (state) => state.zona.response;
export const selectIsLoadingZonas = (state) => state.zona.loading;
export const selectIsCreatingZonas = (state) => state.zona.creating;
export const selectIsEditingZonas = (state) => state.zona.editing;
export const selectIsDeletingZonas = (state) => state.zona.deleting;
export const selectErrorZonas = (state) => state.zona.error;

export default ZonaSlice.reducer;
