import React, { createContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Toolbar from "@mui/material/Toolbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Loader from "components/Loader/Loader";
import TituloNuevo from "../TituloNuevo/TituloNuevo";
import TitulosTable from "../TitulosTable/TitulosTable";
import {
  clearEntities,
  clearEntitiesFiltered,
  getSearchTitle,
  selectTitulos,
  selectTitulosFiltrados,
  sResponse,
} from "store/titulos/titulos.slice";

import {
  getTitulos,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
} from "store/titulos/titulos.slice";
import {
  getNivelesEnsenanza,
  selectIsLoading,
} from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import { getCategorias } from "store/categorias/cateogiras.slice";
import GenericSearch from "concursos/utils/search/genericSearch";
import { Grid } from "@mui/material";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";

export const myPageTitulos = createContext(1);

export default function TituloPage() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);
  const showLoader = isLoading || isCreating || isEditing || isDeleting;
  const response = useSelector(sResponse);
  let [page, setPage] = useState(1);

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  useEffect(() => {
    dispatch(getTitulos(page ? page : 1));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
    dispatch(getCategorias());
  }, [dispatch]);

  const titulos = useSelector(selectTitulos);

  const handleChange = (event, values) => {
    setPage(values);
  };

  const datasd = useSelector(selectTitulosFiltrados);

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    setPage(1);
    dispatch(clearEntities());
    dispatch(clearEntitiesFiltered());
    if (e.target.value !== "") {
      dispatch(getSearchTitle(e.target.value));
    } else {
      setPage(1);
      dispatch(getTitulos(page ? page : 1));
    }
  };

  return (
    <>
      <myPageTitulos.Provider value={page}>
        <React.Fragment>
          {showLoader && <Loader />}
          <h1> Títulos </h1>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Toolbar disableGutters>
                <TituloNuevo />
              </Toolbar>
            </Grid>
            <Grid item xs={8}>
              <GenericSearch handleSearch={handleSearch} />
            </Grid>
          </Grid>

          <TitulosTable titulos={datasd ?? titulos} />
          {!datasd && (
            <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
              <Pagination
                count={titulos?.pages}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Stack>
          )}
        </React.Fragment>
        <SnackBarUtils
          view={openSnackbar}
          message={messageAlert}
          closed={(value) => !value && setOpenSnackbar(value)}
        />
      </myPageTitulos.Provider>
    </>
  );
}
