import React, { useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import EliminarCompetencia from "../Common/Eliminar/EliminarCompetencia";
import EditarCompetencia from "../Common/Editar/EditarCompetencia";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";

const CompetenciaTable = ({ competencias, onSelect }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Tipo de título</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Código</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Título</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Materia</TableCell>
              <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competencias?.length > 0 ? (
              competencias?.map((competencia) => (
                <TableRow key={competencia.idCompetencias}>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {competencia.idCompetencias}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {competencia?.tipotituloobjeto?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {competencia.codigoCompetencia}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {competencia?.tituloobjeto?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {competencia?.materiaobjeto?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <EditarCompetencia data={competencia} />
                    <EliminarCompetencia competencia={competencia} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CompetenciaTable;
