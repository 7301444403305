import { request } from "utils/helpers/request.helpers";

const areasListUrl = `${process.env.REACT_APP_API_URL}/api/Areas`;

export function fetchAreasList(page) {
  const user = localStorage.getItem("token");
  const url = `${areasListUrl}/Listar?page=${page}&usuario=${user}`;
  return request(url, { method: "GET" });
}

export function fetchAreasListarTodas() {
  const user = localStorage.getItem("token");
  const url = `${areasListUrl}/listarTodas?usuario=${user}`;
  return request(url, { method: "GET" });
}

export function createArea(data) {
  const user = localStorage.getItem("token");
  return request(`${areasListUrl}/insertarArea?usuario=${user}`, {
    // https://localhost:5001/api/Areas/insertarArea
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateArea({ id, data }) {
  return request(`${areasListUrl}/${id}`, {
    method: "PUT",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function deleteArea(id) {
  return request(`${areasListUrl}/${id}`, {
    method: "DELETE",
  });
}
export function getAreaByID(id) {
  return request(`${areasListUrl}/${id}`, {
    method: "GET",
  });
}
