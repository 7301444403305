import * as Yup from "yup";

export const vsMateria = Yup.object({
  descripcion: Yup.string().required("Este campo es obligatorio"),
  idsRelSubAreas: Yup.array()
    .min(1, "Debe seleccionar al menos una opción")
    .required("Este campo es obligatorio"),
  idsRelNivel: Yup.array()
    .min(1, "Debe seleccionar al menos una opción")
    .required("Este campo es obligatorio"),
});
