import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DeshabilitarItemPuntuacionProcedimiento, crearPuntuacionProcedimiento, editarItemDePuntuacionProcedimiento, selectItemsProcedimientoDetalle } from 'store/PuntuacionPersona/PuntuacionPersona.slice';
import { Modal, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ProcedimientoModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [newProcedimiento, setNewProcedimiento] = useState(false);
  const [procedimiento, setProcedimiento] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editProcedimiento, setEditProcedimiento] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const detallePuntosRedux = useSelector(selectItemsProcedimientoDetalle);
  const [detallePuntos, setDetallePuntos] = useState(detallePuntosRedux);
  const [itemEdit, setItemEdit] = useState();

  useEffect(() => {
    setDetallePuntos(detallePuntosRedux || []);
  }, [detallePuntosRedux]);

  const idItemDePuntuacion = detallePuntos.length > 0 ? detallePuntos[0].idItemDePuntuacion : null;
  
  const handleSaveProcedimiento = () => {  
    const nuevoProcedimiento = { procedimiento, idItemDePuntuacion };
    dispatch(crearPuntuacionProcedimiento(nuevoProcedimiento))
      .unwrap()
      .then(response => {
        setDetallePuntos([...detallePuntos, response.data]);
        setNewProcedimiento(false);
        setProcedimiento("");
      })
      .catch(error => {
        console.error("Error creando el procedimiento:", error);
        alert("Error al guardar el procedimiento. Inténtalo de nuevo.");
      });
  };
  

  const handleEditClick = (index, item) => {
    setEditIndex(index);
    setEditProcedimiento(item.procedimiento);
    setItemEdit(item.idRel_ItemDePuntuacion_Procedimiento);
  };

  const handleEditProcedimiento = (index) => {
    const updatedProcedimiento = {
      idItemDePuntuacion: detallePuntos[index].idItemDePuntuacion,
      procedimiento: editProcedimiento,
      id: itemEdit
    };
    
    dispatch(editarItemDePuntuacionProcedimiento(updatedProcedimiento))
      .unwrap()
      .then(() => {
        const newDetallePuntos = [...detallePuntos];
        newDetallePuntos[index] = updatedProcedimiento;
        setDetallePuntos(newDetallePuntos);
        setEditIndex(null);
        setEditProcedimiento("");
      })
      .catch(error => {
        console.log("🚀 ~ handleEditProcedimiento ~ error:", error);
      });
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    const elementDeshabilitado = detallePuntos[deleteIndex].idRel_ItemDePuntuacion_Procedimiento;
    
    dispatch(DeshabilitarItemPuntuacionProcedimiento(elementDeshabilitado))
      .unwrap()
      .then(() => {
        const newDetallePuntos = [...detallePuntos];
        newDetallePuntos[deleteIndex] = { ...detallePuntos[deleteIndex], deshabilitado: 1 };
        setDetallePuntos(newDetallePuntos);
        setDeleteIndex(null);
        setOpenDeleteDialog(false);
      })
      .catch(error => {
        console.error("Error deshabilitando el procedimiento:", error);
      });
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          boxShadow: 8,
          borderRadius: 2,
          p: 4,
          maxWidth: 1000,
          width: '100%',
        }}
      >
        {newProcedimiento ? (
          <div>
            <p>Nuevo Procedimiento Docente</p>
            <TextField 
              id="standard-basic" 
              label="Procedimiento" 
              variant="standard" 
              fullWidth 
              value={procedimiento}
              onChange={(e) => setProcedimiento(e.target.value)}
              sx={{ width: 500, maxWidth: '100%' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
              {/* pasarle el parametro idItemDePuntuacion al handleSaveProcedimiento  */}
              <Button variant="contained" onClick={handleSaveProcedimiento}>Guardar</Button>
              <Button onClick={() => setNewProcedimiento(false)}>Cancelar</Button>
            </Box>
          </div>
        ) : (
          <>
            <Button variant="contained" onClick={() => setNewProcedimiento(true)}>
              Nuevo
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Procedimientos</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detallePuntos
                .filter(item => item.deshabilitado !== 1)
                .map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {editIndex === index ? (
                        <TextField 
                          value={editProcedimiento}
                          onChange={(e) => setEditProcedimiento(e.target.value)}
                          variant="standard"
                        />
                      ) : (
                        item.procedimiento
                      )}
                    </TableCell>
                    <TableCell>
                      {editIndex === index ? (
                        <>
                          <Button onClick={() => handleEditProcedimiento(index)}>Guardar</Button>
                          <Button onClick={() => setEditIndex(null)}>Cancelar</Button>
                        </>
                      ) : (
                        <>
                          <EditIcon color="primary" onClick={() => handleEditClick(index, item)} />
                          <DeleteIcon color="disabled" onClick={() => handleDeleteClick(index)} />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estás seguro de que deseas eliminar este procedimiento?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default ProcedimientoModal;
