import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TituloForm from "../TituloForm/TituloForm";
import { getTitulos, newTitulo } from "store/titulos/titulos.slice";
import CustomModal from "components/customModal/CustomModal";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import { myPageTitulos } from "../TitulosPage/TituloPage";
//import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";

export default function ActividadNuevo() {
  const [open, setOpen] = useState(false);
  const page = useContext(myPageTitulos);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(newTitulo(data));
    setTimeout(() => {
      dispatch(getTitulos(page ? page : 1));
      dispatch(getNivelesEnsenanza(1));
    }, 400);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo
      </Button>
      <CustomModal
        open={open}
        setOpen={setOpen}
        title="Agregar Título / Certificado"
      >
        <TituloForm onSubmit={handleSubmit} onClose={handleClose} />
      </CustomModal>
    </React.Fragment>
  );
}
