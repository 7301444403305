import { request } from "utils/helpers/request.helpers";

const competenciaUrl = `${process.env.REACT_APP_API_URL}/api/Competencias`;

export function fetchCompetencias() {
  const url = `${competenciaUrl}/Listar`; // ABM Titulos.

  return request(url, { method: "GET" });
}

export function fetchCompetenciasBySubArea(
  id,
  page,
  titulo = null,
  materia = null
) {
  let tituloParam = titulo ? "&titulo=" + titulo : "";
  if (!titulo) {
    tituloParam = "";
  }

  let materiaParam = materia ? "&materia=" + materia : "";
  if (!materia) {
    materiaParam = "";
  }
  const url = `${competenciaUrl}/GetCompetencias_ByIdSubArea?pIdSubArea=${id}&page=${page}${tituloParam}${materiaParam}`; // ABM Titulos.

  return request(url, { method: "GET" });
}

export function createCompetencia(data) {
  return request(`${competenciaUrl}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateCompetencia(id, data) {
  return request(`${competenciaUrl}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function getCompetenciaWithArea(idArea, pageCompetencia) {
  const url = `${competenciaUrl}/ListarCompetenciasXArea/${idArea}?page=${pageCompetencia}`; // ABM Titulos.

  return request(url, { method: "GET" });
}

export function filterCompetencia(data) {
  return request(`${competenciaUrl}/filter`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function deleteCompetencia(id) {
  return request(`${competenciaUrl}/${id}`, {
    method: "DELETE",
  });
}
