import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Grid, Paper } from "@mui/material";
import Loader from "components/Loader/Loader";
import {
  selectPersonas,
  getPersonas,
  selectIsLoading,
  selectError,
  selectMessageResponse,
  clearPersonaSearchData,
} from "store/persona/persona.slice";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import PersonaTable from "../Table/PersonaTable";
import EditarPersona from "../Modal/Editar/EditarPersona";
import { PersonaContext } from "../Modal/Common/PersonaProvider";
import ConfirmDialog from "../Modal/Common/ConfirmDialog";
import PersonaFiltro from "../Modal/Common/PersonaFiltro";

const initialPage = {
  page: 1,
  nombres: null,
  apellidos: null,
  documento: null,
};

export default function PersonaPage() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);
  const dispatch = useDispatch();
  const { persona, setPersona } = useContext(PersonaContext);
  const [mensaje, setMensaje] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const isLoading = useSelector(selectIsLoading);
  const personas = useSelector(selectPersonas);
  const error = useSelector(selectError);
  const sSelectMessageResponse = useSelector(selectMessageResponse);

  let [page, setPage] = React.useState(initialPage);

  useEffect(() => {
    dispatch(getPersonas(page));
  }, [page, dispatch]);

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  useEffect(() => {
    let message = [];
    if (sSelectMessageResponse?.length > 0) {
      if (sSelectMessageResponse[0]?.status === 500) {
        setMensaje(sSelectMessageResponse[0].description);
        message = [
          {
            ...sSelectMessageResponse[0],
            description: "Ocurrió un error al procesar los datos",
          },
        ];
        setOpenDialog(true);
      } else {
        message = [...sSelectMessageResponse];
        setPersona(null);
        dispatch(clearPersonaSearchData());
        setShowForm(false);
        setPage({ ...page });
      }
      const response = {
        message,
      };
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sSelectMessageResponse, setPersona]);

  useEffect(() => {
    if (error) {
      setMessageAlert(SnackBarMessageResponse(error));
      setOpenSnackbar(true);
    }
  }, [error]);

  const handleSearch = ({ nombres, apellidos, documento }) => {
    setPage({ ...initialPage, nombres, apellidos, documento });
  };

  const handleForm = (value, isNew = false) => {
    dispatch(clearPersonaSearchData());
    isNew && setPersona(null);
    setShowForm(value);
    !value && setPage({ ...page });
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ConfirmDialog
        mensaje={mensaje}
        onClose={onCloseDialog}
        open={openDialog}
        title={"Atención"}
      />
      <React.Fragment>
        {isLoading && <Loader />}
        {!showForm ? (
          <>
            <div style={{ display: "flex", alignItems: "left" }}>
              <h1 style={{ margin: "10px 0" }}>Persona</h1>
            </div>
            <Grid>
              <Paper
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingBottom: 15,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 0,
                }}
              >
                <PersonaFiltro
                  onAdd={() => handleForm(true, true)}
                  onSearch={handleSearch}
                  filter={page}
                />
              </Paper>
              <PersonaTable
                personas={personas?.records}
                onSelect={handleForm}
              />
              <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
                <Pagination
                  count={personas?.pages}
                  page={page?.page}
                  onChange={handleChange}
                  color="primary"
                />
              </Stack>
            </Grid>
          </>
        ) : (
          <>
            <EditarPersona onClose={handleForm} persona={persona} />
          </>
        )}
      </React.Fragment>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </>
  );
}
