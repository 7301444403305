import { TramOutlined } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCompetencia,
  createTitulo,
  deleteCompetencia,
  fetchCompetencias,
  filterCompetencia,
  getCompetenciaWithArea,
  updateCompetencia,
} from "./competencia.api";

const initialState = {
  entities: [],
  areasFiltered: null, // La entrada original es un objeto {}.
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getCompetencias = createAsyncThunk(
  "competencias/getCompetencias",
  async () => {
    const r = await fetchCompetencias();
    return r;
  }
);
export const getCompetenciasXAreas = createAsyncThunk(
  "competencias/getCompetenciasXarea",
  async ({ idArea, pageCompetencia }) => {
    const r = await getCompetenciaWithArea(idArea, pageCompetencia);
    return r;
  }
);

export const createCompetencias = createAsyncThunk(
  "competencias/postCompetencia",
  async (data) => {
    const r = await createCompetencia(data);
    return r;
  }
);

export const editarCompetencia = createAsyncThunk(
  "competencias/putCompetencia",
  async (data) => {
    const r = await updateCompetencia(data.idCompetencias, data);
    return r;
  }
);

export const deleteCompetencias = createAsyncThunk(
  "competencias/eliminar",
  async (id) => {
    const r = await deleteCompetencia(id);
    return r;
  }
);

export const filterCompetencias = createAsyncThunk(
  "competencias/filtrar",
  async (data) => {
    const r = await filterCompetencia(data);
    return r;
  }
);

export const CompetenciaSlice = createSlice({
  name: "competencia",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompetencias.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompetencias.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getCompetencias.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCompetencias.pending, (state) => {
        // pendiente.
        state.creating = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(createCompetencias.fulfilled, (state, action) => {
        // cumplida.
        state.creating = false;
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(createCompetencias.rejected, (state, action) => {
        // rechazado.
        state.creating = false;
        state.error = action.error.message;
      })
      .addCase(editarCompetencia.pending, (state) => {
        // pendiente.
        state.editing = true;
        state.error = null;
      })
      .addCase(editarCompetencia.fulfilled, (state, action) => {
        // cumplida.
        state.editing = false;
        state.entities = action.payload;
      })
      .addCase(editarCompetencia.rejected, (state, action) => {
        // rechazado.
        state.editing = false;
        state.error = action.error.message;
      })
      .addCase(deleteCompetencias.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(deleteCompetencias.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state.entities?.filter(
          (competencia) => competencia.id !== action.payload?.id
        );
      })
      .addCase(deleteCompetencias.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      })
      .addCase(filterCompetencias.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(filterCompetencias.fulfilled, (state, action) => {
        state.loading = false;
        state.areasFiltered = action.payload.data;
      })
      .addCase(filterCompetencias.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCompetenciasXAreas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompetenciasXAreas.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getCompetenciasXAreas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectCompetencias = (state) => state.competencias.entities; // records??.
export const selectIsLoading = (state) => state.competencias.loading;
export const selectIsCreating = (state) => state.competencias.creating;
export const selectIsEditing = (state) => state.competencias.editing;
export const selectIsDeleting = (state) => state.competencias.deleting;
export const selectError = (state) => state.competencias.error;
export const competenciasFiltered = (state) => state.competencias.areasFiltered;

export default CompetenciaSlice.reducer;
