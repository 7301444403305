import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { newVariableParam } from 'store/variablesParam/variablesParam.slice';
import VariablesParamForm from '../VariablesParamForm/VariablesParamForm';
import SnackBarUtils from 'utils/MUI/SnackbarUtils';

export default function VariablesParamNuevo() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (dato) => {
    setOpen(false);
    dispatch(newVariableParam(dato)).then((resultAction) => {
      if (newVariableParam.fulfilled.match(resultAction)) {
        setView(true);
        setMessage(resultAction.payload.message[0].description);
      }
    });
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nueva
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Agregar Variable Parametridaza</DialogTitle>
        <DialogContent>
          <VariablesParamForm onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
      <SnackBarUtils
        closed={(value) => setView(value)}
        message={message}
        view={view}
      />
    </React.Fragment>
  );
}
