import React from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SubareaForm from "../SubareasForm/SubareasForm";
import {
  getSubareasByID,
  newSubarea,
} from "../../../../store/subareas/subareas.slice";
import CustomModal from "components/customModal/CustomModal";
import { Stack } from "@mui/material";

export default function SubareaNueva({ area, handleConteinerClose }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    data = {
      ...data,
      area: area.idArea,
      deshabilitado: 0,
      porDefecto: false,
      idSubAreas: 0,
    };
    dispatch(newSubarea(data));
    //window.location.reload(true);
  };

  return (
    <React.Fragment>
      <Stack
        spacing={2}
        direction="row"
        sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={handleConteinerClose}
          spacing={2}
        >
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleClickOpen}>
          Nuevo
        </Button>
      </Stack>
      <CustomModal
        title={"Agregar Subárea"}
        open={open}
        setOpen={handleClose}
        maxWidth="md"
      >
        <DialogContent>
          <SubareaForm
            area={area}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
