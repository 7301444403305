import { request } from "utils/helpers/request.helpers";

const inscripcionesUrl = `${process.env.REACT_APP_API_URL}/api/InscripcionGeneral`;

const user = localStorage.getItem("token");

export function searchInscripcionGeneral(page, pBusqueda) {
  const url = `${inscripcionesUrl}/FiltrarInscripcionGeneral?pBusqueda=${pBusqueda}&page=${page}&usuario=${user}`;
  return request(url, { method: "GET" });
}

export function searchInscGeneralesxDepto(pIdDepartamento) {
  const url = `${inscripcionesUrl}/ListarInsripcionesGeneralesAbiertas_xDepartamento?pIdDepartamento=${pIdDepartamento}&usuario=${user}`;
  return request(url, { method: "GET" });
}

export function searchEstadosInscGeneral() {
  const url = `${inscripcionesUrl}/GetEstadosInscripcionGeneral`;
  return request(url, { method: "GET" });
}

export function createInscripcionGeneral(data) {
  return request(`${inscripcionesUrl}/InsertarInscripcion`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateInscripcionGeneral({ data }) {
  return request(`${inscripcionesUrl}/ModificarInscripcion?pIdInscGeneral=${data.idInscGeneral}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deleteInscripcionGeneral(pIdInscGeneral) {
  return await request(`${inscripcionesUrl}/Deshabilitar?pIdInscGeneral=${pIdInscGeneral}`, {
    method: "PUT",
  });
} 
