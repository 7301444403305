import { request } from 'utils/helpers/request.helpers';

const proyectosUrl = `${process.env.REACT_APP_API_URL}/api/Proyecto`;

export function fetchProyectos(id) {
  const url = id
    ? `${proyectosUrl}/ListarProyectosInstitucion/${id}`
    : `${proyectosUrl}/ListarProyecto`;
  return request(url, { method: 'GET' });
}

export function createProyecto(data) {
  
  return request(`${proyectosUrl}/`, {
    method: 'POST',
    headers:{ 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function updateProyecto({ id, data }) {
  return request(`${proyectosUrl}/${id}`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function deleteProyecto(id) {
  return request(`${proyectosUrl}/${id}`, {
    method: 'DELETE',
  });
}
