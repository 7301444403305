import React, { createContext, useState } from "react";

// Componente Provider del contexto
export const CompetenciaProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [competencia, setCompetencia] = useState({});
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");

  return (
    <CompetenciaContext.Provider
      value={{
        page,
        competencia,
        setPage,
        setCompetencia,
        openSnackbar,
        setOpenSnackbar,
        messageAlert,
        setMessageAlert,
      }}
    >
      {children}
    </CompetenciaContext.Provider>
  );
};

// Creamos el contexto
export const CompetenciaContext = createContext();
