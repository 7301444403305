import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import FormHelperText from "@mui/material/FormHelperText";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Box, Button, Input, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";


import { selectTitulosAll } from 'store/titulos/titulos.slice';
import { getTitulosxPersona, selectErrorTitxPer } from 'store/titulosxPersona/titulosxPersona.slice';
import { selectEntidadesOtorgantesAll } from 'store/entidadesOtorgantes/entidadesOtorgantes.slice';
import { vTitulosxPersona } from './TitulosxPersonaYup';


export default function TitulosxPersonaForm({ datosTitulo, persona, onSubmitNewEdit }) {

  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  const [listaAllTitulos, setListaAllTitulos] = useState([]);
  const [listaEntOtorgantes, setListaEntOtorgantes] = useState([]);
  const getDesc_AllTitulos = useSelector(selectTitulosAll);
  const getDesc_AllEntOtorgantes = useSelector(selectEntidadesOtorgantesAll);
  const titxPerError = useSelector(selectErrorTitxPer);
  const siNoRegistro = [{ registrado: 0, desRegistro: "Sí", nomenRegistro: "S" }, { registrado: 1, desRegistro: "No", nomenRegistro: "N" }]
  const siNoAsiento = [{ asiento: 0, desAsiento: "Sí", nomenAsiento: "S" }, { asiento: 1, desAsiento: "No", nomenAsiento: "N" }]

  useEffect(() => {
    let lista1 = getDesc_AllTitulos?.map((x) => ({
      descripcion: x.descripcion + "- Código: " + x.codigo,
      idTitulo: x.idTitulo,
    }));
    setListaAllTitulos(lista1);
    let lista2 = getDesc_AllEntOtorgantes?.map((x) => ({
      descripcion: x.descripcion + "- ID: " + x.idEntidad,
      idEntidadOtorganteDeTitulo: x.idEntidad,
    }));
    setListaEntOtorgantes(lista2);
  }, [getDesc_AllEntOtorgantes, getDesc_AllTitulos]);

  const handleSubmit = (values) => {
    const datoAEnviar = {
      idPersona: persona?.idPersona || 0,
      idTituloPersona: datosTitulo?.idTituloPersona || "",
      idTitulo: values?.autocompleteTitulo?.idTitulo ?? "",
      promedio: values?.promedio || null,
      duracionCarrera: values?.duracion || "",
      fechaEgreso: values?.fechaEgreso || "",
      idEntidadOtorganteDeTitulo: values?.autocompleteEntOtorgante?.idEntidadOtorganteDeTitulo || "",
      registrado: values?.registrado || null,
      numeroRegistro: values?.numeroRegistro || null,
      fechaRegistro: values?.fechaRegistro || null,
      asiento: values?.asiento || "",
      cargaHoraria: datosTitulo?.cargaHoraria || "",
      deshabilitado: 0,
    }
    //console.log('datoAEnviar', datoAEnviar)
    onSubmitNewEdit(datoAEnviar);
    setTimeout(() => {
      dispatch(getTitulosxPersona(persona?.idPersona));
    }, 400);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          autocompleteTitulo: {
            idTitulo: datosTitulo?.idTitulo || "",
            descripcion: datosTitulo?.des_Titulo || ""
          },
          duracion: datosTitulo?.duracionCarrera || "",
          promedio: datosTitulo?.promedio || "",
          fechaEgreso: datosTitulo?.fechaEgreso ? dayjs(datosTitulo?.fechaEgreso) : null,
          autocompleteEntOtorgante: {
            idEntidadOtorganteDeTitulo: datosTitulo?.idEntidadOtorganteDeTitulo || "",
            descripcion: datosTitulo?.des_EntidadOtorganteDeTitulo || ""
          },
          registrado: datosTitulo?.registrado || null,
          numeroRegistro: datosTitulo?.numeroRegistro || null,
          fechaRegistro: datosTitulo?.fechaRegistro ? dayjs(datosTitulo?.fechaRegistro) : null,
          asiento: datosTitulo?.asiento || "",
          cargaHoraria: datosTitulo?.cargaHoraria || "",
        }}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vTitulosxPersona}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Box
            component="form"
            style={{ borderRadius: "5px" }}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idTitulo) && touched.idTitulo}
                >
                  <Form>
                    <Field name="autocompleteTitulo">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          options={listaAllTitulos}
                          getOptionLabel={(option) => option.descripcion}
                          onChange={(event, newValue) => {
                            setFieldValue('autocompleteTitulo',
                              {
                                idTitulo: newValue?.idTitulo || "",
                                descripcion: newValue?.descripcion || ""
                              });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Título"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Form>
                  {Boolean(errors.idTitulo) && touched.idTitulo && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {titxPerError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    type='number'
                    id="duracion"
                    label="Duración (en Años)"
                    name="duracion"
                    autoFocus
                    value={values?.duracion}
                    onChange={handleChange}
                    error={Boolean(errors.duracion && touched.duracion)}
                    helperText={
                      touched.duracion &&
                      Boolean(errors.duracion) &&
                      errors.duracion
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    type='number'
                    id="promedio"
                    label="Promedio"
                    name="promedio"
                    autoFocus
                    value={values?.promedio}
                    onChange={handleChange}
                    error={Boolean(errors.promedio && touched.promedio)}
                    helperText={
                      touched.promedio &&
                      Boolean(errors.promedio) &&
                      errors.promedio
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de Egreso"
                    name="fechaEgreso"
                    value={values.fechaEgreso}
                    onChange={(value) => setFieldValue("fechaEgreso", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          (Boolean(errors.fechaEgreso) &&
                            touched.fechaEgreso) &&
                          errors.fechaEgreso,
                        error:
                          Boolean(errors.fechaEgreso) && Boolean(touched.fechaEgreso),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={8}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idEntidadOtorganteDeTitulo) && touched.idEntidadOtorganteDeTitulo}
                >
                  <Form>
                    <Field name="autocompleteEntOtorgante">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          options={listaEntOtorgantes}
                          getOptionLabel={(option) => option.descripcion}
                          onChange={(event, newValue) => {
                            setFieldValue('autocompleteEntOtorgante',
                              {
                                idEntidadOtorganteDeTitulo: newValue?.idEntidadOtorganteDeTitulo || "",
                                descripcion: newValue?.descripcion || ""
                              });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Entidad Otorgante"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Form>
                  {Boolean(errors.idEntidadOtorganteDeTitulo) && touched.idEntidadOtorganteDeTitulo && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {titxPerError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    type='number'
                    id="cargaHoraria"
                    label="Carga Horaria"
                    name="cargaHoraria"
                    value={values?.cargaHoraria}
                    onChange={handleChange}
                    error={Boolean(errors.cargaHoraria && touched.cargaHoraria)}
                    helperText={
                      touched.cargaHoraria &&
                      Boolean(errors.cargaHoraria) &&
                      errors.cargaHoraria
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  error={!!errors.registrado && touched.registrado}
                >
                  <InputLabel id="registrado">Registrado?</InputLabel>
                  <Select
                    labelId="registrado"
                    id="registrado"
                    label="Registrado?"
                    name="registrado"
                    value={values?.nomenRegistro}
                    defaultValue={datosTitulo?.registrado || ""}
                    onChange={handleChange}
                    error={!!errors.registrado && touched.registrado}
                  >
                    {siNoRegistro?.map((option) => (
                      <MenuItem
                        key={option.registrado}
                        value={option.nomenRegistro}
                      >
                        {option.desRegistro}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.registrado && touched.registrado && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {titxPerError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {values?.registrado === "N" ?
                    (<TextField
                      disabled
                      type='number'
                      id="numeroRegistro"
                      label="Nº Registro"
                      name="numeroRegistro"
                      autoFocus
                      value={""}
                      onChange={handleChange}
                      error={Boolean(errors.numeroRegistro && touched.numeroRegistro)}
                      helperText={
                        touched.numeroRegistro &&
                        Boolean(errors.numeroRegistro) &&
                        errors.numeroRegistro
                      }
                    />) :
                    (<TextField
                      type='number'
                      id="numeroRegistro"
                      label="Nº Registro"
                      name="numeroRegistro"
                      autoFocus
                      value={values?.numeroRegistro}
                      onChange={handleChange}
                      error={Boolean(errors.numeroRegistro && touched.numeroRegistro)}
                      helperText={
                        touched.numeroRegistro &&
                        Boolean(errors.numeroRegistro) &&
                        errors.numeroRegistro
                      }
                    />)
                  }
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {values?.registrado === "N" ?
                    (<DatePicker
                      disabled
                      label="Fecha de Registro"
                      name="fechaRegistro"
                      value={values.fechaRegistro}
                      onChange={(value) => setFieldValue("fechaRegistro", value)}
                      slotProps={{
                        textField: {
                          helperText:
                            (Boolean(errors.fechaRegistro) &&
                              touched.fechaRegistro) &&
                            errors.fechaRegistro,
                          error:
                            Boolean(errors.fechaRegistro) && Boolean(touched.fechaRegistro),
                        },
                      }}
                    />) :
                    (<DatePicker
                      label="Fecha de Registro"
                      name="fechaRegistro"
                      value={values.fechaRegistro}
                      onChange={(value) => setFieldValue("fechaRegistro", value)}
                      slotProps={{
                        textField: {
                          helperText:
                            (Boolean(errors.fechaRegistro) &&
                              touched.fechaRegistro) &&
                            errors.fechaRegistro,
                          error:
                            Boolean(errors.fechaRegistro) && Boolean(touched.fechaRegistro),
                        },
                      }}
                    />)}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={!!errors.asiento && touched.asiento}
                >
                  <InputLabel id="asiento">Asiento?</InputLabel>
                  <Select
                    labelId="asiento"
                    id="asiento"
                    label="Asiento?"
                    name="asiento"
                    value={values?.nomenAsiento}
                    defaultValue={datosTitulo?.asiento || ""}
                    onChange={handleChange}
                    error={!!errors.asiento && touched.asiento}
                  >
                    {siNoAsiento?.map((option) => (
                      <MenuItem
                        key={option.asiento}
                        value={option.nomenAsiento}
                      >
                        {option.desAsiento}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.asiento && touched.asiento && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {titxPerError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
              <Button
                onClick={() => submitForm()}
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}