import { useMutation, useQueryClient } from "react-query";
import { createCompetencia } from "store/competencias/competencia.api";

export const usePostCompetenciaQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (competencia) => {
      const response = await createCompetencia(competencia);
      return response;
    },
    {
      onSuccess: () => {
        // Invalida y refetch las queries que dependen de esta data
        queryClient.invalidateQueries(["CompetenciasQuery"]);
      },
    }
  );
};
