import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "components/Layout/Layout";
import Login from "views/Login/LoginPage/LoginPage";
import Actividades from "views/Actividades/ActividadesPage/ActividadesPage";
import Materias from "./concursos/views/Materias/MateriasPage/MateriasPage";
import Titulos from "./concursos/views/Titulos/TitulosPage/TituloPage";
import AreasListPage from "concursos/views/Areas/AreasListPage/AreasListPage";
import AreasSubareasPage from "concursos/views/Subareas/SubareasPage/AreasSubareasPage";
import InscripcionGeneralPage from "concursos/views/InscripcionGeneral/Page/InscripcionGeneralPage";
import VariablesxPerPage from "concursos/views/Variables/VariablesPage/VariablesPage";
import VariablesParamxPerPage from "concursos/views/VariablesParam/VariablesParamPage/VariablesParamPage";
import PuntajexAgentePage from "concursos/views/PuntajexAgente/PuntajexAgentePage/PuntajexAgentePage";
import TitulosxPersonaPage from "concursos/views/TitulosxPersona/TitulosxPersonaPage/TitulosxPersonaPage";
import InscripcionesxPersonaPage from "concursos/views/InscripcionesxPersona/InscripcionesxPersonaPage/InscripcionesxPersonaPage";
import PeriodosPage from "concursos/views/Periodos/Page/PeriodosPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import PersonaPage from "concursos/views/Persona/Page/PersonaPage";
import { PersonaProvider } from "concursos/views/Persona/Modal/Common/PersonaProvider";
import { useDispatch, useSelector } from "react-redux";
import Concurso from "concursos/views/Concurso/Concurso/Concurso";
import ProyectosPage from "views/Proyectos/ProyectosPage/ProyectosPage";
import { ItemsPuntuacion } from "concursos/views/ItemsPuntuacion/ItemsPuntuacion";
import { QueryClient, QueryClientProvider } from "react-query";
import { CertificadosPage } from "concursos/views/Certificados/CertificadosPage";
import { CompetenciaProvider } from "concursos/views/Competencia/Common/CompetenciaProvider";
import CompetenciaPage from "concursos/views/Competencia/Page/CompetenciaPage";

export default function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  // Create a client
  const queryClient = new QueryClient();

  //     useEffect(() => {
  // // Recupera el estado de autenticación desde localStorage al cargar la página
  //     const savedAuth = localStorage.getItem("token");
  //     if (savedAuth) {
  //       dispatch(login());
  //     } else {
  //       dispatch(logout());
  //     }
  //   }, [dispatch]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={!!token ? <Layout /> : <Navigate to="/login" />}
            >
              <Route path="concursos" element={<Concurso />} />
              <Route path="proyectos" element={<ProyectosPage />} />
              <Route path="actividades" element={<Actividades />} />
              <Route
                path="concursos/Legajo/Persona"
                element={
                  <PersonaProvider>
                    <PersonaPage />
                  </PersonaProvider>
                }
              />
              <Route
                path="concursos/Legajo/TitulosxPersona"
                element={<TitulosxPersonaPage />}
              />
              <Route
                path="concursos/Legajo/PuntajexAgente"
                element={<PuntajexAgentePage />}
              />
              <Route
                path="concursos/Legajo/InscripcionesxPersona"
                element={<InscripcionesxPersonaPage />}
              />
              <Route
                path="concursos/Legajo/Certificados"
                element={<CertificadosPage />}
              />
              <Route
                path="concursos/Inscripciones/Periodos"
                element={<PeriodosPage />}
              />
              <Route
                path="concursos/Inscripciones/InscripcionGeneral"
                element={<InscripcionGeneralPage />}
              />
              <Route
                path="concursos/Entidades/Areas"
                element={<AreasListPage />}
              />
              <Route
                path="concursos/Entidades/Subareas"
                element={<AreasSubareasPage />}
              />
              <Route path="concursos/Entidades/Titulos" element={<Titulos />} />
              <Route
                path="concursos/Entidades/Materias"
                element={<Materias />}
              />
              <Route
                path="concursos/Entidades/Competencia"
                element={
                  <CompetenciaProvider>
                    <CompetenciaPage />
                  </CompetenciaProvider>
                }
              />
              <Route
                path="concursos/Entidades/VariablesxPer"
                element={<VariablesxPerPage />}
              />
              <Route
                path="concursos/Entidades/VariablesParamxPer"
                element={<VariablesParamxPerPage />}
              />
              <Route
                path="concursos/Entidades/ItemsPuntuacion"
                element={<ItemsPuntuacion />}
              />
            </Route>
          </Routes>
        </Router>
        <ToastContainer theme="colored" hideProgressBar />
      </QueryClientProvider>
    </>
  );
}
