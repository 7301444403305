import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Autocomplete, Box, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { ErrorMessage, Formik } from "formik";

import { inputPxA } from "../PuntajexAgenteStyles";
import "../PuntajexAgenteCss.css";
import { selectPersonas } from "store/persona/persona.slice";
import { getPxAgenteA1EndPoint, getPxAgenteA2EndPoint } from "store/puntajexAgente/puntajexAgente.slice";
import { GetPeriodo_ByTipoPeriodo, sPeriodosxTipo } from "store/periodos/periodos.slice";
import { selectSubareas } from "store/subareas/subareas.slice";


export default function PuntajexAgenteForm() {

  const dispatch = useDispatch();
  const [areasInvolucradas, setAreasInvolucradas] = useState([]);
  const [descripcionesPeriodosHook, setdescripcionesPeriodosHook] = useState([]);
  const [datosSubareas, setDatosSubareas] = useState([]);
  const [persona, setPersona] = useState(null);
  const [cuitValid, setCuitValid] = useState(false);
  const Personas = useSelector(selectPersonas);
  const getAreasSubareas = useSelector(selectSubareas);
  const getPeriodosxTipo = useSelector(sPeriodosxTipo);
  const tiposPeriodos = [
    { idTipoPeriodo: 4, descripcion: 'Inscripción Juntas' },
    { idTipoPeriodo: 5, descripcion: 'Período de Tacha Juntas' }]

  useEffect(() => {
    let lista1 = getPeriodosxTipo?.data?.map((x) => ({
      label: x.descripcion,
      idTipoPeriodo: x.idTipoPeriodo,
      idPeriodo: x.idPeriodo
    }));
    setdescripcionesPeriodosHook(lista1);
    let lista2 = getAreasSubareas?.map((areas) => {
      return areas?.subarea
    });
    //console.log('lista2 form pun x agente', lista2)
    setDatosSubareas(lista2);
  }, [getPeriodosxTipo, getAreasSubareas]);

  //Logica para enviar formulario al backend
  const handleSubmit = (value) => {
    const datoAEnviarA1 = {
      idPersona: persona?.idPersona,
      idPeriodo: value.idPeriodo,
      idSubArea1: areasInvolucradas[0]?.idSubAreas ?? null,
    }
    const datoAEnviarA2 = {
      idPersona: persona?.idPersona,
      idPeriodo: value.idPeriodo,
      idSubArea2: areasInvolucradas[1]?.idSubAreas ?? null,
    }
    dispatch(getPxAgenteA1EndPoint(datoAEnviarA1))    // Muestro Área 1.
    dispatch(getPxAgenteA2EndPoint(datoAEnviarA2))    // Muestro Área 2.
  };

  //Campos del formulario
  const initialValue = {
    idTipoPeriodo: 0,
    idPeriodo: null,
    idPersona: 0,
    cuit: "",
    idSubarea: null,
  };

  const handleCheckCuit = (value) => {
    if (Personas.find((x) => x.cuit == value) != undefined) {
      setCuitValid(true);
      setPersona(Personas.find((x) => x.cuit == value));
      return true;
    } else {
      setPersona(null);
      setCuitValid(false);
      return false;
    }
  };

  const handleChangeInputs = (e, name) => {
    setAreasInvolucradas(e);
  };

  function llegoA2() {
    if (areasInvolucradas?.length <= 1) {
      return datosSubareas
    } else {
      return []
    }
  }

  //Validaciones del formulario.
  const validationSchema = Yup.object().shape({
    idTipoPeriodo: Yup.number()
      .required("Debe seleccionar un Tipo de Período."),
    idPeriodo: Yup.number()
      .required("Debe seleccionar un Período."),
    cuit: Yup.number().test(
      "CuitValidation",
      'Cuit no registrado en base, consulta a la Junta de Educación.',
      (value) => {
        return handleCheckCuit(value);
      }
    ),
  });

  return (
    <>
      <Formik
        initialValues={initialValue}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Box
            component="form"
            style={{ borderRadius: "5px", marginLeft: "20px" }}
            sx={{ mt: 1 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8} md={5}>

                <FormControl fullWidth>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      formikProps.setFieldValue(
                        "idTipoPeriodo",
                        newValue !== null ? newValue.idTipoPeriodo : ""
                      )
                      dispatch(GetPeriodo_ByTipoPeriodo(newValue?.idTipoPeriodo));
                    }}
                    options={tiposPeriodos ?? []}
                    getOptionLabel={(option) => option.descripcion ?? ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Período"
                        variant="outlined"
                      />)}
                  />
                </FormControl>
                <ErrorMessage name="idTipoPeriodo">
                  {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                </ErrorMessage>

                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ mt: 1.5 }}
                    disablePortal
                    onChange={(event, newValue) => {
                      formikProps.setFieldValue(
                        "idPeriodo",
                        newValue !== null ? newValue.idPeriodo : ""
                      );
                    }}
                    id="combo-box-demo"
                    options={descripcionesPeriodosHook ?? []}
                    name="idPeriodo"
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Descripción de Período" />
                    )}
                  />
                </FormControl>
                <ErrorMessage name="idPeriodo">
                  {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                </ErrorMessage>

                <FormControl fullWidth>
                  <TextField
                    type="number"
                    variant="outlined"
                    style={inputPxA}
                    label={`CUIL`}
                    placeholder="CUIL"
                    id="cuit"
                    name="cuit"
                    {...formikProps.getFieldProps("cuit")}
                  ></TextField>
                </FormControl>
                <ErrorMessage name="cuit">
                  {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                </ErrorMessage>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    style={inputPxA}
                    variant="outlined"
                    label={`Apellido y Nombre`}
                    InputProps={{ readOnly: true }}
                    disabled={true}
                    value={
                      cuitValid ? `${persona.apellidos} ${persona.nombres}` : ""
                    }
                    id="personaInfo"
                    name="personaInfo"
                  ></TextField>
                </FormControl>
                <ErrorMessage name="personaInfo">
                  {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                </ErrorMessage>

                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ mt: 1.5 }}
                    multiple
                    id="tags-outlined"
                    options={llegoA2()}                // datosSubareas
                    getOptionLabel={(option) => option.descripcion}
                    value={areasInvolucradas.idSubAreas}
                    isOptionEqualToValue={(option, value) =>
                      option.idSubAreas === value.idSubAreas
                    }
                    filterSelectedOptions
                    onChange={(event, value) => handleChangeInputs(value, "idSubAreas")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Seleccione Áreas (2 máximo)"
                        placeholder="Áreas"
                      />
                    )}
                  />
                </FormControl>
                <ErrorMessage name="idSubAreas">
                  {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
                </ErrorMessage>

                <Button
                  onClick={() => formikProps.submitForm()}
                  style={{ margin: "20px 0px", float: "right", backgroundColor: "rgb(25,118,210)", color: "white" }}
                >
                  Consultar
                </Button>

              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
}

