import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import Loader from 'components/Loader/Loader';
import VariablesTable from '../VariablesTable/VariablesTable';
import VariablesNuevo from '../VariablesNueva/VariablesNueva';
import {
  getVariables,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
  selectVariables
} from 'store/variables/variables.slice';

export const myPageVariables = React.createContext(1);

export default function VariablesPage() {      // Presentamos las áreas, para elegir la subárea.
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  let [page, setPage] = React.useState(1);

  const varSelector = useSelector(selectVariables); 

  useEffect(() => {
    dispatch(getVariables(page ? page : 1));
  }, [page, dispatch]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <myPageVariables.Provider value={page}>
      <React.Fragment>
        {showLoader && <Loader />}
        <h1 style={{ marginBottom: '15px' }}> Variables </h1>
        <Toolbar disableGutters>
          <VariablesNuevo />
        </Toolbar>
        <VariablesTable variables={varSelector} />
        <Stack spacing={2} style={{ margin: '20px 0px 30px 0px', }}>
          <Pagination count={varSelector?.pages} page={page} onChange={handleChange} color="primary" />
        </Stack>
      </React.Fragment>
    </myPageVariables.Provider>
  );
}
