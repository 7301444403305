import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MateriaEditar from "../MateriasEditar/MateriasEditar";
import MateriaEliminar from "../MateriasEliminar/MateriasEliminar";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const MateriasTable = ({ materias }) => {
  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materias?.length > 0 ? (
            materias?.map((materia) => (
              <TableRow key={materia?.idMateria}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {materia?.idMateria}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {materia?.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  <MateriaEditar materia={materia} />
                  <MateriaEliminar materia={materia} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default MateriasTable;
