import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import VariablesParamxPerPage from "../../VariablesParamxPer/VariablesParamxPerPage/VariablesParamxPerPage";
import {
  tableBodyCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const VariablesParamTable = ({ varParam }) => {
  //console.log('varParam en Tabla Variables', varParam)

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Desc. Parám 1</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Desc. Parám 2</TableCell>
            {/* <TableCell style={{textAlign: "center"}}>Por Cargo?</TableCell>           // Va ???
            <TableCell style={{textAlign: "center"}}>Escalafón</TableCell> */}
            <TableCell sx={{ ...tableHeadCell }}>desc. Valor</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Períodos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {varParam?.data?.records?.length > 0 ? (
            varParam?.data?.records?.map((variableParam) => (
              <TableRow key={variableParam.idVariableParam}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {variableParam.idVariableParam}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {variableParam.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {variableParam.descParam1}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {variableParam.descParam2 || "NULL"}
                </TableCell>
                {/* <TableCell style={{textAlign: "center"}}>{variableParam.esPorCargo ?? "NULL"}</TableCell>
              <TableCell style={{textAlign: "center"}}>{variableParam.escalafon}</TableCell> */}
                <TableCell sx={{ ...tableBodyCell }}>
                  {variableParam.descValor}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  <VariablesParamxPerPage variableParam={variableParam} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default VariablesParamTable;
