import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVariables,
  fetchPeriodosxVariable,
  createVariable,
  createVariablexPer,
} from "./variables.api";

const initialState = {
  entities: [],
  entitiesxVariable: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getVariables = createAsyncThunk(
  "variables/getVariables",
  async (page) => {
    const r = await fetchVariables(page);
    //console.log('r en GET getVariables', r)
    return r;
  }
);

export const getPeriodosxVariable = createAsyncThunk("variables/getPeriodosxVariable",
  async (idVariable) => {
    const r = await fetchPeriodosxVariable(idVariable);
    //console.log('r en GET getPeriodosxVariable', r)
    return r;
  });

export const newVariable = createAsyncThunk("variables/newVariable",
  async (data) => {
    const r = await createVariable(data);
    return r;
  }
);

export const newVariablexPer = createAsyncThunk("variables/newVariablexPer",
  async (dato) => {
    const r = await createVariablexPer(dato);
    return r;
  }
);


export const VariablesSlice = createSlice({
  name: 'variables',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GET
      .addCase(getVariables.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVariables.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getVariables.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GET
      .addCase(getPeriodosxVariable.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPeriodosxVariable.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxVariable = action.payload;
      })
      .addCase(getPeriodosxVariable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // POST
      .addCase(newVariable.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newVariable.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newVariable.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      // POST
      .addCase(newVariablexPer.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newVariablexPer.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newVariablexPer.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })
  },
});

export const selectVariables = (state) => state.variables.entities;
export const selectVariablesxPeriodo = (state) => state.variables.entitiesxVariable;
export const selectResponseNewVariables = (state) => state.variables.response;
export const selectIsLoading = (state) => state.variables.loading;
export const selectIsCreating = (state) => state.variables.creating;
export const selectIsEditing = (state) => state.variables.editing;
export const selectIsDeleting = (state) => state.variables.deleting;
export const selectError = (state) => state.variables.error;

export default VariablesSlice.reducer;
