import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import GenericSearch from "concursos/utils/search/genericSearch";
import { Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Loader from "components/Loader/Loader";
import {
  filtrarInscripcionGeneral,
  selectInscripcionGeneral,
  selectIsLoadingInscGeneral,
  selectResponseInscGeneral,
} from "store/inscripcionGeneral/inscripcionGeneral.slice";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import InscGeneralTable from "../Table/InscripcionGeneralTable";
import NuevaInscGeneral from "../Modal/Nuevo/InscripcionGeneralNuevo";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";

export const InscGeneralContext = React.createContext(1);

export default function InscripcionGeneralPage() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [currentSearch, setcurrentSearch] = React.useState("");
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoadingInscGeneral);
  const listaInscGeneral = useSelector(selectInscripcionGeneral);
  const response = useSelector(selectResponseInscGeneral);

  let [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(filtrarInscripcionGeneral({ page, data: currentSearch })); // Tabla y búsqueda.
  }, [page, currentSearch, dispatch]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    setPage(1);
    setcurrentSearch(e.target.value);
  };

  return (
    <InscGeneralContext.Provider value={{ page, data: currentSearch }}>
      <React.Fragment>
        {isLoading && <Loader />}
        <div style={{ display: "flex", alignItems: "left" }}>
          <h1 style={{ margin: "10px 0" }}>Inscripción General</h1>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Toolbar disableGutters>
              <NuevaInscGeneral />
            </Toolbar>
          </Grid>
          <Grid item xs={8}>
            <GenericSearch handleSearch={handleSearch} />
          </Grid>
        </Grid>

        <InscGeneralTable listaInscGeneral={listaInscGeneral?.records} />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={listaInscGeneral?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </InscGeneralContext.Provider>
  );
}
