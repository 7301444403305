import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, DialogContent, DialogActions, TextField, Box, Grid, FormControl, DialogTitle } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { getNivelesEnsenanza, selectNivelesEnsenanza } from 'store/nivelesEnsenanza/nivelesEnsenanza.slice';
import { createPuntuacionItem } from 'store/PuntuacionPersona/PuntuacionPersona.slice';
import { ListarTipoItemDePuntuacionApi } from 'store/PuntuacionPersona/PuntuacionPersona.api';

const NuevoItems = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [titulo, setTitulo] = useState({});
  const [nivelesSeleccionados, setNivelesSeleccionados] = useState(null);
  const [unidadMedida, setUnidadMedida] = useState('');
  const [idTipoItemsDePuntuacion, setIdTipoItemsDePuntuacion] = useState(null);
  const [tiposItemsDePuntuacion, setTiposItemsDePuntuacion] = useState([]);

  const seletEnsenanza = useSelector(selectNivelesEnsenanza);
  const nivelesEnsenanza = seletEnsenanza.records;

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
    ListarTipoItemDePuntuacionApi().then((response) => {
      setTiposItemsDePuntuacion(response);
    });
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
    setTitulo({});
    setUnidadMedida('');
    setNivelesSeleccionados(null);
    setIdTipoItemsDePuntuacion(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const idNivelEnsenanza = nivelesSeleccionados ? nivelesSeleccionados.idNivelEnsenanza : 0;

    const nuevoItem = {
      idTipoItemsDePuntuacion: Number(idTipoItemsDePuntuacion.idTipoItemsDePuntuacion),
      codigo: titulo.codigo,
      descripcion: titulo.descripcion,
      unidadDeMedida: unidadMedida,
      idNivelEnsenanza: idNivelEnsenanza,
    };

    dispatch(createPuntuacionItem(nuevoItem)).then(() => {
      handleClose();
    });
  };

  const handleChange = (e) => {
    setTitulo({ ...titulo, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Nuevo
      </Button>
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            boxShadow: 8,
            borderRadius: 2,
            p: 4,
            maxWidth: 400,
            width: '100%',
          }}
        >
          <DialogTitle>Nuevo</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="codigo"
                    label="Código"
                    name="codigo"
                    autoFocus
                    inputProps={{ maxLength: 10 }}
                    value={titulo.codigo || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="descripcion"
                    label="Descripción"
                    name="descripcion"
                    inputProps={{ maxLength: 250 }}
                    value={titulo.descripcion || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="unidadMedida"
                    label="Unidad de Medida"
                    name="unidadMedida"
                    inputProps={{ maxLength: 250 }}
                    value={unidadMedida}
                    onChange={(e) => setUnidadMedida(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={tiposItemsDePuntuacion}
                      value={idTipoItemsDePuntuacion}
                      onChange={(e, value) => setIdTipoItemsDePuntuacion(value)}
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo de Ítems de Puntuación"
                          placeholder="Selecciona un tipo"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={nivelesEnsenanza}
                      value={nivelesSeleccionados}
                      onChange={(e, value) => setNivelesSeleccionados(value)}
                      getOptionLabel={(option) => option.nivelEnsenanza}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nivel de Enseñanza"
                          placeholder="Selecciona un nivel"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default NuevoItems;
