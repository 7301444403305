import { request } from 'utils/helpers/request.helpers';

const puntajeListUrl = `${process.env.REACT_APP_API_URL}/api/Puntaje`;      // Prueba con Áreas. 


//------------- Puntaje por Agente.
export function fetchPxAgenteA1List({idPersona, idPeriodo, idSubArea1}) {                              
  //const user = localStorage.getItem("token");
  const url = `${puntajeListUrl}/ListarPuntajePorPersona?pIdPersona=${idPersona}&pIdPeriodo=${idPeriodo}&pIdSubArea=${idSubArea1}`;
  return request(url, { method: "GET" });
}

export function fetchPxAgenteA2List({idPersona, idPeriodo, idSubArea2}) {                              
  //const user = localStorage.getItem("token");
  const url = `${puntajeListUrl}/ListarPuntajePorPersona?pIdPersona=${idPersona}&pIdPeriodo=${idPeriodo}&pIdSubArea=${idSubArea2}`;
  return request(url, { method: "GET" });
}