import * as Yup from "yup";

export const vsTitulo = Yup.object({
  idCategoria: Yup.string().required("Este campo es obligatorio").nullable(),
  descripcion: Yup.string().required("Este campo es obligatorio").nullable(),
  codigo: Yup.string().required("Este campo es obligatorio").nullable(),
  descAbrev: Yup.string().required("Este campo es obligatorio").nullable(),
  idNivelEnsenanza: Yup.array()
    .min(1, "Debe seleccionar al menos una opción")
    .required("Este campo es obligatorio"),
});
