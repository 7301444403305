import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Stack,
} from "@mui/material";
import {
  selectMateriasEdit,
  selectIsLoadingEditt,
} from "store/materias/materias.slice";
import {
  selectNivelesEnsenanza,
  selectIsLoading,
  selectError,
} from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import {
  selectSubareas,
  selectIsLoading as selectIsLoadingSubareas,
  selectError as selectErrorSubareas,
} from "store/subareas/subareas.slice";
import { Formik } from "formik";
import { vsMateria } from "./YupMateria";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MateriaForm({ data, onSubmit, onClose }) {
  const [isEdit, setIsEdit] = useState(true);
  const getNiveles = useSelector(selectNivelesEnsenanza);
  const getAreas = useSelector(selectSubareas);
  const isLoadingAreas = useSelector(selectIsLoadingSubareas);
  const isLoadingNiveles = useSelector(selectIsLoading);
  const errorNiveles = useSelector(selectError);
  const errorAreas = useSelector(selectErrorSubareas);

  useEffect(() => {
    setIsEdit(false);
    if (data?.idMateria) {
      setIsEdit(true);
    }
  }, [data]);

  const nivelEnseñanza =
    getNiveles?.records?.map((option) => ({
      id: option.idNivelEnsenanza,
      label: option.nivelEnsenanza,
    })) || [];

  const areasSubAreas =
    getAreas.map((option) => ({
      id: option.subarea.idSubAreas,
      label: `${option.subarea.idSubAreas} - ${option.subarea.descripcion}`,
    })) || [];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        materiaId: data?.idMateria || null,
        descripcion: data?.descripcion || "",
        idsRelSubAreas: isEdit ? data?.idsRelSubAreas || [] : [],
        idsRelNivel: isEdit ? data?.idsRelNivel || [] : [],
      }}
      validationSchema={vsMateria}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        touched,
        setFieldValue,
        submitForm,
        dirty,
      }) => (
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  variant="outlined"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.descripcion}
                  error={Boolean(errors.descripcion && touched.descripcion)}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                onChange={(e, value) => {
                  setFieldValue("idsRelNivel", value);
                }}
                style={{ marginTop: "-7px" }}
                disableCloseOnSelect
                options={nivelEnseñanza}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  );
                }}
                value={
                  nivelEnseñanza.filter((item) =>
                    values?.idsRelNivel?.some((itemB) => itemB.id === item.id)
                  ) || []
                }
                name="idsRelNivel"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Nivel de enseñanza"
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    style={{ marginTop: "0.5rem" }}
                    error={errors.idsRelNivel && touched.idsRelNivel}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingNiveles ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    helperText={
                      Boolean(errors.idsRelNivel) &&
                      touched.idsRelNivel &&
                      errors.idsRelNivel
                    }
                  />
                )}
              />
              {errorNiveles && (
                <FormHelperText
                  style={{
                    color: "#d32f2f",
                  }}
                >
                  Ocurrió un problema al recuperar este dato
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                onChange={(e, value) => {
                  setFieldValue("idsRelSubAreas", value);
                }}
                style={{ marginTop: "-7px" }}
                disableCloseOnSelect
                options={areasSubAreas}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  );
                }}
                value={
                  areasSubAreas.filter((item) =>
                    values?.idsRelSubAreas?.some(
                      (itemB) => itemB.id === item.id
                    )
                  ) || []
                }
                name="idsRelSubAreas"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Area"
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    style={{ marginTop: "0.5rem" }}
                    error={errors.idsRelSubAreas && touched.idsRelSubAreas}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAreas ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    helperText={
                      Boolean(errors.idsRelSubAreas) &&
                      touched.idsRelSubAreas &&
                      errors.idsRelSubAreas
                    }
                  />
                )}
              />
              {errorAreas && (
                <FormHelperText
                  style={{
                    color: "#d32f2f",
                  }}
                >
                  Ocurrió un problema al recuperar este dato
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={onClose}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
