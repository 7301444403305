import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import "dayjs/locale/es";
import Box from "@mui/material/Box";
import { FormControl, Stack } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getTiposPerxNivel,
  stpError,
  stpTiposPerxNivel,
} from "store/tiposDePeriodo/tiposDePeriodo.slice";

import { vsPeriodo } from "./YupPeriodo";

export default function PeriodoFrom({ data, onSubmit, onClose }) {
  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const tiposDePeriodo = useSelector(stpTiposPerxNivel);
  const tpError = useSelector(stpError);

  useEffect(() => {
    dispatch(getTiposPerxNivel());
  }, [dispatch]);

  const handleSubmit = (e) => {
    const datoAEnviar = {
      ...e,
      descripcion: (e.labelTipoPeriodo + "- " + e.anioInicio).toUpperCase(),
    };
    onSubmit(datoAEnviar);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          id: data?.idPeriodo || null,
          labelTipoPeriodo: data?.descripcion.slice(0, -6) || "", // Se le saca el año.
          idTipoPeriodo: data?.idTipoPeriodo || "",
          fechaInicio: data?.fechaInicio
            ? dayjs(data?.fechaInicio, "DD/MM/YYYY")
            : null,
          anioInicio: data?.fechaInicio
            ? new Date(dayjs(data.fechaInicio, "DD/MM/YYYY")).getFullYear()
            : "",
          fechaFin: data?.fechaFin ? dayjs(data?.fechaFin, "DD/MM/YYYY") : null,
        }}
        validationSchema={vsPeriodo}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo}
                >
                  <InputLabel id="periodo">Tipo de período</InputLabel>
                  <Select
                    labelId="periodo"
                    id="idTipoPeriodo"
                    label="Tipo de período"
                    name="idTipoPeriodo"
                    value={values?.idTipoPeriodo}
                    onChange={(e, value) => {
                      setFieldValue(
                        "idTipoPeriodo",
                        e?.target?.value ? e.target.value : null
                      );
                      setFieldValue(
                        "labelTipoPeriodo",
                        value?.props?.children ? value.props.children : null
                      );
                    }}
                    onSelect={handleChange}
                    error={
                      Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo
                    }
                  >
                    {tiposDePeriodo.map((option) => (
                      <MenuItem
                        key={option.idTipoPeriodo}
                        value={option.idTipoPeriodo}
                      >
                        {option.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.idTipoPeriodo) && touched.idTipoPeriodo && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {tpError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicio"
                    value={values.fechaInicio}
                    onChange={(e, value) => {
                      setFieldValue("fechaInicio", e);
                      setFieldValue(
                        "anioInicio",
                        e ? new Date(e).getFullYear() : null
                      );
                    }}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicio) &&
                          touched.fechaInicio &&
                          errors.fechaInicio,
                        error:
                          Boolean(errors.fechaInicio) &&
                          Boolean(touched.fechaInicio),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFin"
                    value={values.fechaFin}
                    onChange={(value) => setFieldValue("fechaFin", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFin) &&
                          touched.fechaFin &&
                          errors.fechaFin,
                        error:
                          Boolean(errors.fechaFin) && Boolean(touched.fechaFin),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="text"
                    id="descripcion"
                    label="Descripción"
                    name="descripcion"
                    autoFocus
                    value={
                      values.labelTipoPeriodo
                        ? (
                            values.labelTipoPeriodo +
                            "- " +
                            values?.anioInicio
                          ).toUpperCase()
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              {/* {console.log('values en PeridoForm', values)} */}
            </Grid>

            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
