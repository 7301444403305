import { useQuery } from "react-query";
import { fetchTipoTitulo } from "store/titulos/titulos.api";

export const useTipoTitulosQuery = () => {
  const tipoTituloQuery = useQuery(
    {
      queryKey: ["tipoTituloQuery"],
      queryFn: async () => {
        const { data } = await fetchTipoTitulo();
        return data;
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return tipoTituloQuery;
};
