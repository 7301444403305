import { request } from "utils/helpers/request.helpers";

const proyectosUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/Proyecto`;

export function GetLocalidades() {
  const url = `${proyectosUrl}/GetLocalidades`;
  return request(url, { method: "GET" });
}

export function fetchDeptosxTipoPeriodo(pIdTipoPeriodo) {
  const url = `${proyectosUrl}/GetDepartamento_ByTipoPeriodo?pIdProvincia=${2}&pIdTipoPeriodo=${pIdTipoPeriodo}`;
  return request(url, { method: "GET" });         // Solo Departamentos de Catamarca.
}

export function GetDepartamento(idProvincia, departamento) {
  const url = `${proyectosUrl}/GetDepartamento`;
  const body = JSON.stringify({
    idProvincia: idProvincia,
    departamento: departamento,
  });

  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}

export function GetLocalidad(idDepartamento, localidad) {
  const url = `${proyectosUrl}/GetLocalidadesxDpto`;
  const body = JSON.stringify({
    idDepartamento: idDepartamento,
    localidad: localidad,
  });

  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });
}
