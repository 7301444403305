import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchActividades,
  createActividad,
  updateActividad,
  deleteActividad,
} from './actividades.api';

const initialState = {
  entities: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getActividades = createAsyncThunk(
  'actividades/getActividades',
  async (id) => {
    const r = await fetchActividades(id);
    return r;
  }
);

export const newActividad = createAsyncThunk(
  'actividades/newActividad',
  async (data) => {
    const r = await createActividad(data);
    return r;
  }
);

export const editActividad = createAsyncThunk(
  'actividades/editActividad',
  async ({ id, data }) => {
    const r = await updateActividad({ id, data });
    return r;
  }
);

export const removeActividad = createAsyncThunk(
  'actividades/removeActividad',
  async (id) => {
    const r = await deleteActividad(id);
    return r;
  }
);

export const ActividadesSlice = createSlice({
  name: 'actividades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActividades.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActividades.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getActividades.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(newActividad.pending, (state) => {
        state.creating = true;
      })
      .addCase(newActividad.fulfilled, (state, action) => {
        state.creating = false;
        state.entities.push(action.payload);
      })
      .addCase(newActividad.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })
      .addCase(editActividad.pending, (state) => {
        state.editing = true;
      })
      .addCase(editActividad.fulfilled, (state, action) => {
        state.editing = false;
        state.entities = state.entities.map((actividad) => {
          if (actividad.id !== action.payload.id) {
            return actividad;
          }
          return {
            ...actividad,
            ...action.payload,
          };
        });
      })
      .addCase(editActividad.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })
      .addCase(removeActividad.pending, (state) => {
        state.deleting = true;
      })
      .addCase(removeActividad.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state.entities.filter(
          (actividad) => actividad.id !== action.payload.id
        );
      })
      .addCase(removeActividad.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      });
  },
});

export const selectActividades = (state) => state.actividades.entities;

export const selectIsLoading = (state) => state.actividades.loading;

export const selectIsCreating = (state) => state.actividades.creating;

export const selectIsEditing = (state) => state.actividades.editing;

export const selectIsDeleting = (state) => state.actividades.deleting;

export default ActividadesSlice.reducer;
