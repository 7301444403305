import React, { useContext } from "react";
import { Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery, useTheme } from "@mui/material";
import CompetenciaForm from "../../Modal/CompetenciaForm";
import { usePostCompetenciaQuery } from "Hooks/Competencia/UsePostCompetenciaQuery";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import Loader from "components/Loader/Loader";
import { CompetenciaContext } from "../CompetenciaProvider";
export default function NuevaCompetencia({ data, disabled }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const postCompetencia = usePostCompetenciaQuery();
  const { setMessageAlert, setOpenSnackbar } = useContext(CompetenciaContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };
  const handleSubmit = (competencia) => {
    postCompetencia.mutate(competencia, {
      onSuccess: () => {
        handleClose();
        setMessage({
          message: [
            {
              status: 200,
              description: "Competencia/s creada/s correctamente",
            },
          ],
        });
      },
      onError: (error) => {
        setMessage([
          {
            status: error.response.status,
            description: "Se ha producido un error al crear la/s competencia/s",
          },
        ]);
      },
    });
  };

  const setMessage = (message) => {
    setOpenSnackbar(true);
    setMessageAlert(SnackBarMessageResponse(message));
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="success"
        disabled={disabled}
        onClick={handleClickOpen}
      >
        Nueva
      </Button>
      {postCompetencia.isLoading && <Loader />}
      <Dialog
        fullWidth={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: fullScreen ? "100%" : "50%", // Cambia el ancho dependiendo de la resolución
            maxWidth: fullScreen ? "100%" : "none", // Permite que el modal se extienda más allá de los valores predeterminados
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Agregar Competencia</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CompetenciaForm
            data={data}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
