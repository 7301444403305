import { request } from 'utils/helpers/request.helpers';

const materiasUrl = `${process.env.REACT_APP_API_URL}/api/Materias`;

// ABM Materias x pagina.
export function fetchMaterias(page) {
  const userGet = localStorage.getItem("token");
  const url = `${materiasUrl}/Listar?page=${page}&usuario=${userGet}`;  
  return request(url, { method: 'GET' });
}

export function fetchMateriasEdit(idMateria) {
  const url = `${materiasUrl}/GetAreaNivel?idMateria=${idMateria}`;     
  return request(url, { method: 'GET' });
}

export function filterMateria(datafilter, token) {
  return request(`${materiasUrl}/FiltrarMaterias?pBusqueda=${datafilter}$usuario=${token}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function createMateria(data) {
  return await request(`${materiasUrl}`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function updateMateria( data ) {
  return request(`${materiasUrl}/editarMateria`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export function deleteMateria(id) {
  return request(`${materiasUrl}/${id}`, {
    method: 'DELETE',
  });
}

