import { useMutation, useQueryClient } from "react-query";
import { deleteCompetencia } from "store/competencias/competencia.api";

export const useDeleteCompetenciaQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (idCompetencia) => {
      const response = await deleteCompetencia(idCompetencia);
      return response;
    },
    {
      onSuccess: () => {
        // Invalida y refetch las queries que dependen de esta data
        queryClient.invalidateQueries(["CompetenciasQuery"]);
      },
    }
  );
};
