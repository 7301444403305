import React from "react";
import { useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";

import SubareaForm from "../SubareasForm/SubareasForm";
import { editSubarea } from "store/subareas/subareas.slice";
import CustomModal from "components/customModal/CustomModal";

export default function SubareaEditar({ area, subarea }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(editSubarea({ id: subarea.idSubAreas, data }));
  };

  return (
    <React.Fragment>
      <IconButton
        variant="outlined"
        onClick={handleClickOpen}
        style={{ marginLeft: "10px" }}
      >
        <EditIcon />
      </IconButton>
      <CustomModal
        title={"Editar Subárea"}
        open={open}
        setOpen={handleClose}
        maxWidth="md"
      >
        <DialogContent>
          <SubareaForm
            area={area}
            data={subarea}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
