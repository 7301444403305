import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProyectoEditar from '../ProyectoEditar/ProyectoEditar';
import ProyectoEliminar from '../ProyectoEliminar/ProyectoEliminar';
import { useSelector } from 'react-redux';
import { selectProyectos } from 'store/proyectos/proyectos.slice';

export default function ProyectosTable() {
  const proyectos = useSelector(selectProyectos);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Denominación</TableCell>
            <TableCell>Institución</TableCell>
            <TableCell>Modalidad</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {proyectos.map((proyecto) => (
            <TableRow
              key={proyecto.idProyecto}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {proyecto.idProyecto}
              </TableCell>
              <TableCell>{proyecto.denominacion}</TableCell>
              <TableCell>{proyecto.nombreInstitucion}</TableCell>
              <TableCell>{proyecto.nombreModalidad}</TableCell>
              <TableCell>{proyecto.tipoProyecto}</TableCell>
              <TableCell>{proyecto.estadoProyecto}</TableCell>
              <TableCell>
                <ProyectoEditar proyecto={proyecto} />
                <ProyectoEliminar proyecto={proyecto} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
