import { request } from "utils/helpers/request.helpers";

const inscripcionesxPersonaUrl = `${process.env.REACT_APP_API_URL}/api/Inscripcion_Persona`;

const user = localStorage.getItem("token");

export function fetchInscxPersona(pBusqueda, page, pId_Persona) {
  if (pBusqueda === undefined) {
    const url = `${inscripcionesxPersonaUrl}/FiltrarInsripciones_Persona?page=${page}&pId_Persona=${pId_Persona}&usuario=${user}`; 
    return request(url, { method: "GET" });
  } else {
    const url = `${inscripcionesxPersonaUrl}/FiltrarInsripciones_Persona?pBusqueda=${pBusqueda}&page=${page}&pId_Persona=${pId_Persona}&usuario=${user}`;
    return request(url, { method: "GET" });
  }
}

export function createInscripcionxPersona(data) {
  return request(`${inscripcionesxPersonaUrl}/InsertarInscripcion_Persona`, { 
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateInscripcionxPersona({ data }) {
  return request(`${inscripcionesxPersonaUrl}/ModificarInscripcion_Persona?pIdInscripcionPersona=${data.idInscripcionPersona}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function deshabilitarInscxPersona(pIdInscGeneral) {
  return await request(`${inscripcionesxPersonaUrl}/Deshabilitar?pIdInscripcionPersona=${pIdInscGeneral}`, {
    method: "PUT",        
  });
}
