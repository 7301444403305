import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DialogContent from "@mui/material/DialogContent";
import TituloForm from "../TituloForm/TituloForm";
import { editTitulo, getTitulos } from "store/titulos/titulos.slice";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import Loader from "components/Loader/Loader";
import { fetchTitulo_Nivel } from "store/nivelesEnsenanza/nivelesEnsenanza.api";
import ConfirmDialog from "concursos/views/Persona/Modal/Common/ConfirmDialog";
import CustomModal from "components/customModal/CustomModal";
import { myPageTitulos } from "../TitulosPage/TituloPage";

export default function TituloEditar({ titulo }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoadding, setIsLoadding] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();
  const page = useContext(myPageTitulos);

  const fetchTituloByNivel = async () => {
    try {
      setIsLoadding(true);
      const data = await await fetchTitulo_Nivel(titulo.idTitulo);

      const niveles =
        data.map((option) => ({
          id: option.idNivelEnsenanza,
          label: option.nivelEnsenanza,
        })) || [];

      setData({
        ...titulo,
        idNivelEnsenanza: niveles,
      });
      setOpen(true);
    } catch (error) {
      setOpenDialog(true);
    } finally {
      setIsLoadding(false);
    }
  };

  const handleClickOpen = async () => {
    await fetchTituloByNivel();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    handleClose();
    dispatch(editTitulo({ id: titulo.idTitulo, data }));
    setTimeout(() => {
      dispatch(getTitulos(page ? page : 1));
      dispatch(getNivelesEnsenanza(1));
    }, 400);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ConfirmDialog
        mensaje={
          "Se ha producido un error al recuperar informacion del titulo."
        }
        onClose={onCloseDialog}
        open={openDialog}
        title={"Atención"}
      />
      {isLoadding ? (
        <Loader />
      ) : (
        <>
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
          <CustomModal
            title={"Editar Título / Certificado"}
            open={open}
            setOpen={handleClose}
            maxWidth="sm"
          >
            <DialogContent>
              <TituloForm
                data={data}
                onSubmit={handleSubmit}
                onClose={handleClose}
              />
            </DialogContent>
          </CustomModal>
        </>
      )}
    </>
  );
}
