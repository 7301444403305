import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { InscGeneralContext } from "../../Page/InscripcionGeneralPage";
import { filtrarInscripcionGeneral, removeInscripcionGeneral } from 'store/inscripcionGeneral/inscripcionGeneral.slice';

export default function InscripcionGeneralEliminar({ inscGeneral }) {

  //console.log('inscGeneral en inscGeneralDelete', inscGeneral)  

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const pageAndSearch = React.useContext(InscGeneralContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(removeInscripcionGeneral(inscGeneral.idInscGeneral));
    setTimeout(() => {
      dispatch(filtrarInscripcionGeneral(pageAndSearch? pageAndSearch : { page: 1, data: null }));
    }, 300);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar Inscripción General </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar la inscripción{" "} <b> "{inscGeneral.descripcion}" </b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
