import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InscGeneralForm from "../Common/InscripcionGeneralForm";
import { InscGeneralContext } from "../../Page/InscripcionGeneralPage";
import { useMediaQuery, useTheme } from "@mui/material";
import { filtrarInscripcionGeneral, newInscripcionGeneral } from "store/inscripcionGeneral/inscripcionGeneral.slice";

export default function InscripcionGeneralNuevo() {
  const [open, setOpen] = React.useState(false);
  const pageAndSearch = React.useContext(InscGeneralContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") 
        return;
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(newInscripcionGeneral(data));
    setOpen(false);
    setTimeout(() => {
      dispatch(filtrarInscripcionGeneral(pageAndSearch? pageAndSearch : { page: 1, data: null })); 
    }, 400);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo
      </Button>

      <Dialog
        fullWidth={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Agregar Inscripción</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InscGeneralForm
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
