import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import GenericSearch from "concursos/utils/search/genericSearch";
import { Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Loader from "components/Loader/Loader";
import dayjs from "dayjs";
import {
  GetSearchPeriodo,
  sPeriodos,
  sIsLoading,
  sResponse,
} from "store/periodos/periodos.slice";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import PeriodosTable from "../Table/PeriodosTable";
import NuevoPeriodo from "../Modal/Nuevo/NuevoPeriodo";

export const PeriodosContext = React.createContext(1);

export default function PeriodosPage() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [currentSearch, setcurrentSearch] = React.useState("");
  const dispatch = useDispatch();

  const isLoading = useSelector(sIsLoading);
  const periodos = useSelector(sPeriodos);
  const response = useSelector(sResponse);

  let [page, setPage] = React.useState(1);

  const checkDateFormat = (fechaString) => {
    // Expresión regular para el formato dd/mm/yyyy
    var formatoFecha = /^\d{2}\/\d{2}\/\d{4}$/;
    // Verificar si el string cumple con el formato
    return formatoFecha.test(fechaString);
  };

  const parseDateFormat = (date) => {
    if (date && checkDateFormat(date)) {
      return date;
    }
    const fecha = dayjs(date);
    return fecha.format("DD/MM/YYYY");
  };

  useEffect(() => {
    dispatch(GetSearchPeriodo({ page, data: currentSearch }));
  }, [page, currentSearch, dispatch]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    setPage(1);
    setcurrentSearch(e.target.value);
  };

  return (
    <PeriodosContext.Provider value={{ page, data: currentSearch }}>
      <React.Fragment>
        {isLoading && <Loader />}
        <div style={{ display: "flex", alignItems: "left" }}>
          <h1 style={{ margin: "10px 0" }}>Periodos</h1>
        </div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Toolbar disableGutters>
              <NuevoPeriodo />
            </Toolbar>
          </Grid>
          <Grid item xs={8}>
            <GenericSearch
              style={{ marginBottom: 0 }}
              handleSearch={handleSearch}
            />
          </Grid>
        </Grid>

        <PeriodosTable
          periodos={periodos?.records?.map((p) => ({
            ...p,
            fechaInicio: parseDateFormat(p.fechaInicio),
            fechaFin: parseDateFormat(p.fechaFin),
          }))}
        />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={periodos?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </PeriodosContext.Provider>
  );
}
