import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInstituciones } from './instituciones.api';

const initialState = {
  entities: [],
  loading: false,
  error: null,
};

export const getInstituciones = createAsyncThunk(
  'instituciones/getInstituciones',
  async () => {
    const data = await fetchInstituciones();
    return data;
  }
);

export const InstitucionesSlice = createSlice({
  name: 'instituciones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstituciones.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInstituciones.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getInstituciones.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectInstituciones = (state) => state.instituciones.entities;
export const selectInstitucionesIsLoading = (state) => state.instituciones.loading;
export const selectInstitucionesError = (state) => state.instituciones.error;

export default InstitucionesSlice.reducer;
