import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import Loader from 'components/Loader/Loader';
import VariablesParamTable from '../VariablesParamTable/VariablesParamTable';
import VariablesParamNuevo from '../VariablesParamNueva/VariablesParamNueva';
import {
  getVariablesParam,
  selectIsLoading,
  selectIsCreating,
  selectIsEditing,
  selectIsDeleting,
  selectVariablesParam
} from 'store/variablesParam/variablesParam.slice';

export const myPageVariablesParam = React.createContext(1);

export default function VariablesParamPage() {      // Presentamos las áreas, para elegir la subárea.
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const isCreating = useSelector(selectIsCreating);
  const isEditing = useSelector(selectIsEditing);
  const isDeleting = useSelector(selectIsDeleting);

  const showLoader = isLoading || isCreating || isEditing || isDeleting;

  let [page, setPage] = React.useState(1);

  const varParamSelector = useSelector(selectVariablesParam); 

  useEffect(() => {
    dispatch(getVariablesParam(page ? page : 1));
  }, [page, dispatch])

  const handleChange = (event, values) => {
    setPage(values);
  };

  return (
    <myPageVariablesParam.Provider value={page}>
      <React.Fragment>
        {showLoader && <Loader />}
        <h1 style={{ marginBottom: "15px" }}> Variables Parametrizadas </h1>
        <Toolbar disableGutters>
          <VariablesParamNuevo />
        </Toolbar>
        <VariablesParamTable varParam={varParamSelector} />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={varParamSelector?.data?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
    </myPageVariablesParam.Provider>
  );
}
