import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SubareaEditar from "../SubareasEditar/SubareasEditar";
import SubareaEliminar from "../SubareasEliminar/SubareasEliminar";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const SubareasTable = ({ area, subareas }) => {
  return (
    <CustomTableContainer sx={{ maxHeight: 350 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>Código</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subareas?.length > 0 ? (
            subareas?.map((subarea) => (
              <TableRow key={subarea.idSubAreas}>
                {/* <TableCell component="th" scope="row">
                {subarea.idSubAreas}              // Mostramos el ID???.
              </TableCell> */}
                <TableCell sx={{ ...tableBodyCell }}>
                  {subarea.codigoSubArea}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {subarea.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  <SubareaEditar area={area} subarea={subarea} />
                  <SubareaEliminar area={area} subarea={subarea} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default SubareasTable;
