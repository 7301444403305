import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";

import {
  selectPuntajexAgenteA1,
  selectPuntajexAgenteA2,
  selectPuntajexAgenteResponseA1,
} from "store/puntajexAgente/puntajexAgente.slice";
import PuntajexAgenteTable from "../PuntajexAgenteTable/PuntajexAgenteTable";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import PuntajexAgenteForm from "../PuntajexAgenteForm/PuntajexAgenteForm";
import { GetAllPersonaEndpoint } from "store/persona/persona.slice";
import { getSubareas } from "store/subareas/subareas.slice";


const PuntajexAgentePage = () => {

  const dispatch = useDispatch();
  const GetPuntajexAgenteA1 = useSelector(selectPuntajexAgenteA1);
  const GetPuntajexAgenteA2 = useSelector(selectPuntajexAgenteA2);

  /* const [message, setMessage] = useState("");
  const [view, setView] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const puntajePorAgente = useSelector(selectPuntajexAgenteResponseA1); */

  useEffect(() => {
    dispatch(getSubareas());
    dispatch(GetAllPersonaEndpoint());
  }, [dispatch]);

  /* useEffect(() => {
    if (Array.isArray(puntajePorAgente.message) && viewMessage) {
      setView(true);
      setMessage(SnackBarMessageResponse(puntajePorAgente));
    }
    setViewMessage(true);
  }, [puntajePorAgente]); */

  const muestroTabla1 = () => {
    if (GetPuntajexAgenteA1?.data?.length !== undefined) {
      if (GetPuntajexAgenteA1?.data?.length !== 0) {
        return <PuntajexAgenteTable datosAgente={GetPuntajexAgenteA1?.data} />
      } else {
        return <p style={{
          textAlign: 'center', marginBottom: '30px', color: "red", width: "67.5%"
        }}> Sin puntaje cargado/ generado en la primer área seleccionada. </p>
      }
    } /* else {
      return <p style={{
        textAlign: 'center', marginBottom: '30px', color: "red", width: "67.5%"
      }}> Sin puntaje cargado/ generado en la primer área seleccionada. </p>
    } */
  }

  const muestroTabla2 = () => {
    if (GetPuntajexAgenteA2?.data?.length !== undefined) {
      if (GetPuntajexAgenteA2?.data?.length !== 0) {
        return <PuntajexAgenteTable datosAgente={GetPuntajexAgenteA2?.data} />
      } else {
        return <p style={{
          textAlign: 'center', marginBottom: '30px', color: "red", width: "67.5%"
        }}> Sin puntaje cargado/ generado en la segunda área seleccionada. </p>
      }
    } /* else {
      return <p style={{
        textAlign: 'center', marginBottom: '30px', color: "red", width: "67.5%"
      }}> Sin puntaje cargado/ generado en la segunda área seleccionada. </p>
    } */
  }

  return (
    <React.Fragment>
      <h1 style={{ marginLeft: "12px" }}> Puntaje por Agente </h1>
      <PuntajexAgenteForm />
      <div style={{ marginBottom: "30px" }} />
      {muestroTabla1()}
      {muestroTabla2()}
      {/* <SnackBarUtils
        closed={(value) => setView(value)}
        message={message}
        view={view}
      /> */}
    </React.Fragment>
  );
};

export default PuntajexAgentePage;
