import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import {
  Box,
  Grid,
  FormHelperText,
  Stack,
  Hidden,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  filterPersonas,
  selectPersonasFilter,
  clearPersonaSearchData,
  selectErrorFilter,
} from "store/persona/persona.slice";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { vsPersona } from "./YupPersona";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SearchOutlined } from "@mui/icons-material";
import ConfirmDialog from "./ConfirmDialog";
import { mensaje, source_sexos, source_tiposDeDocumentos } from "./config";
import { GetAntecedentes, GetEstadoCivil } from "store/persona/persona.api";
import { useTheme } from "@mui/material/styles";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import {
  GetDepartamentos,
  GetLocalidadesByDepartamento,
  selectDepartamentos,
  selectErrorDepartamentos,
  selectErrorLocalidades,
  selectLocalidad,
} from "store/Localidad/Localidad.slice";

export default function PersonaFrom({ data, onSubmit, onClose }) {
  const dispatch = useDispatch();
  const personaSearch = useSelector(selectPersonasFilter);
  const error = useSelector(selectErrorFilter);
  const formRef = useRef(null); // Referencia al formulario
  const [, setCuilExistente] = React.useState(false);
  const [edit] = React.useState(data ?? false);
  const [nuevo, setNuevo] = React.useState(!data && true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const sDepartamentos = useSelector(selectDepartamentos);
  const sLocalidades = useSelector(selectLocalidad);
  const sErrorDepartamentos = useSelector(selectErrorDepartamentos);
  const sErrorLocalidades = useSelector(selectErrorLocalidades);
  const [antecedentes, setAntecedentes] = React.useState();
  const [errorAntecedentes, setErrorAntecedentes] = React.useState(false);
  const [estadosCiviles, setEstadosCiviles] = React.useState();
  const [errorEstadosCiviles, setErrorEstadosCiviles] = React.useState(false);
  const theme = useTheme();

  const handleClose = (event, reason) => {
    dispatch(clearPersonaSearchData());
    onClose();
  };

  useEffect(() => {
    dispatch(clearPersonaSearchData());
    !sDepartamentos && dispatch(GetDepartamentos({ idProvincia: 2 }));
    data?.idDepartamento_Residencia &&
      dispatch(
        GetLocalidadesByDepartamento({
          idDepartamento: data.idDepartamento_Residencia,
        })
      );
    setCuilExistente(false);
  }, [dispatch, data, sDepartamentos]);

  useEffect(() => {
    !antecedentes && fetchAntecedentes();
    !estadosCiviles && fetchEstadosCiviles();
  }, [dispatch, data, antecedentes, estadosCiviles]);

  const findLocalidades = (idDepartamento) => {
    dispatch(GetLocalidadesByDepartamento({ idDepartamento }));
  };

  useEffect(() => {
    error && setNuevo(false);
  }, [error]);

  useEffect(() => {
    const result = personaSearch?.data && personaSearch.data.length !== 0;
    if (result) {
      setOpenDialog(true);
      setNuevo(true);
      setCuilExistente(result);
      formRef.current.resetForm();
      formRef.current.setFieldValue("cuit", personaSearch?.data[0].cuit);
    } else if (result !== undefined && result !== null) {
      setNuevo(false);
    }
  }, [personaSearch]);

  const findPersona = (value) => {
    dispatch(filterPersonas(value));
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchAntecedentes = async () => {
    try {
      const antecedentesData = await GetAntecedentes();
      setAntecedentes(antecedentesData);
    } catch (error) {
      setErrorAntecedentes(true);
    }
  };

  const fetchEstadosCiviles = async () => {
    try {
      const estadosCivilesData = await GetEstadoCivil();
      setEstadosCiviles(estadosCivilesData);
    } catch (error) {
      setErrorEstadosCiviles(true);
    }
  };

  const isButtonSenddisabled = () => {
    return (
      nuevo ||
      errorEstadosCiviles ||
      errorAntecedentes ||
      sErrorDepartamentos ||
      sErrorLocalidades
    );
  };

  const reloadDepartamentos = () => {
    !sDepartamentos && dispatch(GetDepartamentos({ idProvincia: 2 }));
  };

  const reloadAntecedentes = () => {
    !antecedentes && fetchAntecedentes();
  };

  const reloadEstadoCivil = () => {
    !estadosCiviles && fetchEstadosCiviles();
  };

  const reloadLocalidades = (idDepartamento) => {
    idDepartamento &&
      dispatch(
        GetLocalidadesByDepartamento({
          idDepartamento: idDepartamento,
        })
      );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <ConfirmDialog
        mensaje={mensaje}
        onClose={onCloseDialog}
        open={openDialog}
        title={"Atención"}
      />
      <Formik
        initialValues={{
          idPersona: data?.idPersona || undefined,
          tipoDocumento: data?.tipoDocumento != null ? data?.tipoDocumento : "",
          documento: data?.documento || "",
          cuit: data?.cuit || "",
          apellidos: data?.apellidos || "",
          nombres: data?.nombres || "",
          lugarNacimiento: data?.lugarNacimiento || "",
          domicilio: data?.domicilio || "",
          idDepartamento_Residencia: data?.idDepartamento_Residencia || "",
          idLocalidad_Residencia: data?.idLocalidad_Residencia || "",
          idEstadoCivil: data?.idEstadoCivil || "",
          sexo: data?.sexo || "",
          fechaNacimiento: data?.fechaNacimiento
            ? dayjs(formatDDMMYY(data.fechaNacimiento), "DD/MM/YYYY")
            : null,
          idTipoAntecedente: data?.idTipoAntecedente || "",
          telefono: data?.telefono || "",
          email: data?.email || "",
        }}
        validationSchema={vsPersona}
        onSubmit={(e) => {
          onSubmit(e);
        }}
        onReset={(value) => {
          setCuilExistente(false);
        }}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box
            component="form"
            sx={{
              mt: 1,
              [theme.breakpoints.up("lg")]: {
                mt: 1,
                px: 10,
                pt: 4,
              },
              [theme.breakpoints.up("md")]: {
                mt: 1,
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="CUIL"
                    type="text"
                    name="cuit"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("cuit", onlyNumbers);
                    }}
                    onPaste={handleChange}
                    value={values.cuit}
                    error={Boolean(errors.cuit && touched.cuit)}
                    onWheel={(e) => e.currentTarget.blur()} // Desenfocar el campo al girar la rueda del mouse
                    helperText={
                      Boolean(errors.cuit) && touched.cuit && errors.cuit
                    }
                    inputProps={{ maxLength: 11 }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {!edit && (
                            <Tooltip title="Buscar CUIT">
                              <IconButton
                                disabled={
                                  values.cuit == null ||
                                  String(values.cuit).length < 11
                                }
                                onClick={() => findPersona(values.cuit)}
                              >
                                <SearchOutlined type="reset" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      ),
                    }}
                    onKeyDown={(event) => {
                      if (
                        values.cuit.length > 10 &&
                        (event.code === "Enter" || event.code === "NumpadEnter")
                      ) {
                        findPersona(values.cuit);
                      }
                    }}
                    autoComplete="off"
                  />
                  {error && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      <span>
                        Ocurrió un problema al recuperar esta información
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} md={6}></Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.tipoDocumento) && touched.tipoDocumento}
                >
                  <InputLabel id="idTipoDocumento">
                    Tipo de documento
                  </InputLabel>
                  <Select
                    label="Tipo de documento"
                    id="tipoDocumento"
                    name="tipoDocumento"
                    fullWidth
                    value={values?.tipoDocumento}
                    onChange={handleChange}
                    onSelect={handleChange}
                    error={
                      Boolean(errors.tipoDocumento) && touched.tipoDocumento
                    }
                    disabled={nuevo}
                  >
                    {source_tiposDeDocumentos.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.tipoDocumento) && touched.tipoDocumento && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Nº de Documento"
                    type="text"
                    name="documento"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("documento", onlyNumbers);
                    }}
                    autoComplete="off"
                    value={values.documento}
                    error={Boolean(errors.documento && touched.documento)}
                    helperText={
                      Boolean(errors.documento) &&
                      touched.documento &&
                      errors.documento
                    }
                    inputProps={{ maxLength: 8 }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Nombres"
                    type="text"
                    name="nombres"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.nombres}
                    error={Boolean(errors.nombres && touched.nombres)}
                    helperText={
                      Boolean(errors.nombres) &&
                      touched.nombres &&
                      errors.nombres
                    }
                    inputProps={{ maxLength: 50 }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellidos"
                    type="text"
                    name="apellidos"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.apellidos}
                    error={Boolean(errors.apellidos && touched.apellidos)}
                    helperText={
                      Boolean(errors.apellidos) &&
                      touched.apellidos &&
                      errors.apellidos
                    }
                    inputProps={{ maxLength: 50 }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Lugar nacimiento"
                    type="text"
                    name="lugarNacimiento"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.lugarNacimiento}
                    error={Boolean(
                      errors.lugarNacimiento && touched.lugarNacimiento
                    )}
                    helperText={
                      Boolean(errors.lugarNacimiento) &&
                      touched.lugarNacimiento &&
                      errors.lugarNacimiento
                    }
                    inputProps={{ maxLength: 50 }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Domicilio"
                    type="text"
                    name="domicilio"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.domicilio}
                    error={Boolean(errors.domicilio && touched.domicilio)}
                    helperText={
                      Boolean(errors.domicilio) &&
                      touched.domicilio &&
                      errors.domicilio
                    }
                    inputProps={{ maxLength: 50 }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(errors.idDepartamento_Residencia) &&
                    touched.idDepartamento_Residencia
                  }
                  onDoubleClick={() => reloadDepartamentos()}
                >
                  <InputLabel id="idDepartamento">Departamento</InputLabel>
                  <Select
                    label="Departamento"
                    id="idDepartamento_Residencia"
                    name="idDepartamento_Residencia"
                    fullWidth
                    value={values?.idDepartamento_Residencia}
                    onChange={({ target }) => {
                      values.idLocalidad_Residencia = "";
                      setFieldValue("idDepartamento_Residencia", target.value);
                      findLocalidades(target.value);
                    }}
                    error={
                      Boolean(errors.idDepartamento_Residencia) &&
                      touched.idDepartamento_Residencia
                    }
                    disabled={nuevo || sErrorDepartamentos}
                  >
                    {sDepartamentos?.map((option) => (
                      <MenuItem
                        key={option.idDepartamento}
                        value={option.idDepartamento}
                      >
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.idDepartamento_Residencia) &&
                    touched.idDepartamento_Residencia && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                </FormControl>
                {sErrorDepartamentos && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    <span>
                      Ocurrió un problema al recuperar esta información
                    </span>
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    values.idDepartamento_Residencia !== "" &&
                    Boolean(errors.idLocalidad_Residencia) &&
                    touched.idLocalidad_Residencia
                  }
                  onDoubleClick={() =>
                    reloadLocalidades(values?.idDepartamento_Residencia)
                  }
                >
                  <InputLabel id="idLocalidad">Localidad</InputLabel>
                  <Select
                    label="Localidad"
                    id="idLocalidad_Residencia"
                    name="idLocalidad_Residencia"
                    fullWidth
                    value={values?.idLocalidad_Residencia}
                    onChange={handleChange}
                    error={
                      Boolean(errors.idLocalidad_Residencia) &&
                      touched.idLocalidad_Residencia
                    }
                    disabled={
                      values.idDepartamento_Residencia === "" ||
                      sErrorLocalidades
                    }
                  >
                    {sLocalidades?.map((option) => (
                      <MenuItem
                        key={option.idLocalidad}
                        value={option.idLocalidad}
                      >
                        {option.localidad}
                      </MenuItem>
                    ))}
                  </Select>
                  {values.idDepartamento_Residencia !== "" &&
                    Boolean(errors.idLocalidad_Residencia) &&
                    touched.idLocalidad_Residencia && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                  {sErrorLocalidades && values.idDepartamento_Residencia && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      <span>
                        Ocurrió un problema al recuperar esta información
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(errors.idTipoAntecedente) &&
                    touched.idTipoAntecedente
                  }
                  onDoubleClick={() => reloadAntecedentes()}
                >
                  <InputLabel id="idAntecedente">Antecedente</InputLabel>
                  <Select
                    label="Antecedente"
                    id="idTipoAntecedente"
                    name="idTipoAntecedente"
                    fullWidth
                    value={values?.idTipoAntecedente}
                    onChange={handleChange}
                    error={
                      Boolean(errors.idTipoAntecedente) &&
                      touched.idTipoAntecedente
                    }
                    disabled={nuevo}
                  >
                    {antecedentes?.map((option) => (
                      <MenuItem
                        key={option.idTipoAntecedente}
                        value={option.idTipoAntecedente}
                      >
                        {option.descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.idTipoAntecedente) &&
                    touched.idTipoAntecedente && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                  {errorAntecedentes && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      <span>
                        Ocurrió un problema al recuperar esta información
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idEstadoCivil) && touched.idEstadoCivil}
                  onDoubleClick={() => reloadEstadoCivil()}
                >
                  <InputLabel id="idEstadoCivil">Estado civil</InputLabel>
                  <Select
                    label="Estado civil"
                    id="idEstadoCivil"
                    name="idEstadoCivil"
                    fullWidth
                    value={values?.idEstadoCivil}
                    onChange={handleChange}
                    disabled={nuevo}
                    error={
                      Boolean(errors.idEstadoCivil) && touched.idEstadoCivil
                    }
                  >
                    {estadosCiviles?.map((option) => (
                      <MenuItem
                        key={option.idEstadoCivil}
                        value={option.idEstadoCivil}
                      >
                        {option.estadoCivil}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.idEstadoCivil) && touched.idEstadoCivil && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {errorEstadosCiviles && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      <span>
                        Ocurrió un problema al recuperar esta información
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.sexo) && touched.sexo}
                >
                  <InputLabel id="idSexo">Sexo</InputLabel>
                  <Select
                    label="Sexo"
                    id="sexo"
                    name="sexo"
                    fullWidth
                    value={values?.sexo}
                    onChange={handleChange}
                    error={Boolean(errors.sexo) && touched.sexo}
                    disabled={nuevo}
                  >
                    {source_sexos.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(errors.sexo) && touched.sexo && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de nacimiento"
                    name="fechaNacimiento"
                    value={values.fechaNacimiento}
                    onChange={(value) =>
                      setFieldValue("fechaNacimiento", value)
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaNacimiento) &&
                          touched.fechaNacimiento &&
                          errors.fechaNacimiento,
                        error:
                          Boolean(errors.fechaNacimiento) &&
                          Boolean(touched.fechaNacimiento),
                      },
                    }}
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Telefono"
                    type="text"
                    name="telefono"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    value={values.telefono}
                    error={Boolean(errors.telefono && touched.telefono)}
                    helperText={
                      Boolean(errors.telefono) &&
                      touched.telefono &&
                      errors.telefono
                    }
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    type="text"
                    name="email"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    onChange={handleChange}
                    value={values.email}
                    error={Boolean(errors.email && touched.email)}
                    helperText={
                      Boolean(errors.email) && touched.email && errors.email
                    }
                    disabled={nuevo}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                mb: 2,
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty || isButtonSenddisabled()}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
