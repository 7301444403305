import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  fetchTitulos,
  fetchAllTitulos,
  createTitulo,
  updateTitulo,
  deleteTitulo,
  searchTitle,
  fetchTipoTitulo,
  response,
} from "./titulos.api";

const initialState = {
  entities: [],
  entitiesAll: [],
  messageResponse: null,
  entitiesFiltered: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
  tituloXNivel: [],
  tipoTitulo: [],
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getTitulos = createAsyncThunk(
  "titulos/getTitulos",
  async (page) => {
    const r = await fetchTitulos(page);
    //console.log('r get titulos en slice', r)
    return r;
  }
);

export const getAllTitulos = createAsyncThunk(
  "titulos/getAllTitulos",
  async () => {
    const r = await fetchAllTitulos();
    //console.log('r get titulos en slice', r)
    return r;
  }
);

export const getTipoTitulo = createAsyncThunk(
  "titulos/getTipoTitulos",
  async (user) => {
    const r = await fetchTipoTitulo(user);
    return r;
  }
);

export const newTitulo = createAsyncThunk(
  "titulos/newTitulos",
  async (data) => {
    const r = await createTitulo(data);
    return r;
  }
);

export const getSearchTitle = createAsyncThunk(
  "titulos/filterTitle",
  async (data) => {
    const r = await searchTitle(data);
    return r;
  }
);

export const editTitulo = createAsyncThunk(
  "titulos/editTitulos",
  async ({ id, data }) => {
    const r = await updateTitulo({ data });
    return r;
  }
);

export const removeTitulo = createAsyncThunk(
  "titulos/removeTitulos",
  async (id) => {
    const r = await deleteTitulo(id);
    return r;
  }
);

export const clearEntities = createAction("titulos/clearEntities");
export const clearEntitiesFiltered = createAction(
  "titulos/clearEntitiesFiltered"
);

export const TitulosSlice = createSlice({
  name: "titulos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTitulos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTitulos.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload?.data;
      })
      .addCase(getTitulos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAllTitulos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTitulos.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesAll = action.payload?.data;
      })
      .addCase(getAllTitulos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTipoTitulo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTipoTitulo.fulfilled, (state, action) => {
        state.loading = false;
        state.tipoTitulo = action.payload;
      })
      .addCase(getTipoTitulo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(newTitulo.pending, (state) => {
        state.creating = true;
        state.error = null;
        state.response = null;
      })
      .addCase(newTitulo.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newTitulo.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(editTitulo.pending, (state) => {
        state.editing = true;
        state.error = null;
        state.response = null;
      })
      .addCase(editTitulo.fulfilled, (state, action) => {
        state.editing = false;
        state.response = action.payload;
      })
      .addCase(editTitulo.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(getSearchTitle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSearchTitle.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesFiltered = action.payload.data;
      })
      .addCase(getSearchTitle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeTitulo.pending, (state) => {
        state.deleting = true;
        state.error = null;
        state.response = null;
      })
      .addCase(removeTitulo.fulfilled, (state, action) => {
        state.deleting = false;
        state.response = action.payload;
      })
      .addCase(removeTitulo.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#region clearEntities
      .addCase(clearEntities, (state) => {
        state.entities = null;
      })
      //#endregion
      //#region clearEntitiesFiltered
      .addCase(clearEntitiesFiltered, (state) => {
        state.entitiesFiltered = null;
      });
    //#endregion
  },
});

export const selectTitulos = (state) => state.titulos.entities;
export const selectTitulosAll = (state) => state.titulos.entitiesAll;
export const selectTipoTitulo = (state) => state.titulos.tipoTitulo;
export const selectTitulosFiltrados = (state) => state.titulos.entitiesFiltered; // records??.
export const selectIsLoading = (state) => state.titulos.loading;
export const selectIsCreating = (state) => state.titulos.creating;
export const selectIsEditing = (state) => state.titulos.editing;
export const selectIsDeleting = (state) => state.titulos.deleting;
export const selectError = (state) => state.titulos.error;
export const sResponse = (state) => state.titulos.response;

export default TitulosSlice.reducer;
