import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchNivelesEnsenanza,
  fetchTitulo_Nivel,
} from "./nivelesEnsenanza.api";

const initialState = {
  entities: [], 
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
  tituloXNivel: [],
};

export const getNivelesEnsenanza = createAsyncThunk(
  "nivelesEnsenanza/getNivelesEnsenanza",
  async () => {
    const r = await fetchNivelesEnsenanza();
    return r;
  }
);

export const getTitulos_Nivel = createAsyncThunk(
  "titulos/getTitulos_Nivel",
  async (id) => {
    const r = await fetchTitulo_Nivel(id);
    return r;
  }
);

export const NivelesEnsenanzaSlice = createSlice({
  name: "nivelesEnsenanza",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTitulos_Nivel.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getTitulos_Nivel.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.tituloXNivel = action.payload;
      })
      .addCase(getTitulos_Nivel.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getNivelesEnsenanza.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getNivelesEnsenanza.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getNivelesEnsenanza.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectNivelesEnsenanza = (state) => state.nivelesEnsenanza.entities;
export const selectIsLoading = (state) => state.nivelesEnsenanza.loading;
export const selectTitulosXNivel = (state) => state.nivelesEnsenanza.tituloXNivel;
export const selectError = (state) => state.nivelesEnsenanza.error;

export default NivelesEnsenanzaSlice.reducer;
