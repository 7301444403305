import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSubareas,
  createSubarea,
  updateSubarea,
  deleteSubarea,
  fetchSubareasById,
  fetchSubareasByIdconDefault,
  fetchSubareasPerComp,
} from "./subareas.api";

const initialState = {
  entities: [], // La entrada original es un objeto {}.
  entitiesxArea: [],
  entitiesxPerYComp: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getSubareas = createAsyncThunk(
  "subareas/getSubareas", // Obtiene Subáreas acorde al área que se eligió!
  async () => {
    const r = await fetchSubareas();
    return r;
  }
);

export const getSubareasByID = createAsyncThunk(
  "subareas/getSubareasByID",   // Obtiene Subáreas acorde al área que se eligió, sin subárea por default.
  async (idArea) => {
    const r = await fetchSubareasById(idArea);
    return r;
  }
);

export const getSubareasByIDconDefault = createAsyncThunk(
  "subareas/getSubareasByIDconDefault",   // Obtiene Subáreas acorde al área que se eligió, con subárea por default.
  async (idArea) => {
    const r = await fetchSubareasByIdconDefault(idArea);
    return r;
  }
);

export const getSubareasPerComp = createAsyncThunk(
  "subareas/getSubareasPerComp",                   // Subáreas según "Persona" y sus competencias. 
  async (pIdPersona) => {
    const r = await fetchSubareasPerComp(pIdPersona);
    return r;
  }
);

export const newSubarea = createAsyncThunk(
  "subareas/newSubareas",
  async (data) => {
    const r = await createSubarea(data);
    return r;
  }
);

export const editSubarea = createAsyncThunk(
  "subareas/editSubareas",
  async ({ id, data }) => {
    const r = await updateSubarea({ id, data });
    try {
      //console.log('try async edit')
      return r;
    } catch {
      //console.log('catch async edit')
    } finally {
      //console.log('finally async edit')
    }
  }
);

export const removeSubarea = createAsyncThunk(
  "subareas/removeSubareas",
  async (id) => {
    const r = await deleteSubarea(id);
    try {
      //console.log('try async remove')
      return r;
    } catch {
      //console.log('catch async remove')
    } finally {
      //console.log('finally async remove')
    }
  }
);

export const SubareasSlice = createSlice({
  name: "subareas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubareas.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubareas.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getSubareas.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSubareasByID.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubareasByID.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entitiesxArea = action.payload;
      })
      .addCase(getSubareasByID.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSubareasByIDconDefault.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubareasByIDconDefault.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entitiesxArea = action.payload;
      })
      .addCase(getSubareasByIDconDefault.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSubareasPerComp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubareasPerComp.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxPerYComp = action.payload;
      })
      .addCase(getSubareasPerComp.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      .addCase(newSubarea.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newSubarea.fulfilled, (state, action) => {
        state.creating = false;
        state.entities = action.payload;
      })
      .addCase(newSubarea.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      .addCase(editSubarea.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editSubarea.fulfilled, (state, action) => {
        state.editing = false;
        state.entitiesxArea = state.entitiesxArea.map((subarea) => {
          // "records"??.
          if (subarea.id !== action.payload?.id) {
            // sacamos signo de pregunta.
            return subarea;
          }
          return {
            ...subarea,
            ...action.payload,
          };
        });
      })
      .addCase(editSubarea.rejected, (state, action) => {
        state.editing = false;
        state.error = action.error.message;
      })

      .addCase(removeSubarea.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeSubarea.fulfilled, (state, action) => {
        state.deleting = false;
        state.entitiesxArea = state.entitiesxArea.filter(
          (subarea) => subarea.id !== action.payload?.id
        );
      })
      .addCase(removeSubarea.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      });
  },
});

export const selectSubareas = (state) => state.subareas.entities;
export const selectSubareasxArea = (state) => state.subareas.entitiesxArea;
export const selectSubareasxPerYComp = (state) => state.subareas.entitiesxPerYComp;
export const selectIsLoading = (state) => state.subareas.loading;
export const selectIsCreating = (state) => state.subareas.creating;
export const selectIsEditing = (state) => state.subareas.editing;
export const selectIsDeleting = (state) => state.subareas.deleting;
export const selectError = (state) => state.subareas.error;

export default SubareasSlice.reducer;
