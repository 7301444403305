import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  GetAllPersona,
  createPersona,
  deletePerson,
  editPersona,
  fechPersona,
  fetchPersonaById,
  filterPerson,
  GetAntecedentes,
  GetEstadoCivil,
} from "./persona.api";

const initialState = {
  entities: [],
  entitiesFilter: [],
  entitiesEstadoCivil: [],
  loadingEddit: false,
  messageResponse: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
  errorFilter: null,
  personaById: [],
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getPersonas = createAsyncThunk(
  "persona/getPersonas",
  async ({ page, nombres = null, apellidos = null, documento = null }) => {
    const r = await fechPersona(page, nombres, apellidos, documento);
    return r;
  }
);
export const GetAllPersonaEndpoint = createAsyncThunk(
  "persona/GetAllPersona",
  async () => {
    const r = await GetAllPersona();
    return r;
  }
);

export const GetEstadoCivilEndpoint = createAsyncThunk(
  "persona/GetEstadoCivil",
  async () => {
    const r = await GetEstadoCivil();
    return r;
  }
);

export const filterPersonas = createAsyncThunk(
  "persona/filter",
  async (search) => {
    const r = await filterPerson(search);
    return r;
  }
);

export const createNewPerson = createAsyncThunk(
  "persona/createNewPerson",
  async (data) => {
    const r = await createPersona(data);
    return r;
  }
);

export const editPerson = createAsyncThunk(
  "persona/editPerson",
  async (data) => {
    const r = await editPersona(data);
    return r;
  }
);

export const getPersonaById = createAsyncThunk(
  "persona/getPersonaByID",
  async (id) => {
    const r = await fetchPersonaById(id);
    return r;
  }
);

export const removePersona = createAsyncThunk(
  "titulos/removePersona",
  async (id) => {
    const r = await deletePerson(id);
    return r;
  }
);

export const clearPersonaSearchData = createAction("personaSearch/clearData");
export const GetAntecedente = createAsyncThunk(
  "persona/ListarAntecedentes",
  async () => {
    const r = await GetAntecedentes();
    return r;
  }
);

export const PersonaSlice = createSlice({
  name: "personas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region GetPersonas
      .addCase(getPersonas.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getPersonas.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getPersonas.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      })
      //#endregion
      //#region GetAllPersonaEndpoint
      .addCase(GetAllPersonaEndpoint.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAllPersonaEndpoint.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(GetAllPersonaEndpoint.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      })

      .addCase(GetEstadoCivilEndpoint.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(GetEstadoCivilEndpoint.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesEstadoCivil = action.payload;
      })
      .addCase(GetEstadoCivilEndpoint.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error.message
          ? action.error.message
          : "Failed to fetch estado civil";
      })

      .addCase(filterPersonas.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.errorFilter = null;
      })
      .addCase(filterPersonas.fulfilled, (state, action) => {
        state.entitiesFilter = action.payload;
        state.loading = false;
      })
      .addCase(filterPersonas.rejected, (state, action) => {
        state.errorFilter = action.error?.stack
          ? errorMessage
          : action.error.message;
        state.loading = false;
      })
      //#region clearPersonaSearchData
      .addCase(clearPersonaSearchData, (state) => {
        state.entitiesFilter = null; // Vacía el array entitiesFilter
        state.errorFilter = null;
        state.messageResponse = null;
      })
      //#endregion    
      //#region GetPersonaById
      .addCase(getPersonaById.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getPersonaById.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.personaById = action.payload;
      })
      .addCase(getPersonaById.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      })
      //#endregion
      //#region CreateNewPerson
      .addCase(createNewPerson.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewPerson.fulfilled, (state, action) => {
        state.loading = false;
        state.messageResponse = action.payload.message;
      })
      .addCase(createNewPerson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      })
      //#endregion
      //#region EditPerson
      .addCase(editPerson.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editPerson.fulfilled, (state, action) => {
        state.loading = false;
        state.messageResponse = action.payload.message;
      })
      .addCase(editPerson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      })
      //#endregion
      //#region RemovePersona
      .addCase(removePersona.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePersona.fulfilled, (state, action) => {
        state.loading = false;
        state.messageResponse = action.payload.message;
      })
      .addCase(removePersona.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      });
    //#endregion
  },
});

export const selectPersonas = (state) => state.persona.entities;
export const selectEstadoCivil = (state) => state.persona.entitiesEstadoCivil;
export const selectPersonasFilter = (state) => state.persona.entitiesFilter;
export const selectPersonaById = (state) => state.persona.personaById;
export const selectIsLoadingEditt = (state) => state.persona.loadingEddit;
export const selectIsLoading = (state) => state.persona.loading;
export const selectError = (state) => state.persona.error;
export const selectErrorFilter = (state) => state.persona.errorFilter;
export const selectMessageResponse = (state) => state.persona.messageResponse;

export default PersonaSlice.reducer;
