import { useQuery } from "react-query";
import { fetchAreasListarTodas } from "store/areasList/areasList.api";

export const useAreaQuery = () => {
  const areaQuery = useQuery(
    {
      queryKey: ["areasQuery"],
      queryFn: async () => {
        const { data } = await fetchAreasListarTodas();
        return data
          ?.sort((a, b) => {
            if (a.codigo < b.codigo) return -1;
            if (a.codigo > b.codigo) return 1;
            return 0;
          })
          .map((item) => ({
            ...item,
            descripcion: `${item?.codigo} - ${item?.descripcion}`,
          }));
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      refetchOnMount: false, // Desactiva el re-fetch al montar el componente
      refetchOnHover: false,
      refetchInterval: false,
      staleTime: 1000 * 60 * 60,
    }
  );

  return areaQuery;
};
