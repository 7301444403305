import React, { useEffect } from 'react'
import PuntuacionTable from './tabla/PuntuacionTable'
import NuevoItems from './nuevo/NuevoItems.js'
import { Box, Paper, TableContainer, Typography } from '@mui/material'
import ItemsPuntuacionFiltro from './ItemsPuntuacionFiltro'
import { listarItemsDePuntuacion_PBusqueda } from 'store/PuntuacionPersona/PuntuacionPersona.slice'
import { useDispatch } from 'react-redux'

const initialPage = {
  page: 1,
  idTipo: null,
  codigo: "",
  desc: "",
};
export const ItemsPuntuacion = () => {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(initialPage);

  const handleSearch = ({ idTipo, codigo, desc }) => {
    setPage({ ...initialPage, idTipo, codigo, desc });
  };

  useEffect(() => {
    handleSearchChange(page);
  }, [dispatch, page]);

  const handleSearchChange = (page) => {
    dispatch(listarItemsDePuntuacion_PBusqueda(page));
  };


  return (
    <div>
      <Typography variant="h4">Items de Puntuacion</Typography>
      <TableContainer component={Paper}>
        <Box sx={{ display: 'flex', gap: 1, mt: 2, marginTop: '25px', marginBottom: '15px' }}>
          <ItemsPuntuacionFiltro filter={1}
            onSearch={handleSearch} />
          {/* <NuevoItems /> */}
        </Box>
      </TableContainer>

      <PuntuacionTable page={page} setPage={setPage} />
    </div>
  )
}
