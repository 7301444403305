import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete, Box, Button, TextField, Stack, Grid, FormControl } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { selectNivelesEnsenanza } from 'store/nivelesEnsenanza/nivelesEnsenanza.slice';
import { getAllZonas, getZonaPorDepartamento, selectAllZonas, selectZonaxDepartamento } from 'store/zona/zona.slice';
import { GetDepartamentos, getDeptosxTipoPeriodo, selectDepartamentos, selectDeptosxTipoPer } from 'store/Localidad/Localidad.slice';
import { getInscGeneralesxDepto, selectInscGeneralesxDepto } from 'store/inscripcionGeneral/inscripcionGeneral.slice';
import { selectSubareasxPerYComp } from 'store/subareas/subareas.slice';
import { getInscxPersona, selectErrorInscxPer } from 'store/inscripcionesxPersona/inscripcionesxPersona.slice';
import { InscxPersonaContext } from '../../InscripcionesxPersonaPage/InscripcionesxPersonaPage';
import { vsInscripcionesxPersona } from './InscripcionesxPersonaYup';


export default function InscripcionesxPersonaForm({ datosInscPersona, persona, onSubmitNewEdit, onClose }) {

  //console.log('datosInscPersona', datosInscPersona)

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const pageAndSearch = React.useContext(InscxPersonaContext);
  const [datosSubareas, setDatosSubareas] = useState([]);
  const [inscGeneralTipoPer, setInscGeneralTipoPer] = useState(datosInscPersona?.inscripcionGeneral?.periodo?.idTipoPeriodo || null)
  const autocompleteRef = useRef(null);
  const getDesc_AllNiveles = useSelector(selectNivelesEnsenanza);
  const getDesc_AllZonas = useSelector(selectAllZonas);
  const getAllDeptos = useSelector(selectDepartamentos);
  const getZonaxDepto = useSelector(selectZonaxDepartamento);
  const getSubareasPorPerYComp = useSelector(selectSubareasxPerYComp);
  const listaInscGeneralesxDepto = useSelector(selectInscGeneralesxDepto);
  const getDeptosxTipoPer = useSelector(selectDeptosxTipoPer);
  const inscxPersonaError = useSelector(selectErrorInscxPer);


  useEffect(() => {
    dispatch(getZonaPorDepartamento(persona?.idDepartamento_Residencia));  // Sólo Secundario.
  }, [dispatch, getAllDeptos]);

  useEffect(() => {
    dispatch(getAllZonas());  // Sólo Secundario.
    dispatch(GetDepartamentos({ idProvincia: 2 }));  // Sólo Primario.
    dispatch(getInscGeneralesxDepto(persona?.idDepartamento_Residencia));
  }, [dispatch]);

  useEffect(() => {
    let lista1 = ((getSubareasPorPerYComp?.data !== undefined) && getSubareasPorPerYComp?.data?.map((x) => (
      { descripcion: x.descripcion, idSubAreas: x.idSubAreas, idArea: x.area, idNivel: x.idnivel }
    ))) || [];
    setDatosSubareas(lista1);
  }, [getSubareasPorPerYComp]);

  useEffect(() => {
    (inscGeneralTipoPer !== null) && dispatch(getDeptosxTipoPeriodo(inscGeneralTipoPer));
  }, [dispatch, inscGeneralTipoPer])


  const handleSubmit = (values) => {
    const datoAEnviar = {
      ...values,
      idsSubAreas: datosInscPersona ? [values.subareas[0].idSubAreas] : values.subareas.map((x) => (
        x.idSubAreas
      )),
      domicilioInscripcion: values.domicilioInscripcion,
      idDepartamentoOpcional: (persona?.idDepartamento_Residencia !== values.idDepartamentoOpcional) ?
        values.idDepartamentoOpcional : 0,
      deshabilitado: 0,
    }
    //console.log('datoAEnviar en inscGeneralForm', datoAEnviar)
    onSubmitNewEdit(datoAEnviar);
    setTimeout(() => {
      dispatch(getInscxPersona(pageAndSearch));
    }, 400);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  function llegoA4(values) {
    if (datosInscPersona === undefined) {
      const contadorSinA29 = values?.subareas?.filter((x) => (x.idArea !== 380))   // Descartamos Área 29 de la cuenta.
      if (contadorSinA29.length <= 3) {        // El NEW puede tener 4 Áreas(descartando área 29).
        return datosSubareas.filter((x) => (x?.idNivel === values?.idNivelEnsenanza)) 
      } else {
        return []
      }
    } else {
      if (values?.subareas?.length <= 0) {       // Sólo se puede EDITAR de a una SubÁrea.
        return datosSubareas.filter((x) => (x?.idNivel === values?.idNivelEnsenanza))            
      } else {
        return []
      }
    }
  }

  if (getZonaxDepto?.data === null) {
    return (
      <p style={{ margin: "10px 30px 20px 30px", textAlign: "center", fontSize: "18px" }}>
        El Agente no reside en la Provincia de Catamarca
      </p>
    );
  } else {
    return (
      getZonaxDepto?.data && /* listaInscGeneralesxDepto?.data && getDesc_AllNiveles?.records && */
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <Formik
          initialValues={{
            idPersona: persona?.idPersona || null,
            idInscripcionPersona: datosInscPersona?.idInscripcionPersona || 0,
            idNivelEnsenanza: datosInscPersona?.inscripcionGeneral?.nivelEnsenanza?.idNivelEnsenanza || null,
            nivel: datosInscPersona?.inscripcionGeneral?.nivelEnsenanza?.nivel || "",
            idZona: getZonaxDepto?.data?.idZona || null,
            idDepartamento: persona?.idDepartamento_Residencia || null,
            idInscGeneral: datosInscPersona?.inscripcionGeneral?.idInscGeneral || null,
            idDepartamentoOpcional: datosInscPersona?.departamentoOpcional?.idDepartamento || null,
            subareas: datosInscPersona?.subAreas ? [{
              descripcion: datosInscPersona?.subAreas?.descripcion,
              idSubAreas: datosInscPersona?.subAreas?.idSubAreas
            }] : [],
            domicilioInscripcion: datosInscPersona?.domicilioInscripcion || "",
            fechaInscripcion: datosInscPersona?.fechaInscripcion ? dayjs(datosInscPersona?.fechaInscripcion) : null,
            expediente: datosInscPersona?.expediente || "",
            observaciones: datosInscPersona?.observaciones || "",
          }}
          validationSchema={vsInscripcionesxPersona}
          onSubmit={handleSubmit}
          onReset={(values, { setFieldValue }) => {
            if (autocompleteRef.current) {
              autocompleteRef.current.getElementsByClassName("MuiAutocomplete-clearIndicator")[0]?.click();
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            touched,
            setFieldValue,
            submitForm,
            resetForm,
          }) => (
            <Box component="form" sx={{ mt: 1 }}>
              <Grid container spacing={2}>

                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.idNivelEnsenanza) && touched.idNivelEnsenanza}
                  >
                    <Form>
                      {datosInscPersona ?
                        <Field name="idNivelEnsenanza">
                          {({ field }) => (
                            <Autocomplete
                              disabled
                              onChange={(e, value) => {
                                setFieldValue("idNivelEnsenanza", value ? value.id : null);
                                setFieldValue("nivel", value ? value.nivel : null);
                              }}
                              options={
                                (getDesc_AllNiveles?.records &&
                                  getDesc_AllNiveles?.records
                                    .map((option) => ({
                                      id: option.idNivelEnsenanza,
                                      label: option.nivelEnsenanza,
                                      nivel: option.nivel
                                    }))) ||
                                []
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                              name="idNivelEnsenanza"
                              value={
                                getDesc_AllNiveles?.records?.find(
                                  (item) => item.idNivelEnsenanza === values.idNivelEnsenanza
                                )?.nivelEnsenanza || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Nivel de Enseñanza"
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="off"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  error={
                                    Boolean(errors.idNivelEnsenanza) && touched.idNivelEnsenanza
                                  }
                                  sx={{
                                    "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                  }}
                                  helperText={
                                    Boolean(errors.idNivelEnsenanza) &&
                                    touched.idNivelEnsenanza &&
                                    errors.idNivelEnsenanza
                                  }
                                />
                              )}
                            />
                          )}
                        </Field>
                        :
                        <Field name="idNivelEnsenanza">
                          {({ field }) => (
                            <Autocomplete
                              onChange={(e, value) => {
                                resetForm();        // Iniciamos reiniciando los campos.
                                setFieldValue("idNivelEnsenanza", value ? value.id : null);
                                setFieldValue("nivel", value ? value.nivel : null);
                              }}
                              options={
                                (getDesc_AllNiveles?.records &&
                                  getDesc_AllNiveles.records
                                    .filter((x) => (x.nivelEnsenanza === 'PRIMARIO' || x.nivelEnsenanza === 'SECUNDARIO'))
                                    .map((option) => ({
                                      id: option.idNivelEnsenanza,
                                      label: option.nivelEnsenanza,
                                      nivel: option.nivel
                                    }))) ||
                                []
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                              name="idNivelEnsenanza"
                              value={
                                getDesc_AllNiveles?.records?.find(
                                  (item) => item.idNivelEnsenanza === values.idNivelEnsenanza
                                )?.nivelEnsenanza || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Nivel de Enseñanza"
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="off"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  error={
                                    Boolean(errors.idNivelEnsenanza) && touched.idNivelEnsenanza
                                  }
                                  sx={{
                                    "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                  }}
                                  helperText={
                                    Boolean(errors.idNivelEnsenanza) &&
                                    touched.idNivelEnsenanza &&
                                    errors.idNivelEnsenanza
                                  }
                                />
                              )}
                            />
                          )}
                        </Field>
                      }
                    </Form>
                    {inscxPersonaError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {(values.nivel === "M") && persona?.idDepartamento_Residencia &&    // Sólo para Secundario.
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.idZona) && touched.idZona}
                    >
                      <Form>
                        <Field name="idZona">
                          {({ field }) => (
                            <Autocomplete
                              disabled
                              onChange={(e, value) => {
                                setFieldValue("idZona", value ? value.id : null);
                              }}
                              options={
                                (getDesc_AllZonas?.records &&
                                  getDesc_AllZonas?.records.map((option) => ({
                                    id: option.idZona,
                                    label: option.descripcion,
                                  }))) ||
                                []
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                              name="idZona"
                              value={
                                getDesc_AllZonas?.records?.find(
                                  (item) => item.idZona === values.idZona
                                )?.descripcion || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Zona de Residencia"
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="off"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  error={
                                    Boolean(errors.idZona) && touched.idZona
                                  }
                                  sx={{
                                    "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                  }}
                                  helperText={
                                    Boolean(errors.idZona) &&
                                    touched.idZona &&
                                    errors.idZona
                                  }
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Form>
                      {Boolean(errors.idZona) && touched.idZona && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Este campo es obligatorio
                        </FormHelperText>
                      )}
                      {inscxPersonaError && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Ocurrió un problema al recuperar este dato
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>}

                {(values.nivel === "P") && persona?.idDepartamento_Residencia &&      // Sólo para Primario.
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.idDepartamento) && touched.idDepartamento}
                    >
                      <Form>
                        <Field name="idDepartamento">
                          {({ field }) => (
                            <Autocomplete
                              disabled
                              onChange={(e, value) => {
                                setFieldValue("idDepartamento", value ? value.id : null);
                              }}
                              options={
                                (getAllDeptos &&
                                  getAllDeptos?.map((option) => ({
                                    id: option.idDepartamento,
                                    label: option.nombre,
                                  }))) ||
                                []
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                              name="idDepartamento"
                              value={
                                getAllDeptos?.find(
                                  (item) => item.idDepartamento === values.idDepartamento
                                )?.nombre || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Departamento de Residencia"
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="off"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  error={
                                    Boolean(errors.idDepartamento) && touched.idDepartamento
                                  }
                                  sx={{
                                    "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                  }}
                                  helperText={
                                    Boolean(errors.idDepartamento) &&
                                    touched.idDepartamento &&
                                    errors.idDepartamento
                                  }
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Form>
                      {Boolean(errors.idDepartamento) && touched.idDepartamento && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Este campo es obligatorio
                        </FormHelperText>
                      )}
                      {inscxPersonaError && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Ocurrió un problema al recuperar este dato
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                }

                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.idInscGeneral) && touched.idInscGeneral}
                  >
                    <Form>
                      <Field name="idInscGeneral">
                        {({ field }) => (
                          <Autocomplete
                            onChange={(e, value) => {
                              setFieldValue("idInscGeneral", value ? value.id : null);
                              setFieldValue("idTipoPeriodo", value ? value.idTipoPeriodo : null);
                              setInscGeneralTipoPer(value ? value.idTipoPeriodo : null);
                              setFieldValue("idDepartamentoOpcional", null);
                            }}
                            options={
                              (listaInscGeneralesxDepto?.data &&
                                listaInscGeneralesxDepto.data.filter((x) => x.nivelEnsenanza.nivel === values.nivel)
                                  .map((option) => ({
                                    id: option?.idInscGeneral,
                                    label: option?.descripcion,
                                    idTipoPeriodo: option?.periodo?.idTipoPeriodo,
                                  }))
                              )
                              || []
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              );
                            }}
                            name="idInscGeneral"
                            value={
                              listaInscGeneralesxDepto?.data?.find(
                                (item) => item.idInscGeneral === values.idInscGeneral
                              )?.descripcion || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Inscripción General"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{ ...params.InputProps, type: "search" }}
                                error={
                                  Boolean(errors.idInscGeneral) && touched.idInscGeneral
                                }
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                                }}
                                helperText={
                                  Boolean(errors.idInscGeneral) &&
                                  touched.idInscGeneral &&
                                  errors.idInscGeneral
                                }
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Form>
                    {inscxPersonaError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {(values.nivel === "P") &&      // Depto Opcional: Sólo para Primario.          
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors.idDepartamentoOpcional) && touched.idDepartamentoOpcional}
                    >
                      <Form>
                        <Field name="idDepartamentoOpcional">
                          {({ field }) => (
                            <Autocomplete
                              onChange={(e, value) => {
                                setFieldValue("idDepartamentoOpcional", value ? value.id : null);
                              }}
                              options={
                                (getDeptosxTipoPer?.data &&
                                  getDeptosxTipoPer?.data.map((option) => ({
                                    id: option.idDepartamento,
                                    label: option.nombre,
                                  }))) ||
                                []
                              }
                              renderOption={(props, option) => {
                                return (
                                  <li {...props} key={option.id}>
                                    {option.label}
                                  </li>
                                );
                              }}
                              name="idDepartamentoOpcional"
                              value={
                                getDeptosxTipoPer?.data?.find(
                                  (item) => item.idDepartamento === values.idDepartamentoOpcional
                                )?.nombre || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Departamento Opcional"
                                  margin="normal"
                                  variant="outlined"
                                  autoComplete="off"
                                  InputProps={{ ...params.InputProps, type: "search" }}
                                  error={
                                    Boolean(errors.idDepartamentoOpcional) && touched.idDepartamentoOpcional
                                  }
                                  sx={{
                                    "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                  }}
                                  helperText={
                                    Boolean(errors.idDepartamentoOpcional) &&
                                    touched.idDepartamentoOpcional &&
                                    errors.idDepartamentoOpcional
                                  }
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Form>
                      {Boolean(errors.idDepartamentoOpcional) && touched.idDepartamentoOpcional && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Este campo es obligatorio
                        </FormHelperText>
                      )}
                      {inscxPersonaError && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Ocurrió un problema al recuperar este dato
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                }

                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.subareas) && touched.subareas}
                  >
                    <Form>
                      <Field name="subareas">
                        {() => (
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={llegoA4(values)}                // datosSubareas
                            defaultValue={values?.subareas || []}
                            getOptionLabel={(option) => option.descripcion || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.idSubAreas === value.idSubAreas
                            }
                            filterSelectedOptions
                            onChange={(event, value) => {
                              setFieldValue("subareas", value !== null ? value : "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Subáreas (4 máximo)"
                                placeholder="Seleccione Subáreas"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{ ...params.InputProps, type: "search" }}
                                error={
                                  Boolean(errors.subareas) && touched.subareas
                                }
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                                }}
                                helperText={
                                  Boolean(errors.subareas) &&
                                  touched.subareas &&
                                  errors.subareas
                                }
                              />
                            )}
                            ref={autocompleteRef}
                          />
                        )}
                      </Field>
                    </Form>
                    {inscxPersonaError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} >
                  <FormControl fullWidth>
                    <TextField
                      label="Domicilio de Residencia"
                      type="text"
                      name="domicilioInscripcion"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      autoComplete="off"
                      value={values.domicilioInscripcion}
                      error={Boolean(errors.domicilioInscripcion && touched.domicilioInscripcion)}
                      helperText={
                        Boolean(errors.domicilioInscripcion) &&
                        touched.domicilioInscripcion &&
                        errors.domicilioInscripcion
                      }
                      inputProps={{ maxLength: 152 }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4} style={{ marginTop: "6px" }}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Fecha de inscripción"
                      name="fechaInscripcion"
                      value={values.fechaInscripcion}
                      onChange={(value) => setFieldValue("fechaInscripcion", value)}
                      slotProps={{
                        textField: {
                          helperText:
                            Boolean(errors.fechaInscripcion) &&
                            touched.fechaInscripcion &&
                            errors.fechaInscripcion,
                          error:
                            Boolean(errors.fechaInscripcion) &&
                            Boolean(touched.fechaInscripcion),
                        },
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={8} style={{ marginTop: "6px" }}>
                  <FormControl fullWidth>
                    <TextField
                      label="Expediente"
                      type="text"
                      name="expediente"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      autoComplete="off"
                      value={values.expediente}
                      error={Boolean(errors.expediente && touched.expediente)}
                      helperText={
                        Boolean(errors.expediente) &&
                        touched.expediente &&
                        errors.expediente
                      }
                      inputProps={{ maxLength: 52 }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "6px" }}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      fullWidth
                      label="Observaciones"
                      type="text"
                      name="observaciones"
                      variant="outlined"
                      rows={2}
                      onChange={handleChange}
                      autoComplete="off"
                      value={values.observaciones}
                      error={Boolean(errors.observaciones && touched.observaciones)}
                      helperText={
                        Boolean(errors.observaciones) &&
                        touched.observaciones &&
                        errors.observaciones
                      }
                      inputProps={{ maxLength: 252 }}
                    />
                  </FormControl>
                </Grid>
                {/* {console.log('values en render', values)} */}
              </Grid>

              <Stack
                spacing={2}
                direction="row"
                sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleClose()}
                  spacing={2}
                >
                  Cancelar
                </Button>
                <Button variant="contained" onClick={() => submitForm()}>
                  Guardar
                </Button>
              </Stack>
            </Box>
          )}
        </Formik>
      </LocalizationProvider>
    );
  }
}