import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDepCatamarca } from './localidades.api';

const initialState = {
  entities: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const GetDepCatamarca  = createAsyncThunk(
  'localidades/GetDepCatamarca',
  async () => {
    const r = await fetchDepCatamarca();
    return r;
  }
);

export const localidadesSlice = createSlice({
  name: 'localidades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetDepCatamarca.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDepCatamarca.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(GetDepCatamarca.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
  },
});

export const selectDeptosCatamarca = (state) => state.localidades.entities;
export const selectIsLoading = (state) => state.localidades.loading;
export const selectIsCreating = (state) => state.localidades.creating;
export const selectIsEditing = (state) => state.localidades.editing;
export const selectIsDeleting = (state) => state.localidades.deleting;
export const selectError = (state) => state.localidades.error;

export default localidadesSlice.reducer;
