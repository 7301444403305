import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProyectoForm from '../ProyectoForm/ProyectoForm';
import { newProyecto } from 'store/proyectos/proyectos.slice';

export default function Concurso() {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(newProyecto(data));
  };

  return (
    <React.Fragment>
      
    </React.Fragment>
  );
}
