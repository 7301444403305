import * as Yup from "yup";

export const vsInscGeneral = Yup.object({
  idNivelEnsenanza: Yup.number().required("Este campo es obligatorio").integer(),
  idPeriodo: Yup.number().required("Este campo es obligatorio").integer(),
  //idZona: Yup.number().required("Este campo es obligatorio").integer(),
  //idDepartamento: Yup.number().required("Este campo es obligatorio").integer(),
  /* idZona: Yup.number().when('idDepartamento', {
    is: null,
    then: Yup.number().required("Este campo es obligatorio").integer(),
    otherwise: Yup.number().notRequired(),
  }), */
  idEstadoInsc: Yup.number().required("Este campo es obligatorio").integer(),
  fechaInicioInsc: Yup.date()
    .typeError("Ingrese una fecha válida")
    .required("Este campo es obligatorio")
    .min(Yup.ref("fechaIniPeriodoMenos1"), "La Fecha de Inicio de la Inscripción debe ser posterior o igual a la Fecha de Inicio del Período")
    .max(Yup.ref("fechaFinPeriodoMas1"), "La Fecha de Inicio de la Inscripción debe ser inferior a la Fecha de Fin del Período"),
  fechaFinInsc: Yup.date()
    .typeError("Ingrese una fecha válida")
    .required("Este campo es obligatorio")
    .min(Yup.ref("fechaInicioInsc"), "La Fecha de Fin de la Inscripción debe ser posterior o igual a la Fecha de Inicio de la Inscripción")
    .max(Yup.ref("fechaFinPeriodoMas1"), "La Fecha de Fin de la Inscripción debe ser inferior a la Fecha de Fin del Período"),
});