import { request } from "utils/helpers/request.helpers";

const periodoSageJuntasUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/Periodo`;

const user = localStorage.getItem("token");

export function fetchPeriodo_ByTipoPeriodo(pIdTipoPeriodo) {
  return request(
    `${periodoSageJuntasUrl}/GetPeriodo_ByTipoPeriodo?pIdTipoPeriodo=${pIdTipoPeriodo}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function fetchPeriodos(page) {
  return request(
    `${periodoSageJuntasUrl}/Listar?page=${page}&tipoPeriodoIds=4&tipoPeriodoIds=5`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function fetchPeriodosxNivel(pNivel) {
  return request(
    `${periodoSageJuntasUrl}/GetPeriodo_ByNivel?pNivel=${pNivel}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function fetchPeriodosSinPaginado() {
  return request(
    `${periodoSageJuntasUrl}/ListarPeriodos`,    // Todos los períodos SIN PAGINADO Y ABIERTOS.
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function fetchPeriodo(id) {
  return request(`${periodoSageJuntasUrl}/GetPeriodo_ByID?pId=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}

export function createPeriodo(data) {
  return request(`${periodoSageJuntasUrl}/InsertarPeriodo`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updatePeriodo(data) {
  return request(`${periodoSageJuntasUrl}/ModificarPeriodo?pId=${data.id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export async function disabledPeriodo(data) {
  return request(`${periodoSageJuntasUrl}/Deshabilitar?pIdPeriodo=${data}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  });
}

export function searchPeriodo(page, search) {
  return request(
    `${periodoSageJuntasUrl}/FiltrarPeriodosAppC?pBusqueda=${search}&page=${page}&usuario=${user}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
}

