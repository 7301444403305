import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEstadosProyecto } from './estadosProyecto.api';

const initialState = {
  entities: [],
  loading: false,
  error: null,
};

export const getEstadosProyecto = createAsyncThunk(
  'estadosProyecto/getEstadosProyecto',
  async () => await fetchEstadosProyecto()
);

export const EstadosProyectoSlice = createSlice({
  name: 'estadosProyecto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEstadosProyecto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEstadosProyecto.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getEstadosProyecto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectEstadosProyecto = (state) => state.estadosProyecto.entities;
export const selectEstadosProyectoIsLoading = (state) =>
  state.estadosProyecto.loading;
export const selectEstadosProyectoError = (state) =>
  state.estadosProyecto.error;

export default EstadosProyectoSlice.reducer;
