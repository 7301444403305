import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AreasListForm from "../AreasListForm/AreasListForm";
import { editArea } from "store/areasList/areasList.slice";
import { Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

export default function AreasListEditar({ areasList }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(editArea({ id: areasList.idArea, data }));
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <CustomModal
        title={"Editar Área"}
        open={open}
        setOpen={handleClose}
        maxWidth="sm"
      >
        <DialogContent>
          <AreasListForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            data={areasList}
          />
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
