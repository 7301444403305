import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAreasList,
         createArea,
         updateArea,
         deleteArea,
         getAreaByID
        } from "./areasList.api";

const initialState = {
  entities: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getAreas = createAsyncThunk("areas/getAreas",
  async (page) => {
    const r = await fetchAreasList(page);
    return r;
});
export const getAreasByID = createAsyncThunk("areas/getAreas/id",
  async (idArea) => {
    const r = await getAreaByID(idArea);
    return r;
});

export const newArea = createAsyncThunk("areas/newArea",
  async(data) =>{
    const r = await createArea(data);
    return r;
  }
);

export const editArea = createAsyncThunk("areas/editArea",
  async({id, data}) =>{
    const r = await updateArea({ id, data});
    return r;
  }
);

export const removeArea = createAsyncThunk("areas/removeArea",
  async(data) =>{
    const r = await deleteArea(data);
    return r;
  }
);

export const AreasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAreas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getAreas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(newArea.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newArea.fulfilled, (state, action) => {
        state.creating = false;
        state.entities = action.payload;
        state.response = action.payload;
      })
      .addCase(newArea.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      .addCase(editArea.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editArea.fulfilled, (state, action) => {
        state.editing = false;
        state.entities = state.entities.records.map((areasList) => {
          if (areasList.id !== action.payload?.id) {
            return areasList;
          }
          return {
            ...areasList,
            ...action.payload,
          };
        });
      })
      .addCase(editArea.rejected, (state, action) => {
        state.editing = false;
        state.error = action?.error?.message;
      })

      .addCase(removeArea.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(removeArea.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state?.entities?.records?.filter(
          (areasList) => areasList.id !== action.payload?.id
        );
        state.response = action.payload;
      })
      .addCase(removeArea.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      })
      .addCase(getAreasByID.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(getAreasByID.fulfilled, (state, action) => {
        state.deleting = false;
        state.entities = state.entities.records.filter(
          (areasList) => areasList.id !== action.payload?.id
        );
      })
      .addCase(getAreasByID.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      }); 
  },
});

export const selectAreas = (state) => state.areas.entities;
export const selectResponseNewAreas = (state) => state.areas.response;  
export const selectIsLoading = (state) => state.areas.loading;
export const selectIsCreating = (state) => state.areas.creating;
export const selectIsEditing = (state) => state.areas.editing;
export const selectIsDeleting = (state) => state.areas.deleting;
export const selectError = (state) => state.areas.error;

export default AreasSlice.reducer;
