import { request } from 'utils/helpers/request.helpers';

const localidadesUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/Proyecto`; // Corregir la palabra "Proyecto" por "Localidades".

/* export function GetLocalidades() {
  const url = `${localidadesUrl}/GetLocalidades`;
  return request(url, { method: 'GET' });
} */

export function fetchDepCatamarca() {
  return request(`${localidadesUrl}/GetDepartamento`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        idProvincia: 2,                          // Sólo Departamentos de Catamarca.
        departamento: ""
      }),
    });
}

/* export function GetLocalidad(idDepartamento, localidad) {
  return request(`${localidadesUrl}/GetLocalidadesxDpto`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      idDepartamento: idDepartamento,
      localidad: localidad
    }),
  });
} */