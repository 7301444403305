import React, { useContext, useEffect, useState } from "react";
import CompetenciaFiltro from "../Common/CompetenciaFiltro";
import { Pagination, Paper, Stack } from "@mui/material";
import CompetenciaTable from "../Table/CompetenciaTable";
import { CompetenciaContext } from "../Common/CompetenciaProvider";
import Loader from "components/Loader/Loader";
import { useCompetenciasQuery } from "Hooks/Competencia/UseCompetenciasQuery";
import SnackBarUtils from "utils/MUI/SnackbarUtils";

const initialPage = {
  page: 1,
  idArea: null,
  idSubArea: null,
  materia: "",
  titulo: "",
};

const CompetenciaPage = () => {
  let [page, setPage] = React.useState(initialPage);
  const { setCompetencia, openSnackbar, messageAlert, setOpenSnackbar } =
    useContext(CompetenciaContext);
  const competenciasQuery = useCompetenciasQuery(page);

  const handleSearch = ({ idArea, idSubArea, materia, titulo }) => {
    setPage({ ...initialPage, idArea, idSubArea, materia, titulo });
  };

  const handleForm = (value, isNew = false) => {
    isNew && setCompetencia(null);
    !value && setPage({ ...page });
  };

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  return (
    <>
      {competenciasQuery.isLoading && <Loader />}
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}>Competencias</h1>
      </div>
      <Paper
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 15,
          backgroundColor: "#FFFFFF",
          borderRadius: 0,
        }}
      >
        <CompetenciaFiltro
          filter={1}
          onAdd={() => console.log("onAdd")}
          onSearch={handleSearch}
        />
      </Paper>
      <CompetenciaTable
        competencias={competenciasQuery?.data?.records}
        onSelect={handleForm}
      />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={
            competenciasQuery?.data?.pages ? competenciasQuery.data.pages : 1
          }
          page={page?.page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </>
  );
};
export default CompetenciaPage;
