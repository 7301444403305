import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  getInstituciones,
  selectInstituciones,
  selectInstitucionesError,
} from 'store/instituciones/instituciones.slice';
import {
  getModalidadesProyecto,
  selectModalidadesProyecto,
  selectModalidadesProyectoError,
} from 'store/modalidadesProyecto/modalidadesProyecto.slice';
import {
  getTiposProyecto,
  selectTiposProyecto,
  selectTiposProyectoError,
} from 'store/tiposProyecto/tiposProyectos.slice';
import {
  getEstadosProyecto,
  selectEstadosProyecto,
  selectEstadosProyectoError,
} from 'store/estadosProyecto/estadosProyecto.slice';

export default function ProyectoForm({ data, onSubmit }) {
  const [proyecto, setProyecto] = useState(data || {});
  const instituciones = useSelector(selectInstituciones);
  const institucionesError = useSelector(selectInstitucionesError);
  const modalidades = useSelector(selectModalidadesProyecto);
  const modalidadesError = useSelector(selectModalidadesProyectoError);
  const tipos = useSelector(selectTiposProyecto);
  const tiposError = useSelector(selectTiposProyectoError);
  const estados = useSelector(selectEstadosProyecto);
  const estadosError = useSelector(selectEstadosProyectoError);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstituciones());
    dispatch(getModalidadesProyecto());
    dispatch(getTiposProyecto());
    dispatch(getEstadosProyecto());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(proyecto);
  };

  const handleChange = (e) => {
    setProyecto({ ...proyecto, [e.target.name]: e.target.value });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              required
              fullWidth
              id="denominacion"
              label="Denominación"
              name="denominacion"
              autoFocus
              value={proyecto?.denominacion || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="institucion-select-label">Institución</InputLabel>
            <Select
              labelId="institucion-select-label"
              id="institucion"
              label="Institución"
              name="institucion"
              value={proyecto?.institucion || ''}
              onChange={handleChange}
              error={!!institucionesError}
              required
            >
              {instituciones.map((option) => (
                <MenuItem
                  key={option.idInstitucion}
                  value={option.idInstitucion}
                >
                  {option.nombre}
                </MenuItem>
              ))}
            </Select>
            {institucionesError && (
              <FormHelperText>Error al cargar</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="modalidad-select-label">Modalidad</InputLabel>
            <Select
              labelId="modalidad-select-label"
              id="modalidad"
              label="Modalidad"
              name="modalidadProyecto"
              value={proyecto?.modalidadProyecto || ''}
              onChange={handleChange}
              error={!!modalidadesError}
              required
            >
              {modalidades.map((option) => (
                <MenuItem
                  key={option.idModalidadProyecto}
                  value={option.idModalidadProyecto}
                >
                  {option.denominacion}
                </MenuItem>
              ))}
            </Select>
            {modalidadesError && (
              <FormHelperText>Error al cargar</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="tipo-select-label">Tipo</InputLabel>
            <Select
              labelId="tipo-select-label"
              id="tipo"
              label="Tipo"
              name="tipoProyecto"
              value={proyecto?.tipoProyecto || ''}
              onChange={handleChange}
              error={!!tiposError}
              required
            >
              {tipos.map((option) => (
                <MenuItem
                  key={option.idTipoProyecto}
                  value={option.idTipoProyecto}
                >
                  {option.denominacion}
                </MenuItem>
              ))}
            </Select>
            {tiposError && <FormHelperText>Error al cargar</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="estado-select-label">Estado</InputLabel>
            <Select
              labelId="estado-select-label"
              id="estado"
              label="Estado"
              name="estadoProyecto"
              value={proyecto?.estadoProyecto || ''}
              onChange={handleChange}
              error={!!estadosError}
              required
            >
              {estados.map((option) => (
                <MenuItem
                  key={option.idEstadoProyecto}
                  value={option.idEstadoProyecto}
                >
                  {option.descripcion}
                </MenuItem>
              ))}
            </Select>
            {estadosError && <FormHelperText>Error al cargar</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="tiempoPrevisto"
              label="Tiempo Previsto"
              name="tiempoPrevisto"
              autoFocus
              value={proyecto?.tiempoPrevisto || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              id="lugar"
              label="Lugar"
              name="lugar"
              autoFocus
              value={proyecto?.lugar || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              multiline
              id="observaciones-multiline"
              label="Observaciones"
              name="observaciones"
              rows={4}
              value={proyecto?.observaciones || ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, mb: 2 }}>
        <Button type="submit" variant="contained">
          Guardar
        </Button>
      </Box>
    </Box>
  );
}
