import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPxAgenteA1List,
  fetchPxAgenteA2List,
} from "./puntajexAgente.api";

const initialState = {
  entitiesA1: [],                 // Área 1.
  responseA1: {},
  message1: {},
  /* loadingA1: false,
  creatingA1: false,
  editingA1: false,
  deletingA1: false, */
  errorA1: null,

  entitiesA2: [],                 // Área 2.
  responseA2: {},
  message2: {},
  errorA2: null,
};

export const getPxAgenteA1EndPoint = createAsyncThunk("puntajexAgente/fetchPxAgenteA1List",
  async ({idPersona, idPeriodo, idSubArea1}) => {
    const r = await fetchPxAgenteA1List({idPersona, idPeriodo, idSubArea1});
    return r;
  });

export const getPxAgenteA2EndPoint = createAsyncThunk("puntajexAgente/fetchPxAgenteA2List",
  async ({idPersona, idPeriodo, idSubArea2}) => {
    const r = await fetchPxAgenteA2List({idPersona, idPeriodo, idSubArea2});
    return r;
  });

export const puntajexAgenteSlice = createSlice({
  name: "puntajexAgente",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // getPxAgenteA1EndPoint
      .addCase(getPxAgenteA1EndPoint.pending, (state) => {
        state.loadingA1 = true;
        state.errorA1 = null;
      })
      .addCase(getPxAgenteA1EndPoint.fulfilled, (state, action) => {
        state.loadingA1 = false;
        state.entitiesA1 = action.payload;
      })
      .addCase(getPxAgenteA1EndPoint.rejected, (state, action) => {
        state.loadingA1 = false;
        //state.errorA1 = action.errorA1.message;
      })

      // getPxAgenteA2EndPoint
      .addCase(getPxAgenteA2EndPoint.pending, (state) => {
        state.loadingA2 = true;
        state.errorA2 = null;
      })
      .addCase(getPxAgenteA2EndPoint.fulfilled, (state, action) => {
        state.loadingA2 = false;
        state.entitiesA2 = action.payload;
      })
      .addCase(getPxAgenteA2EndPoint.rejected, (state, action) => {
        state.loadingA2 = false;
        //state.errorA2 = action.errorA2.message;
      })
  },
});

export const selectPuntajexAgenteA1 = (state) => state.puntajexAgente.entitiesA1;
export const selectPuntajexAgenteResponseA1 = (state) => state.puntajexAgente.responseA1;
/* export const selectIsLoadingA1 = (state) => state.puntajexAgente.loadingA1;
export const selectIsCreatingA1 = (state) => state.puntajexAgente.creatingA1;
export const selectIsEditingA1 = (state) => state.puntajexAgente.editingA1;
export const selectIsDeletingA1 = (state) => state.puntajexAgente.deletingA1; */
export const selectErrorA1 = (state) => state.puntajexAgente.errorA1;

export const selectPuntajexAgenteA2 = (state) => state.puntajexAgente.entitiesA2;
export const selectPuntajexAgenteResponseA2 = (state) => state.puntajexAgente.responseA2;
export const selectErrorA2 = (state) => state.puntajexAgente.errorA2;

export default puntajexAgenteSlice.reducer;
