import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCertificados, getBusquedaDniPersona } from './certificado.api';

// AsyncThunk para obtener los datos de una persona basada en el DNI
export const fetchPersonasDni = createAsyncThunk(
  'certificados/fetchPersonasDni',
  async (personaDni, {dispatch}) => {
      const r = await getBusquedaDniPersona(personaDni);
      const idPersonaFiltrada1 = r.data[0]?.idPersona;
      if (idPersonaFiltrada1){
        dispatch(fetchCertificados(idPersonaFiltrada1));    
      }
      return r;
    }
  );

// AsyncThunk para obtener certificados basado en el idpersonaFiltrada
export const fetchCertificados = createAsyncThunk(
  'certificados/fetchCertificados',
  async (idpersonaFiltrada1) => {
      const r = await getCertificados(idpersonaFiltrada1);
      return r;
  }
);

const certificadosSlice = createSlice({
  name: 'certificados',
  initialState: {
    certificados: [],
    dni: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificados.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCertificados.fulfilled, (state, action) => {
        state.loading = false;
        state.certificados = action.payload;
      })
      .addCase(fetchCertificados.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPersonasDni.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPersonasDni.fulfilled, (state, action) => {
        state.loading = false;
        state.dni = action.payload;
      })
      .addCase(fetchPersonasDni.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
// Selectores para acceder al estado en los componentes
export const selectCertificados = (state) => state.certificados.certificados;
export const selectDni = (state) => state.certificados.dni;
export const selectLoading = (state) => state.certificados.loading;
export const selectError = (state) => state.certificados.error;

export default certificadosSlice.reducer;
