import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { vsFiltroCompetencia } from "./vsCompetencia";
import React, { useState } from "react";
import { ClearIcon } from "@mui/x-date-pickers";
import NuevaCompetencia from "./Nuevo/NuevaCompetencia";
import { useAreaQuery } from "Hooks/Area/UseAreaQuery";
import { useSubAreaQuery } from "Hooks/SubArea/UseSubAreaQuery";

const CompetenciaFiltro = ({ onAdd, onSearch, filter }) => {
  const theme = useTheme();
  const [idArea, setIdArea] = useState(null);

  const areaQuery = useAreaQuery();
  const subAreaQuery = useSubAreaQuery(idArea);

  const handleAdd = () => {
    onAdd(true, true);
  };

  return (
    <>
      <Formik
        initialValues={{
          idArea: filter?.idArea ?? "",
          idSubArea: filter?.idSubarea ?? "",
          titulo: filter?.titulo ?? "",
          materia: filter?.materia ?? "",
        }}
        validationSchema={vsFiltroCompetencia}
        onSubmit={(e) => {
          onSearch(e);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Box
            component="form"
            sx={{
              mt: 1,
              [theme.breakpoints.up("lg")]: {
                mt: 1,
                pr: 35,
                pt: 3,
                pl: 2,
              },
              [theme.breakpoints.up("sm")]: {
                mt: 1,
              },
            }}
          >
            <Grid container spacing={2} sx={{ borderRadius: 0 }}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idSubArea", "");
                    setIdArea(value?.id);
                    setFieldValue("idArea", value?.id ?? "");
                    value?.id ?? submitForm();
                  }}
                  options={
                    (areaQuery?.data &&
                      areaQuery.data.map((option) => ({
                        id: option.idArea,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idArea"
                  value={
                    areaQuery?.data?.find(
                      (item) => item.idArea === values.idArea
                    )?.descripcion || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Área"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {areaQuery?.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errors.idArea) && touched.idArea}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idArea) &&
                        touched.idArea &&
                        errors.idArea
                      }
                    />
                  )}
                />
                {areaQuery.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idSubArea", value ? value.id : "");
                    value?.id ?? submitForm();
                  }}
                  options={
                    (subAreaQuery?.data?.length > 0 &&
                      subAreaQuery?.data?.map((option) => ({
                        id: option.idSubAreas,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idSubarea"
                  value={
                    (subAreaQuery?.data?.length > 0 &&
                      subAreaQuery?.data?.find(
                        (item) => item.idSubAreas === values.idSubArea
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Subarea"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {areaQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={
                        Boolean(values?.idArea) &&
                        Boolean(errors.idSubArea) &&
                        touched.idSubArea
                      }
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(values?.idArea) &&
                        Boolean(errors.idSubArea) &&
                        touched.idSubArea &&
                        errors.idSubArea
                      }
                    />
                  )}
                />
                {subAreaQuery.errors && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Título"
                    type="text"
                    name="titulo"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.titulo}
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.titulo && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("titulo", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="materia"
                    type="text"
                    name="materia"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    value={values.materia}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.materia && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("materia", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => submitForm()}
                    disabled={!(values.idArea && values.idSubArea)}
                  >
                    Buscar
                  </Button>
                  <NuevaCompetencia
                    data={{ area: values.idArea, subArea: values.idSubArea }}
                    disabled={!(values.idArea && values.idSubArea)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};
export default CompetenciaFiltro;
