import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchVariablesParam,
  fetchPeriodosxVariableParam,
  createVariableParam,
  createVariableParamxPer,
} from "./variablesParam.api";

const initialState = {
  entities: [],
  entitiesxVariable: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getVariablesParam = createAsyncThunk(
  "variablesParam/getVariablesParam",
  async (page) => {
    const r = await fetchVariablesParam(page);
    return r;
  }
);

export const getPeriodosxVariableParam = createAsyncThunk("variablesParam/getPeriodosxVariableParam",
  async (idVariableParam) => {
    const r = await fetchPeriodosxVariableParam(idVariableParam);
    //console.log('r en GET getPeriodosxVariableParam', r)
    return r;
  });

export const newVariableParam = createAsyncThunk("variablesParam/newVariableParam",
  async (data) => {
    const r = await createVariableParam(data);
    return r;
  }
);

export const newVariableParamxPer = createAsyncThunk("variablesParam/newVariableParamxPer",
  async (dato) => {
    const r = await createVariableParamxPer(dato);
    return r;
  }
);


export const VariablesParamSlice = createSlice({
  name: 'variablesParam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // GET
      .addCase(getVariablesParam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVariablesParam.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getVariablesParam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // GET
      .addCase(getPeriodosxVariableParam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPeriodosxVariableParam.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxVariable = action.payload;
      })
      .addCase(getPeriodosxVariableParam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // POST
      .addCase(newVariableParam.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newVariableParam.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newVariableParam.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })

      // POST
      .addCase(newVariableParamxPer.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newVariableParamxPer.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newVariableParamxPer.rejected, (state, action) => {
        state.creating = false;
        state.error = action.error.message;
      })
  },
});

export const selectVariablesParam = (state) => state.variablesParam.entities;
export const selectVariablesParamxPeriodo = (state) => state.variablesParam.entitiesxVariable;
export const selectResponseNewVariables = (state) => state.variablesParam.response;
export const selectIsLoading = (state) => state.variablesParam.loading;
export const selectIsCreating = (state) => state.variablesParam.creating;
export const selectIsEditing = (state) => state.variablesParam.editing;
export const selectIsDeleting = (state) => state.variablesParam.deleting;
export const selectError = (state) => state.variablesParam.error;

export default VariablesParamSlice.reducer;
