import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  Grid,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik, Form, Field } from "formik";
import dayjs from "dayjs";
import "dayjs/locale/es";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { vsInscGeneral } from "./InscripcionGeneralYup";
import { selectNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import { getEstadosInscGeneral, selectErrorInscGeneral, selectInscGeneralEstados } from "store/inscripcionGeneral/inscripcionGeneral.slice";
import { GetPeriodosxNivel, sPeriodosxNivel } from "store/periodos/periodos.slice";
import { getDeptosxTipoPeriodo, selectDeptosxTipoPer } from "store/Localidad/Localidad.slice";
import { getAllZonas, selectAllZonas } from "store/zona/zona.slice";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";

export default function InscGeneralForm({ data, onSubmit, onClose }) {
  //console.log('data para editar InscGeneralForm', data)

  dayjs.extend(customParseFormat);
  const dispatch = useDispatch();
  const [banderaNivel, setBanderaNivel] = useState(null);
  const [deptoxTipoPeriodo, setDeptoxTipoPeriodo] = useState(null);
  const getDesc_AllNiveles = useSelector(selectNivelesEnsenanza);
  const getDesc_AllPeriodosxN = useSelector(sPeriodosxNivel);
  const getDesc_AllZonas = useSelector(selectAllZonas);
  const getDeptosxTipoPer = useSelector(selectDeptosxTipoPer);
  const listaEstadosInscGeneral = useSelector(selectInscGeneralEstados);
  const inscGeneralError = useSelector(selectErrorInscGeneral);

  useEffect(() => {
    banderaNivel !== null && banderaNivel === "M" && dispatch(getAllZonas()); // Sólo Secundario.
  }, [dispatch, banderaNivel]);

  useEffect(() => {
    banderaNivel !== null && dispatch(GetPeriodosxNivel(banderaNivel)); // Primario o Secuncario.
  }, [dispatch, banderaNivel]);

  useEffect(() => {
    deptoxTipoPeriodo !== null &&
      dispatch(getDeptosxTipoPeriodo(deptoxTipoPeriodo)); // Sólo Primario.
  }, [dispatch, deptoxTipoPeriodo]);

  useEffect(() => {
    dispatch(getEstadosInscGeneral());
  }, [dispatch])


  const handleSubmit = (values) => {
    const datoAEnviar = {
      idInscGeneral: data?.idInscGeneral || 0,
      idNivelEnsenanza: values.idNivelEnsenanza,
      idPeriodo: values.idPeriodo,
      idZona: values.idZona,
      idDepartamento: values.idDepartamento,
      descripcion: (
        values.labelPeriodo +
        "- " +
        values.labelNivelEnsenanza +
        "- " +
        values.labelDepartamento +
        values.labelZona
      ).toUpperCase(),
      fechaInicioInsc: values.fechaInicioInsc,
      fechaFinInsc: values.fechaFinInsc,
      idEstadoInsc: values.idEstadoInsc,
      deshabilitado: 0,
    };
    //console.log('datoAEnviar en inscGeneralForm', datoAEnviar)
    onSubmit(datoAEnviar);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idNivelEnsenanza: data?.nivelEnsenanza?.idNivelEnsenanza || null,
          labelNivelEnsenanza: data?.nivelEnsenanza?.nivelEnsenanza || "",
          nivel: data?.nivelEnsenanza?.nivel || "",
          idPeriodo: data?.periodo?.idPeriodo || null,
          labelPeriodo: data?.periodo?.descripcion || "",
          idTipoPeriodo: data?.periodo?.idTipoPeriodo || null,
          fechaIniPeriodo: data?.periodo?.fechaInicio
            ? dayjs(data?.periodo?.fechaInicio)
            : null,
          fechaIniPeriodoMenos1: data?.periodo?.fechaInicio // Para que me tome también el día de Inicio del Período.
            ? dayjs(data?.periodo?.fechaInicio) // Ver el combo del formulario para entender.
            : null,
          fechaFinPeriodo: data?.periodo?.fechaFin
            ? dayjs(data?.periodo?.fechaFin)
            : null,
          fechaFinPeriodoMas1: data?.periodo?.fechaFin // Para que me tome también el día de Fin del Período.
            ? dayjs(
                new Date(data?.periodo?.fechaFin).setDate(
                  new Date(data?.periodo?.fechaFin).getDate() + 1
                )
              )
            : null,
          idZona: data?.zona?.idZona || null,
          labelZona: data?.zona?.descripcion || "",
          idDepartamento: data?.departamento?.idDepartamento || null,
          labelDepartamento: data?.departamento?.nombre || "",
          fechaInicioInsc: data?.fechaInicioInsc
            ? dayjs(data?.fechaInicioInsc)
            : null,
          fechaFinInsc: data?.fechaFinInsc ? dayjs(data?.fechaFinInsc) : null,
          idEstadoInsc: data?.estadoInscripcionGeneral?.idEstadoInsc || 1, // Por defecto Inscripción "ABIERTA".
        }}
        validationSchema={vsInscGeneral}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          resetForm,
          dirty,
        }) => (
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ paddingTop: "0px" }}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(errors.idNivelEnsenanza) && touched.idNivelEnsenanza
                  }
                >
                  <Form>
                    {data ? (
                      <Field name="idNivelEnsenanza">
                        {({ field }) => (
                          <Autocomplete
                            disabled
                            onChange={(e, value) => {
                              setFieldValue(
                                "idNivelEnsenanza",
                                value ? value.id : null
                              );
                              setFieldValue(
                                "labelNivelEnsenanza",
                                value ? value.label : null
                              );
                              setFieldValue(
                                "nivel",
                                value ? value.nivel : null
                              );
                            }}
                            options={
                              (getDesc_AllNiveles?.records &&
                                getDesc_AllNiveles?.records
                                  .filter(
                                    (x) =>
                                      x.nivelEnsenanza === "PRIMARIO" ||
                                      x.nivelEnsenanza === "SECUNDARIO"
                                  )
                                  .map((option) => ({
                                    id: option.idNivelEnsenanza,
                                    label: option.nivelEnsenanza,
                                    nivel: option.nivel,
                                  }))) ||
                              []
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              );
                            }}
                            name="idNivelEnsenanza"
                            value={
                              getDesc_AllNiveles?.records?.find(
                                (item) =>
                                  item.idNivelEnsenanza ===
                                  values.idNivelEnsenanza
                              )?.nivelEnsenanza || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Nivel de Enseñanza"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                                error={
                                  Boolean(errors.idNivelEnsenanza) &&
                                  touched.idNivelEnsenanza
                                }
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                }}
                                helperText={
                                  Boolean(errors.idNivelEnsenanza) &&
                                  touched.idNivelEnsenanza &&
                                  errors.idNivelEnsenanza
                                }
                              />
                            )}
                          >
                            {/* Pregunto si ya se seleccionó un nivel */}
                            {values?.idNivelEnsenanza !== null &&
                              setBanderaNivel(values.nivel)}
                          </Autocomplete>
                        )}
                      </Field>
                    ) : (
                      <Field name="idNivelEnsenanza">
                        {({ field }) => (
                          <Autocomplete
                            onChange={(e, value) => {
                              resetForm(); // Iniciamos reiniciando los campos.
                              setFieldValue(
                                "idNivelEnsenanza",
                                value ? value.id : null
                              );
                              setFieldValue(
                                "labelNivelEnsenanza",
                                value ? value.label : null
                              );
                              setFieldValue(
                                "nivel",
                                value ? value.nivel : null
                              );
                            }}
                            options={
                              (getDesc_AllNiveles?.records &&
                                getDesc_AllNiveles.records
                                  .filter(
                                    (x) =>
                                      x.nivelEnsenanza === "PRIMARIO" ||
                                      x.nivelEnsenanza === "SECUNDARIO"
                                  )
                                  .map((option) => ({
                                    id: option.idNivelEnsenanza,
                                    label: option.nivelEnsenanza,
                                    nivel: option.nivel,
                                  }))) ||
                              []
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              );
                            }}
                            name="idNivelEnsenanza"
                            value={
                              getDesc_AllNiveles?.records?.find(
                                (item) =>
                                  item.idNivelEnsenanza ===
                                  values.idNivelEnsenanza
                              )?.nivelEnsenanza || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Nivel de Enseñanza"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                                error={
                                  Boolean(errors.idNivelEnsenanza) &&
                                  touched.idNivelEnsenanza
                                }
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                }}
                                helperText={
                                  Boolean(errors.idNivelEnsenanza) &&
                                  touched.idNivelEnsenanza &&
                                  errors.idNivelEnsenanza
                                }
                              />
                            )}
                          >
                            {/* Pregunto si ya se seleccionó un nivel */}
                            {values?.idNivelEnsenanza !== null &&
                              setBanderaNivel(values.nivel)}
                          </Autocomplete>
                        )}
                      </Field>
                    )}
                  </Form>
                  {inscGeneralError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: "0px" }}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idPeriodo) && touched.idPeriodo}
                >
                  <Form>
                    <Field name="idPeriodo">
                      {({ field }) => (
                        <Autocomplete
                          onChange={(e, value) => {
                            setFieldValue("idPeriodo", value ? value.id : null);
                            setFieldValue(
                              "labelPeriodo",
                              value ? value.label : null
                            );
                            setFieldValue(
                              "idTipoPeriodo",
                              value ? value.idTipoPeriodo : null
                            );
                            setFieldValue(
                              "fechaIniPeriodo",
                              value ? dayjs(value.fechaIniPeriodo) : null
                            );
                            setFieldValue(
                              "fechaIniPeriodoMenos1",
                              value // Para que me tome también el día de Inicio.
                                ? dayjs(
                                    new Date(value.fechaIniPeriodo).setDate(
                                      new Date(
                                        value.fechaIniPeriodo
                                      ).getDate() - 1
                                    )
                                  )
                                : null
                            );
                            setFieldValue(
                              "fechaFinPeriodo",
                              value ? dayjs(value.fechaFinPeriodo) : null
                            );
                            setFieldValue(
                              "fechaFinPeriodoMas1",
                              value // Para que me tome también el día de Fin.
                                ? dayjs(value.fechaFinPeriodo)
                                : null
                            );
                            setFieldValue("idDepartamento", null); // Reiniciamos el Departamento.
                            setFieldValue("labelDepartamento", "");
                          }}
                          options={
                            (getDesc_AllPeriodosxN?.data &&
                              getDesc_AllPeriodosxN?.data.map((option) => ({
                                id: option.idPeriodo,
                                label: option.descripcion,
                                labelVista:
                                  option.descripcion +
                                  " - ( FI: " +
                                  formatDDMMYY(option.fechaInicio) +
                                  "- FF: " +
                                  formatDDMMYY(option.fechaFin) +
                                  " )",
                                idTipoPeriodo: option.tipoPeriodo.idTipoPeriodo,
                                fechaIniPeriodo: dayjs(option.fechaInicio),
                                fechaFinPeriodo: dayjs(option.fechaFin),
                              }))) ||
                            []
                          }
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.labelVista || ""}
                              </li>
                            );
                          }}
                          name="idPeriodo"
                          value={
                            (values?.idPeriodo &&
                              getDesc_AllPeriodosxN?.data?.find(
                                (item) => item.idPeriodo === values.idPeriodo
                              )?.descripcion +
                                " - ( FI: " +
                                formatDDMMYY(
                                  getDesc_AllPeriodosxN?.data?.find(
                                    (item) =>
                                      item.idPeriodo === values.idPeriodo
                                  )?.fechaInicio
                                ) +
                                "- FF: " +
                                formatDDMMYY(
                                  getDesc_AllPeriodosxN?.data?.find(
                                    (item) =>
                                      item.idPeriodo === values.idPeriodo
                                  )?.fechaFin
                                ) +
                                " )") ||
                            null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Período"
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              error={
                                Boolean(errors.idPeriodo) && touched.idPeriodo
                              }
                              sx={{
                                "input[type='search']::-webkit-search-cancel-button":
                                  {
                                    display: "none",
                                  },
                              }}
                              helperText={
                                Boolean(errors.idPeriodo) &&
                                touched.idPeriodo &&
                                errors.idPeriodo
                              }
                            />
                          )}
                        >
                          {values?.idTipoPeriodo !== 0 &&
                            values?.nivel === "P" &&
                            setDeptoxTipoPeriodo(values.idTipoPeriodo)}
                        </Autocomplete>
                      )}
                    </Field>
                  </Form>
                  {Boolean(errors?.periodo?.idPeriodo) &&
                    touched?.periodo?.idPeriodo && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                  {inscGeneralError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {values.nivel === "M" && ( // Sólo para Secundario.
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.idZona) && touched.idZona}
                  >
                    <Form>
                      <Field name="idZona">
                        {({ field }) => (
                          <Autocomplete
                            onChange={(e, value) => {
                              setFieldValue("idZona", value ? value.id : null);
                              setFieldValue(
                                "labelZona",
                                value ? value.label : null
                              );
                            }}
                            options={
                              (getDesc_AllZonas?.records &&
                                getDesc_AllZonas?.records.map((option) => ({
                                  id: option.idZona,
                                  label: option.descripcion,
                                }))) ||
                              []
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              );
                            }}
                            name="idZona"
                            value={
                              getDesc_AllZonas?.records?.find(
                                (item) => item.idZona === values.idZona
                              )?.descripcion || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Zona"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                                error={Boolean(errors.idZona) && touched.idZona}
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                }}
                                helperText={
                                  Boolean(errors.idZona) &&
                                  touched.idZona &&
                                  errors.idZona
                                }
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Form>
                    {Boolean(errors.idZona) && touched.idZona && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Este campo es obligatorio
                      </FormHelperText>
                    )}
                    {inscGeneralError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              {values.nivel === "P" && ( // Sólo para Primario.
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <FormControl
                    fullWidth
                    error={
                      Boolean(errors.idDepartamento) && touched.idDepartamento
                    }
                  >
                    <Form>
                      <Field name="idDepartamento">
                        {({ field }) => (
                          <Autocomplete
                            onChange={(e, value) => {
                              setFieldValue(
                                "idDepartamento",
                                value ? value.id : null
                              );
                              setFieldValue(
                                "labelDepartamento",
                                value ? value.label : null
                              );
                            }}
                            options={
                              (getDeptosxTipoPer?.data &&
                                getDeptosxTipoPer?.data.map((option) => ({
                                  id: option.idDepartamento,
                                  label: option.nombre,
                                }))) ||
                              []
                            }
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.label}
                                </li>
                              );
                            }}
                            name="idDepartamento"
                            value={
                              getDeptosxTipoPer?.data?.find(
                                (item) =>
                                  item.idDepartamento === values.idDepartamento
                              )?.nombre || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Departamento"
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                                error={
                                  Boolean(errors.idDepartamento) &&
                                  touched.idDepartamento
                                }
                                sx={{
                                  "input[type='search']::-webkit-search-cancel-button":
                                    {
                                      display: "none",
                                    },
                                }}
                                helperText={
                                  Boolean(errors.idDepartamento) &&
                                  touched.idDepartamento &&
                                  errors.idDepartamento
                                }
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Form>
                    {Boolean(errors.idDepartamento) &&
                      touched.idDepartamento && (
                        <FormHelperText
                          style={{
                            color: "#d32f2f",
                          }}
                        >
                          Este campo es obligatorio
                        </FormHelperText>
                      )}
                    {inscGeneralError && (
                      <FormHelperText
                        style={{
                          color: "#d32f2f",
                        }}
                      >
                        Ocurrió un problema al recuperar este dato
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaInicioInsc"
                    value={values.fechaInicioInsc}
                    onChange={(value) =>
                      setFieldValue("fechaInicioInsc", value)
                    }
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaInicioInsc) &&
                          touched.fechaInicioInsc &&
                          errors.fechaInicioInsc,
                        error:
                          Boolean(errors.fechaInicioInsc) &&
                          Boolean(touched.fechaInicioInsc),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaFinInsc"
                    value={values.fechaFinInsc}
                    onChange={(value) => setFieldValue("fechaFinInsc", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaFinInsc) &&
                          touched.fechaFinInsc &&
                          errors.fechaFinInsc,
                        error:
                          Boolean(errors.fechaFinInsc) &&
                          Boolean(touched.fechaFinInsc),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.idEstadoInsc) && touched.idEstadoInsc}
                >
                  <InputLabel id="idEstadoInsc">Estado</InputLabel>
                  {data ? (
                    <Select
                      labelId="idEstadoInsc"
                      id="idEstadoInsc"
                      label="Estado"
                      name="idEstadoInsc"
                      value={values?.idEstadoInsc} // Absorbe el valor que tenga en la DB.
                      onChange={handleChange}
                      onSelect={handleChange}
                      error={
                        Boolean(errors.idEstadoInsc) && touched.idEstadoInsc
                      }
                    >
                      {listaEstadosInscGeneral?.records?.map((x) => (
                        <MenuItem
                          key={x.idEstadoInsc}
                          value={x.idEstadoInsc}
                        >
                          {x.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      disabled
                      labelId="idEstadoInsc"
                      id="idEstadoInsc"
                      label="Estado"
                      name="idEstadoInsc"
                      defaultValue="1" // Por defecto "ABIERTA" en el CREATE (POST).
                      onChange={handleChange}
                      onSelect={handleChange}
                      error={
                        Boolean(errors.idEstadoInsc) && touched.idEstadoInsc
                      }
                    >
                      {listaEstadosInscGeneral?.records?.map((x) => (
                        <MenuItem
                          key={x.idEstadoInsc}
                          value={x.idEstadoInsc}
                        >
                          {x.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {Boolean(errors.idEstadoInsc) && touched.idEstadoInsc && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                  {inscGeneralError && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Ocurrió un problema al recuperar este dato
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "8px" }}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    type="text"
                    id="descripcion"
                    label="Descripción Inscripción General"
                    name="descripcion"
                    autoFocus
                    value={
                      values.labelNivelEnsenanza
                        ? (
                            values.labelPeriodo +
                            "- " +
                            values.labelNivelEnsenanza +
                            "- " +
                            values.labelDepartamento +
                            values.labelZona
                          ).toUpperCase()
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              {/* {console.log('values en render', values)} */}

            </Grid>

            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
