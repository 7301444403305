import { useQuery } from "react-query";
import { fetchMaterias } from "store/materias/materias.api";

export const useMateriaQuery = () => {
  const materiasQuery = useQuery({
    queryKey: ["materiaQuery"],
    queryFn: async () => {
      const { data } = await fetchMaterias(0);
      return data?.records
        ? data?.records.map((option) => ({
            id: option.idMateria,
            label: `${option.idMateria} - ${option.descripcion}`,
          }))
        : [];
    },
  });

  return materiasQuery;
};
