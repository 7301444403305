import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditarInscGeneral from "../Modal/Editar/InscripcionGeneralEditar";
import EliminarInscGeneral from "../Modal/Eliminar/InscripcionGeneralEliminar";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";

const InscGeneralTable = ({ listaInscGeneral }) => {
  //console.log('listaInscGeneral en table inscGeneral', listaInscGeneral)

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Nivel de Enseñanza</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Zona/Depo</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Estado</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Fecha Inicio</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Fecha Fin</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        {/* */}
        <TableBody>
          {listaInscGeneral?.length > 0 ? (
            listaInscGeneral?.map((inscGeneral) => (
              <TableRow key={inscGeneral.idInscGeneral}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {inscGeneral.idInscGeneral}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {inscGeneral.nivelEnsenanza.nivelEnsenanza}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {inscGeneral.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {inscGeneral.zona?.descripcion ?? ""}
                  {inscGeneral.departamento?.nombre ?? ""}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {inscGeneral.estadoInscripcionGeneral.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {formatDDMMYY(inscGeneral.fechaInicioInsc)}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {formatDDMMYY(inscGeneral.fechaFinInsc)}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  <EditarInscGeneral inscGeneral={inscGeneral} />
                  <EliminarInscGeneral inscGeneral={inscGeneral} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default InscGeneralTable;
