import * as Yup from "yup";

export const vsPeriodo = Yup.object({
  idTipoPeriodo: Yup.string().required("Este campo es obligatorio").nullable(),
  fechaInicio: Yup.date()
    .typeError("Ingrese una fecha valida")
    .required("Este campo es obligatorio"),
  fechaFin: Yup.date()
    .typeError("Ingrese una fecha valida")
    .required("Este campo es obligatorio")
    .min(Yup.ref("fechaInicio"), "Ingrese una fecha de inicio valida"),
});