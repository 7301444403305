import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";


const VariablesParamxPerTable = ({ variablesParamxPer }) => {

  //console.log('variablesParamxPer en table de varxPer', variablesParamxPer)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell style={{ textAlign: "center" }}>Parámetro 1</TableCell>
            <TableCell style={{ textAlign: "center" }}>Parámetro 2</TableCell>
            <TableCell style={{ textAlign: "center" }}>Fecha Inicio</TableCell>
            <TableCell style={{ textAlign: "center" }}>Fecha Fin</TableCell>
            <TableCell>Valor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {variablesParamxPer?.data?.map(
            (
              varParamxPer
            ) => (
              <TableRow
                key={varParamxPer.idVarparxper}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{varParamxPer.idVarparxper}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{varParamxPer.parametro1}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{varParamxPer.parametro2 ?? "NULL"}</TableCell>
                <TableCell style={{ textAlign: "center" }}>{formatDDMMYY(varParamxPer.fecha1)}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {varParamxPer.fecha2 ? formatDDMMYY(varParamxPer.fecha2) : "NULL"}
                </TableCell>
                <TableCell>{varParamxPer.valor ?? 0}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VariablesParamxPerTable;
