import { useQuery } from "react-query";
import { fetchSubareasByIdconDefault } from "store/subareas/subareas.api";

export const useSubAreaQuery = (idArea) => {
  const subAreaQuery = useQuery(
    {
      queryKey: ["SubAreasQuery", idArea],
      queryFn: async () => {
        if (!!idArea) {
          const data = await fetchSubareasByIdconDefault(idArea);
          return data;
        }
        return { data: [] };
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return subAreaQuery;
};
