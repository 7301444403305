import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchPeriodo_ByTipoPeriodo,
  fetchPeriodo,
  fetchPeriodos,
  fetchPeriodosxNivel,
  fetchPeriodosSinPaginado,
  createPeriodo,
  updatePeriodo,
  disabledPeriodo,
  searchPeriodo,
} from "./periodos.api";

const initialState = {
  entities: [],
  entitiesxNivel: [],
  entitiesSinPaginado: [],
  entity: null,
  periodosxTipo: [],
  response: null,
  error: null,
  loading: false,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const GetPeriodo_ByTipoPeriodo = createAsyncThunk(
  "periodo/GetPeriodo_ByTipoPeriodo",
  async (pIdTipoPeriodo) => {
    const r = await fetchPeriodo_ByTipoPeriodo(pIdTipoPeriodo);
    return r;
  }
);

export const GetPeriodos = createAsyncThunk(
  "periodo/GetPeriodos",
  async (page, { dispatch }) => {
    const r = await fetchPeriodos(page);
    return r;
  }
);

export const GetPeriodosxNivel = createAsyncThunk(
  "periodo/fetchPeriodosxNivel",
  async (pNivel) => {
    const r = await fetchPeriodosxNivel(pNivel);
    return r;
  }
);

export const GetPeriodosSinPaginado = createAsyncThunk(
  "periodo/GetPeriodosSinPaginado",
  async () => {
    const r = await fetchPeriodosSinPaginado();
    return r;
  }
);

export const GetPeriodo = createAsyncThunk("periodo/GetPeriodo", async (id) => {
  const r = await fetchPeriodo(id);
  return r;
});

export const PostPeriodo = createAsyncThunk(
  "periodo/PostPeriodo",
  async ({ data }) => {
    const r = await createPeriodo(data);
    return r;
  }
);

export const PutPeriodo = createAsyncThunk(
  "periodo/PutPeriodo",
  async ({ data }) => {
    const r = await updatePeriodo(data);
    return r;
  }
);

export const GetSearchPeriodo = createAsyncThunk(
  "periodo/filterPeriodo",
  async ({ page = 1, data = "" }) => {
    const r = await searchPeriodo(page, data);
    return r;
  }
);

export const DisabledPeriodo = createAsyncThunk(
  "periodo/DisabledPeriodo",
  async ({ data }) => {
    const r = await disabledPeriodo(data);
    return r;
  }
);

export const PeriodoSlice = createSlice({
  name: "periodo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region GetPeriodo_ByTipoPeriodo
      .addCase(GetPeriodo_ByTipoPeriodo.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodo_ByTipoPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.periodosxTipo = action.payload;
      })
      .addCase(GetPeriodo_ByTipoPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error.message;
      })
      //#endregion
      //#region GetPeriodos
      .addCase(GetPeriodos.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodos.fulfilled, (state, action) => {
        state.entities = action.payload.data;
        state.loading = false;
      })
      .addCase(GetPeriodos.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetPeriodosxNivel
      .addCase(GetPeriodosxNivel.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodosxNivel.fulfilled, (state, action) => {
        state.entitiesxNivel = action.payload;
        state.loading = false;
      })
      .addCase(GetPeriodosxNivel.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetPeriodosSinPaginado
      .addCase(GetPeriodosSinPaginado.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodosSinPaginado.fulfilled, (state, action) => {
        state.entitiesSinPaginado = action.payload;
        state.loading = false;
      })
      .addCase(GetPeriodosSinPaginado.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetPeriodo
      .addCase(GetPeriodo.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetPeriodo.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addCase(GetPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region PostPeriodo
      .addCase(PostPeriodo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PostPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(PostPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region PutPeriodo
      .addCase(PutPeriodo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(PutPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(PutPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region DeletePeriodo
      .addCase(DisabledPeriodo.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(DisabledPeriodo.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(DisabledPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region GetSearchPeriodo
      .addCase(GetSearchPeriodo.pending, (state) => {
        state.loading = true;
        state.response = null;
      })
      .addCase(GetSearchPeriodo.fulfilled, (state, action) => {
        state.entities = action.payload.data;
        state.loading = false;
      })
      .addCase(GetSearchPeriodo.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
    //#endregion
  },
});

export const sPeriodos = (state) => state.periodo.entities;
export const sPeriodosxNivel = (state) => state.periodo.entitiesxNivel;
export const sPeriodosSinPaginado = (state) => state.periodo.entitiesSinPaginado;
export const sResponse = (state) => state.periodo.response;
export const sError = (state) => state.periodo.error;
export const sPeriodosxTipo = (state) => state.periodo.periodosxTipo;
export const sPeriodo = (state) => state.periodo.entity;
export const sIsLoading = (state) => state.periodo.loading;

export default PeriodoSlice.reducer;
