import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";
//import { formatDDMMYY } from "utils/helpers/dateFormat";

export default function PuntajexAgenteTable({ datosAgente }) {

    var sumaTotal = 0;
    function funcSuma(valorASumar) {
        sumaTotal = sumaTotal + valorASumar
    }


    function descripcionVariable(PuntxAgente) {
        if (PuntxAgente.des_Variable !== null) {
            return PuntxAgente.des_Variable
        }
        else if (PuntxAgente.des_VariableParam !== null) {
            return PuntxAgente.des_VariableParam
        } else {
            return "ERROR"
        }
    }

    function funcionArea(datosAgente) {
        if (datosAgente[0].des_Area === datosAgente[0].des_SubArea) {
            return `ÁREA: ${datosAgente[0].des_Area.toUpperCase()}`
        } else {
            return `ÁREA: ${datosAgente[0].des_Area.toUpperCase()} - SUBÁREA: ${datosAgente[0].des_SubArea.toUpperCase()}`
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
                <TableContainer component={Paper} style={{ marginBottom: "40px" }} >
                    <Table style={{ fontWeight: "bold", textAlign: "center", background: "rgb(25,118,210,0.6)" }}>
                        {funcionArea(datosAgente)}
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>ID</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Tipo Puntaje</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Variable</TableCell>
                                <TableCell style={{ fontWeight: "bold", textAlign: "Right" }}>Valor</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {datosAgente?.map(PuntxAgente => (
                                <TableRow
                                    key={PuntxAgente.idPuntajeDetalle}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell >{PuntxAgente.idPuntajeDetalle}</TableCell>
                                    <TableCell >{PuntxAgente.des_TipoPuntaje}</TableCell>
                                    <TableCell >{descripcionVariable(PuntxAgente)}</TableCell>
                                    <TableCell style={{ textAlign: "Right" }}>
                                        {PuntxAgente.valor.toFixed(2)}
                                        {funcSuma(PuntxAgente.valor)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Table style={{ fontWeight: "bold", textAlign: "center", background: "rgb(25,118,210,0.6)" }}>
                        PUNTAJE TOTAL POR ÁREA: {sumaTotal.toFixed(2)}
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}