export const SnackBarMessageResponse = (response) => {
  console.log("response en Snackbar", response);
  if (response !== null && response !== undefined) {
    if (response.message[0].status < 202) {
      return `200: ${response.message[0].description}`;
    } else if (response.message[0].status > 399) {
      return `500: ${response.message[0].description}`;
    } else {
      return `202: ${response.message[0].description}`;
    }
  }
};
