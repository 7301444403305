import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { removeSubarea, getSubareasByID } from '../../../../store/subareas/subareas.slice';


export default function SubareaEliminar({area, subarea}) {

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(removeSubarea(subarea.idSubAreas)); 
    setTimeout(() => {
      dispatch(getSubareasByID(area?.idArea));        // Actualizo la tabla de Subáreas.
    }, 400);
  };   

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar Subárea </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar la Subárea <b> "{subarea.descripcion}" </b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
