import { request } from 'utils/helpers/request.helpers';

const loginUrl = `${process.env.REACT_APP_API_URL}/api/cuenta/loginSage1`;

export function loginApi(Username, Password) {
  return request(`${loginUrl}?Username=${Username}&Password=${Password}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify( Username,  Password ),
  });
}
