import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoriasFetch } from "./categorias.api";

const initialState = {
  entities: [], // La entrada original es un objeto {}.
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

export const getCategorias = createAsyncThunk(
  "categorias/getCategorias",
  async () => {
    const r = await getCategoriasFetch();
    return r;
  }
);

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const CategoriasSlice = createSlice({
  name: "categorias",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategorias.pending, (state) => {
        // pendiente.
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategorias.fulfilled, (state, action) => {
        // cumplida.
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getCategorias.rejected, (state, action) => {
        // rechazado.
        state.loading = false;
        state.error = action.error?.stack
          ? errorMessage
          : action?.error?.message;
      });
  },
});

export const selectCategorias = (state) => state.categorias.entities;
export const selectIsLoading = (state) => state.categorias.loading;
export const selectIsError = (state) => state.categorias.error;

export default CategoriasSlice.reducer;
