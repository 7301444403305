import React from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { getSubareasByID } from "../../../../store/subareas/subareas.slice";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import { vsSubarea } from "./YupSubArea";

export default function SubareaForm({ area, data, onSubmit, onClose }) {
  const dispatch = useDispatch();
  console.log({ data });
  const handleSubmit = (subarea) => {
    onSubmit(subarea);
    setTimeout(() => {
      dispatch(getSubareasByID(area?.idArea)); // Luego de la "Edición", refresco la página.
    }, 400);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <Formik
      initialValues={{
        idSubAreas: data?.idSubAreas || null,
        codigoSubArea: data?.codigoSubArea || "",
        descripcion: data?.descripcion || "",
      }}
      validationSchema={vsSubarea}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched, submitForm, dirty }) => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} md={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="codigoSubArea"
                  label="Código"
                  name="codigoSubArea"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.codigoSubArea}
                  error={Boolean(errors.codigoSubArea && touched.codigoSubArea)}
                  helperText={
                    Boolean(errors.codigoSubArea) &&
                    touched.codigoSubArea &&
                    errors.codigoSubArea
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={10} md={7}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.descripcion}
                  error={Boolean(errors.descripcion && touched.descripcion)}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Stack
            spacing={2}
            direction="row"
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClose()}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
