import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SubareasModificar from "../SubareasModificar/SubareasModificar";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import CustomTableContainer from "components/customTable/CustomTableContainer";

const AreasSubareasTable = ({ areas }) => {
  // Mostramos las Áreas, aunque no se pueden editar las mismas.
  // Usuario debe seleccionar una subarea.
  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>Código de Área</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción de Área</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas?.records?.length > 0 ? (
            areas?.records?.map(
              (
                area // Tabla de Áreas!!!
              ) => (
                <TableRow key={area.idArea}>
                  <TableCell sx={{ ...tableBodyCell }}>{area.codigo}</TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {area.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <SubareasModificar area={area} />{" "}
                    {/* Edición de Subárea, acorde al área Elegida */}
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default AreasSubareasTable;
