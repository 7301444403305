import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch } from 'react-redux';
import { deshabilitarInscPersona, getInscxPersona } from 'store/inscripcionesxPersona/inscripcionesxPersona.slice';
import { InscxPersonaContext } from '../../InscripcionesxPersonaPage/InscripcionesxPersonaPage';


export default function InscripcionesxPersonaEliminar({ datosInscPersona }) {

  //console.log('InscripcionesxPersonaEliminar', datosInscPersona)

  const [open, setOpen] = React.useState(false);
  const pageAndSearch = React.useContext(InscxPersonaContext);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(deshabilitarInscPersona(datosInscPersona.idInscripcionPersona));
    setTimeout(() => {
      dispatch(getInscxPersona(pageAndSearch));
    }, 400);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle> Eliminar asignación </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro/a que deseas eliminar la inscripción
            <b> "{datosInscPersona?.inscripcionGeneral?.descripcion}" </b>
            a {datosInscPersona?.persona?.apellidoyNombre} para la subárea <b>{datosInscPersona?.subAreas?.descripcion}</b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}