import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { ClearIcon } from "@mui/x-date-pickers";
import NuevoItems from "./nuevo/NuevoItems";
import { useTipoItemPuntuacionQuery } from "Hooks/ItemsPuntuacion/useTipoItemPuntuacionQuery";

const ItemsPuntuacionFiltro = ({ onSearch, filter }) => {
  const theme = useTheme();
  const tipoItemPuntuacionQuery = useTipoItemPuntuacionQuery();
 
  return (
    <>
      <Formik
        initialValues={{
          idTipo: filter?.idTipo ?? "",
          codigo: filter?.codigo ?? "",
          desc: filter?.desc ?? "",
        }}
        onSubmit={(e) => {
          onSearch(e);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Box
            component="form"
            sx={{
              mt: 1,
              [theme.breakpoints.up("lg")]: {
                mt: 1,
                pr: 35,
                pt: 3,
                pl: 2,
              },
              [theme.breakpoints.up("sm")]: {
                mt: 1,
              },
            }}
          >
            <Grid container spacing={2} sx={{ borderRadius: 0 }}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idTipo", value?.id ?? "");
                    value?.id ?? submitForm();
                  }}
                  options={
                    (tipoItemPuntuacionQuery?.data &&
                      tipoItemPuntuacionQuery?.data.map((option) => ({
                        id: option.idTipoItemsDePuntuacion,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idTipo"
                  value={
                    tipoItemPuntuacionQuery?.data?.find(
                      (item) => item.idTipoItemsDePuntuacion === values.idTipo
                    )?.descripcion || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de item de puntuación"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {tipoItemPuntuacionQuery?.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errors.idTipo) && touched.idTipo}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idTipo) &&
                        touched.idTipo &&
                        errors.idTipo
                      }
                    />
                  )}
                />
                {tipoItemPuntuacionQuery.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Código"
                    type="text"
                    name="codigo"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.codigo}
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.codigo && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("codigo", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Descripción"
                    type="text"
                    name="desc"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    autoComplete="off"
                    onKeyDown={(event) => {
                      if (
                        event.code === "Enter" ||
                        event.code === "NumpadEnter"
                      ) {
                        submitForm();
                      }
                    }}
                    value={values.desc}
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.materia && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("desc", "");
                                submitForm();
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => submitForm()}
                  >
                    Buscar
                  </Button>
                  <NuevoItems />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};
export default ItemsPuntuacionFiltro;
