import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  fetchInscxPersona,
  createInscripcionxPersona,
  updateInscripcionxPersona,
  deshabilitarInscxPersona,
  /* searchTitle, */
} from "./inscripcionesxPersona.api";

const initialState = {
  entitiesxPer: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};


export const getInscxPersona = createAsyncThunk(
  "inscripcionesxPersona/getInscxPersona",
  async ({ pBusqueda, page, pId_Persona }) => {
    const r = await fetchInscxPersona(pBusqueda, page, pId_Persona);
    return r;
  }
);

export const newInscripcionxPersona = createAsyncThunk(
  "titulos/newInscripcionxPersona",
  async (data) => {
    const r = await createInscripcionxPersona(data);
    return r;
  }
);

export const editInscripcionxPersona = createAsyncThunk(
  "inscripcionesxPersona/updateInscripcionxPersona",
  async ({ data }) => {
    const r = await updateInscripcionxPersona({ data });
    return r;
  }
);

export const deshabilitarInscPersona = createAsyncThunk(
  "inscripcionesxPersona/deshabilitarInscxPersona",
  async (pIdInscGeneral) => {
    const r = await deshabilitarInscxPersona(pIdInscGeneral);
    return r;
  }
);

export const clearInscxPersona = createAction("entitiesxPer/clearInscxPersona");

export const InscripcionesSlice = createSlice({
  name: "inscripcionesxPersona",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getInscxPersona.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInscxPersona.fulfilled, (state, action) => {
        state.loading = false;
        state.entitiesxPer = action.payload;
      })
      .addCase(getInscxPersona.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(newInscripcionxPersona.pending, (state) => {
        state.creating = true;
        state.error = null;
      })
      .addCase(newInscripcionxPersona.fulfilled, (state, action) => {
        state.creating = false;
        state.response = action.payload;
      })
      .addCase(newInscripcionxPersona.rejected, (state, action) => {
        state.creating = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(editInscripcionxPersona.pending, (state) => {
        state.editing = true;
        state.error = null;
      })
      .addCase(editInscripcionxPersona.fulfilled, (state, action) => {
        state.editing = false;
        state.response = action.payload;
      })
      .addCase(editInscripcionxPersona.rejected, (state, action) => {
        state.editing = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(deshabilitarInscPersona.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(deshabilitarInscPersona.fulfilled, (state, action) => {
        state.deleting = false;
        state.response = action.payload;
      })
      .addCase(deshabilitarInscPersona.rejected, (state, action) => {
        state.deleting = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#region clearInscxPersona
      .addCase(clearInscxPersona, (state) => {
        state.entitiesxPer = []; // Vacía el array entitiesxPer
      });
    //#endregion
  },
});

export const selectInscxPersona = (state) => state.inscripcionesxPersona.entitiesxPer;
export const selectResponseInscxPer = (state) => state.inscripcionesxPersona.response;
export const selectIsLoadingInscxPer = (state) => state.inscripcionesxPersona.loading;
export const selectIsCreatingInscxPer = (state) => state.inscripcionesxPersona.creating;
export const selectIsEditingInscxPer = (state) => state.inscripcionesxPersona.editing;
export const selectIsDeletingInscxPer = (state) => state.inscripcionesxPersona.deleting;
export const selectErrorInscxPer = (state) => state.inscripcionesxPersona.error;

export default InscripcionesSlice.reducer;
