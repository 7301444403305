import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";
import InscripcionesxPersonaEditar from "../Modal/InscripcionesxPersonaEditar/InscripcionesxPersonaEditar";
import InscripcionesxPersonaEliminar from "../Modal/InscripcionesxPersonaEliminar/InscripcionesxPersonaEliminar";
import InscripcionesxPersonaNuevo from "../Modal/InscripcionesxPersonaNuevo/InscripcionesxPersonaNuevo";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";


export default function InscripcionesxPersonaTable({ datosInscPersona, persona }) {

  //console.log('datosInscPersona en tabla', datosInscPersona)

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper} style={{ marginBottom: "40px" }} >
          <Table>
            <TableHead style={{ fontWeight: "bold", textAlign: "center", background: "rgb(25,118,210,0.6)" }}>
              INSCRIPCIONES CARGADAS
            </TableHead>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>ID</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Inscripción General</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Subárea</TableCell>
                  {/* <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Departamento/ Zona</TableCell> */}
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Dpto Opcional</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Domicilio Inscripción</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Inscripción</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Expediente</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Observaciones</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Carga</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Acciones</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {datosInscPersona?.map(inscxPersona => (
                  <TableRow
                    key={inscxPersona.idInscripcionPersona}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ textAlign: "center" }}>{inscxPersona.idInscripcionPersona}</TableCell>
                    <TableCell >{inscxPersona.inscripcionGeneral.descripcion}</TableCell>
                    <TableCell >{inscxPersona.subAreas.descripcion}</TableCell>
                    {/* <TableCell >       // Iría como información!
                        {inscxPersona.inscripcionGeneral.nivelEnsenanza.nivel === "P" &&
                          (inscxPersona.departamento.nombre ? inscxPersona.departamento.nombre : "(Sin Dpto.)")}
                        {inscxPersona.inscripcionGeneral.nivelEnsenanza.nivel === "M" &&
                          (inscxPersona.inscripcionGeneral.zona.descripcion ?
                            inscxPersona.inscripcionGeneral.zona.descripcion : "(Sin Zona)")
                        }
                      </TableCell> */}
                    <TableCell >
                      {inscxPersona.inscripcionGeneral.nivelEnsenanza.nivel === "P" &&
                        (inscxPersona.departamentoOpcional ? inscxPersona.departamentoOpcional.nombre : "(Sin D.O.)")}
                      {inscxPersona.inscripcionGeneral.nivelEnsenanza.nivel === "M" && ""}
                    </TableCell>
                    <TableCell >{inscxPersona.domicilioInscripcion}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{formatDDMMYY(inscxPersona.fechaInscripcion)}</TableCell>
                    <TableCell >{inscxPersona.expediente ?
                      inscxPersona.expediente : "(Sin Exp.)"
                    }
                    </TableCell>
                    <TableCell style={{ whiteSpace: "pre-wrap" }}>{inscxPersona.observaciones}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{formatDDMMYY(inscxPersona.fechaCarga)}</TableCell>
                    <TableCell style={{ textAlign: "center", minWidth: "120px" }} >
                      <InscripcionesxPersonaEditar datosInscPersona={inscxPersona} persona={persona} />
                      <InscripcionesxPersonaEliminar datosInscPersona={inscxPersona} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}