import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import TituloEditar from "../TituloEditar/TituloEditar";
import TituloEliminar from "../TituloEliminar/TituloEliminar";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";

const TituloTable = ({ titulos }) => {
  const transformRecords = !titulos?.records
    ? titulos
    : titulos?.records?.filter((tit) => tit.deshabilitado != 1);

  return (
    <CustomTableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead
          sx={{
            borderTop: "1px solid", // Agregar borde superior
          }}
        >
          <TableRow>
            <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Código</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeadCell }}>Desc Abreviada</TableCell>
            <TableCell sx={{ ...tableHeadCenterCell }}>Categoria</TableCell>
            <TableCell style={{ minWidth: "150px" }}>Acciones</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {transformRecords?.length > 0 ? (
            transformRecords?.map((titulo) => (
              <TableRow key={titulo.idTitulo}>
                <TableCell sx={{ ...tableBodyCell }}>
                  {titulo.idTitulo}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>{titulo.codigo}</TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {titulo.descripcion}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {titulo.descAbrev}
                </TableCell>
                <TableCell sx={{ ...tableBodyCell }}>
                  {titulo.idCategoria === 1 ? "Titulo" : "Certificado"}
                </TableCell>
                <TableCell sx={{ ...tableBodyCenterCell }}>
                  <TituloEditar titulo={titulo} />
                  <TituloEliminar titulo={titulo} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                No se encontraron resultados
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};
export default TituloTable;
