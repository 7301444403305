import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TitulosxPersonaForm from '../TitulosxPersonaCommon/TitulosxPersonaForm';
import TitulosxPersonaSubtitulo from '../TitulosxPersonaCommon/TitulosxPersonaSubtitulo';
import { editTituloxPersona } from 'store/titulosxPersona/titulosxPersona.slice';

export default function TitulosxPersonaEditar({ datosTitulo, persona }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    dispatch(editTituloxPersona({data}));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Editar Título asignado</DialogTitle>
        <TitulosxPersonaSubtitulo persona={persona} />
        <DialogContent>
          <TitulosxPersonaForm datosTitulo={datosTitulo} persona={persona} onSubmitNewEdit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}