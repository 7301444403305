import * as Yup from "yup";

export const vVariables = Yup.object().shape({
  descripcion: Yup.string().required("Debe agregar una Descripción."),
  valor: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
  fechaInicio: Yup.date().required("Debe agregar una Fecha de Inicio."),
});

export const vVariablesxPer = Yup.object().shape({
  ultFechaInicio: Yup.date(),
  valor: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
  fechaInicio: Yup.date()
    .required("Debe agregar una Fecha de Inicio.")
    .min(Yup.ref("ultFechaInicio"), "La Fecha de Inicio, debe ser posterior al último período abierto."),
});

export const vVariablesParam = Yup.object().shape({
  descripcion: Yup.string().required("Debe agregar una Descripción."),
  descParam1: Yup.string().required("Debe agregar una Descripción."),
  descParam2: Yup.string().nullable(),                                                     // Puede ser un String vacío.
  descValor: Yup.string().required("Debe agregar una Descripción."),
  parametro1: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
  parametro2: Yup.number()
    .nullable()                                                                             // Puede ser Nula, como no.
    .positive("El Valor debe ser mayor que cero."),
  fecha1: Yup.date().required("Debe agregar una Fecha de Inicio."),
  valor: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
});

export const vVariablesParamxPer = Yup.object().shape({
  ultFechaInicio: Yup.date(),
  parametro1: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
  parametro2: Yup.number()
    .nullable()                                                                             // Puede ser Nula, como no.
    .positive("El Valor debe ser mayor que cero."),
  fecha1: Yup.date()
    .required("Debe agregar una Fecha de Inicio.")
    .min(Yup.ref("ultFechaInicio"), "La Fecha de Inicio, debe ser posterior al último período abierto."),
  valorVarParam: Yup.number()
    .required("Debe agregar un Valor para esta variable.")
    .positive("El Valor debe ser mayor que cero."),
}); 