import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Avatar, Button, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import logo from "./Logo Edu.png";
import logoCataGob from "./Ctca-Gobierno.png";
import { redirect, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/auth/auth.slice";
import MainListItems from "./ListItems";

const drawerWidth = 275;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#1976D2",
    },
    secondary: {
      main: "#311b92",
    },
  },
});

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function DashboardContent() {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  let usuario = localStorage.getItem("token");
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
    dispatch(logout());
    navigate("/login");
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!token) {
      redirect("/login");
    }
  }, [token]);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar
            color="primary"
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img alt="logo" src={logo}></img>
            <Typography
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              style={{
                fontSize: "20px",
                textAlign: "center",
                marginRight: "220px",
              }}
            >
              CONCURSOS DOCENTES CATAMARCA
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={usuario}>
                <IconButton onClick={handleMenuOpen} sx={{ ml: 2 }}>
                  <Avatar {...stringAvatar(usuario)} />
                </IconButton>
                <Typography variant="body1" color="inherit" sx={{ ml: 2 }}>
                  {usuario}
                </Typography>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem sx={{ ml: 1 }} color="inherit" onClick={handleLogout}>
                  Salir
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
            style={{ margin: "12px 0px" }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Box
            sx={{
              height: "calc(100vh - 64px)",
              overflowY: "auto",
            }}
          >
            <List component="nav">
              <MainListItems open={open} />
            </List>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[900],
            flexGrow: 1,
            width: "auto",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container
            maxWidth={false}
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Container>
          {/* <Toolbar style={{ backgroundColor: "#1976D2", color: "white", padding: "10px 0px 2px 10px" }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <span>
              <img src={logoCataGob} alt="logoCataGob" />
            </span>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              - MINISTERIO DE EDUCACIÓN DE LA PROVINCIA, DIRECCIÓN DE INFORMÁTICA Y
              TELECOMUNICACIONES -
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              style={{
                fontSize: "13px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                height: "100%",
                marginRight: "40px",
              }}
            >
              © {new Date().getFullYear()}.v1.2
            </Box>
          </Grid>
        </Grid>
      </Toolbar> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
